import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';
declare var Stripe: any;

// Live
const STRIPE = Stripe('pk_live_51JNLEKC7qnCJJDECPzOxWmNEWgFZdWCs4uCeh3mPpqyZAe8J3huax0nYSLSbHSkun1AXMJucVMD99bmK7zhThqSg005daaOLmp');
// TEST
// const STRIPE = Stripe('pk_test_51JNLEKC7qnCJJDECZn4Y2XicyCvI737yfcWLUEBQKFRF3bLZrJqJQ9otEvbC3fzZctJLmZwZRuGe4jfQWcAqrfy3007qERNpoc');

@Component({
    selector: 'app-modal-subscription',
    templateUrl: './modal-subscription.component.html',
    styleUrls: ['./modal-subscription.component.scss']
})
export class ModalSubscriptionComponent implements OnInit {

    public sectionActive: number = 1;
    public loader: boolean = false;
    public loaderSub: boolean = false;
    public planList = [];
    public subscription_info: any;
    public nameHolder: string;
    public cardElement: any;
    public selectedPlan: any;
    public excludedPlans = [];
    public silverPlan: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public user_information: any,
        private dialogRef: MatDialog,
        private router: Router,
        private user_service: UserService
    ) { }

    async ngOnInit() {
        if(this.user_information.subs.length > 0) {
            this.silverPlan = true
        }

        if (this.user_information.incomplete) {
            this.openPayment('');
            console.log(this.user_information.subData);
            const response = await fetch('/secret');
            console.log(response);
            // let clientSecret = await response.json();
            // const { client_secret: clientSecret } = await response.json();
        }
    }

    async showPlan() {
        this.router.navigate(['cursos'])
        this.dialogRef.closeAll();
        return;
        this.loader = true;
        var planIds = [];
        var subIds = [];
        try {
            var plans = await this.user_service.getPlanList();
            console.log(plans);
            
            plans.splice(0,1);
            for await(const plan of plans){
                planIds.push(plan.id)
            }
            for await(const sub of this.user_information.subs){
                subIds.push(sub.plan.id)
            }

            var availablePlans = planIds.filter(plan => !subIds.includes(plan));
            this.planList = plans.filter(plan => availablePlans.includes(plan.id));

            console.log(this.planList);
            
            // var subAnalysis: Array<any> = subs.data.filter(sub => sub.plan.product == 'prod_Kjv5TTKil1eFl6');

            this.loader = false;
            this.sectionActive = 2;
        } catch (error) {
            console.log(error);
            this.loader = false;

        }
    }

    openPayment(plan) {
        console.log(plan);
        
        this.selectedPlan = plan;
        this.sectionActive = 3;
        setTimeout(() => {
            const elements = STRIPE.elements();
            this.cardElement = elements.create('card');
            this.cardElement.mount('#card-element');
            this.loaderSub = false;
        }, 2000);
    }

    async paySuscribe() {
        console.log(this.nameHolder );
        if(this.nameHolder == undefined || this.nameHolder=='') return Swal.fire('Error', 'Favor de llenar todos los campos', 'error');;
        Swal.fire('Generando orden...', '');
        Swal.showLoading();
        try {
            await this.suscribeToPlan(this.selectedPlan);
            await this.payment();
        } catch (error) {
            console.log(error);
            this.dialogRef.closeAll();
            return Swal.fire('Lo sentimos', 'Hubo un problema generar la suscripción', 'info');
        }
    }

    async suscribeToPlan(plan) {
        this.loaderSub = true;
        try {
            console.log(plan);

            var response = await this.user_service.createSuscription(this.user_information.data.stripeclient_id, plan.id);
            this.subscription_info = response;
            console.log(this.subscription_info);

            // this.sectionActive = 3;
            // setTimeout(() => {
            //     const elements = STRIPE.elements();
            //     this.cardElement = elements.create('card');
            //     this.cardElement.mount('#card-element');
            //     this.loaderSub = false;
            // }, 2000);
            this.loaderSub = false;

        } catch (error) {
            console.log(error);
            this.loaderSub = false;
            throw error;
        }
    }

    async payment() {
        STRIPE.confirmCardPayment(this.subscription_info.clientSecret, {
            payment_method: {
                card: this.cardElement,
                billing_details: {
                    name: this.nameHolder,
                },
            }
        }).then(async (result) => {
            if (result.error) {
                Swal.close();
                var response = await this.user_service.deleteSuscription(this.subscription_info.subscriptionId); // En caso de haber un error en el pago se cancela la suscripción
                console.log(`Payment failed: ${result.error.message}`);
                Swal.fire('Lo sentimos', `Hubo un problema: ${result.error.message}`, 'info');
                return

            } else {
                console.log(`Success! Redirecting to your account. ${result}`);
                // var portal = await this.user_service.getUserPortal(this.user_information.data.stripeclient_id);
                // console.log(portal);
                Swal.close();
                Swal.fire('¡Listo!', 'Gracias por suscribirte, ahora tendrás acceso a todo el contenido disponible para ti', 'success').then(() => {
                    this.dialogRef.closeAll();
                    this.router.navigate(['']);
                })

            }
        });
    }

}
