import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChangepasswordComponent } from 'src/app/components/changepassword/changepassword.component';
import { CoursesListComponent } from 'src/app/components/courses-list/courses-list.component';
import { SubscriptionDialogComponent } from 'src/app/components/subscription-dialog/subscription-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { packs } from 'src/app/services/packs/packs';
import { UserService } from 'src/app/services/user/user.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  public hide = true;
  public profileForm: FormGroup;
  public userInformation!: any;

  public isEdit: boolean = false;

  public userId: string = ''

  public profile: any;

  public subscriptionName: string;
  public subscription
  public packs = packs


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.profileForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  async ngOnInit(): Promise<void> {
    this.profileForm.disable();
    let response = await this.authService.isLoggedIn();
    
    this.userId = response.uid
    this.userInformation = await this.userService.getUserData(response.uid);
    this.userInformation.creationDate = response.metadata.creationTime

    this.profileForm.patchValue(this.userInformation);   
    this.getSubs();
  }

  async getSubs() {
    const subs = await this.userService.getUserSubscriptions(this.userInformation.stripeclient_id);

    if(subs.data.length == 0) {
      this.subscription = ''
      this.subscriptionName = ''
    }

    let isActiveSub: boolean = false;
    let someActive = subs.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');
    let currentDate = new Date().setHours(0, 0, 0, 0);
    let someCurrentPeriodActive = subs.data.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);


    if(someActive || someCurrentPeriodActive) {
      this.packs.forEach(pack => {
        subs.data.map((element) => {
  
          console.log('elemento',element);

          if (element.plan.product == pack.monthId && element.plan.active) {
            this.subscription = element
            this.subscriptionName = pack.title
          }
          if (element.plan.product == pack.yearId) {
            this.subscription = element
            this.subscriptionName = pack.titleYear
          }
          if (element.plan.product == pack.biannualId) {
            this.subscription = element
            this.subscriptionName = pack.titleBiannual
          }
        })
      }); 
    } else {
      return false
    }
  }

  async getUserCreationDate() {
    try {
      let response = await this.authService.isLoggedIn();
      console.log(response.metadata.creationTime);
      
      return response.metadata.creationTime
    } catch (error) {
      
    }
  }

  setEdit() {
    this.profileForm.enable();

    if (this.isEdit == true) {
      this.profileForm.disable()
      this.isEdit = false;
    } else {
      this.isEdit = true;
    }
  }

  async updateProfile() {
    this.profile = this.profileForm.value

    try {
      Swal.fire('Cargando...', 'Enviando información');
      Swal.showLoading();

      let response = await this.userService.updateUser(this.profile, this.userId);

      if (response.code == 404) {
        Swal.close();
        Swal.fire('Oops...', 'Por favor verifique que sus datos sean correctos y que el correo electrónico no este en uso', 'error');
        this.isEdit = false
      } else {
        this.shootSimpleAlert('success', '¡Tu información fue actualizada correctamente!')
        this.ngOnInit();
        this.isEdit = false
      }

    } catch (error) {
      this.isEdit = false
      Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
    }
  }

  openSubscriptionDialog() {    
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
      width: '25vw',
      panelClass: 'dialog-class',
      data: {
        subscriptionName: this.subscriptionName,
        subscription: this.subscription
      }
    })
    dialogRef.afterClosed().subscribe(() => {
      console.log('entro');
      this.ngOnInit();
      this.getSubs();
    })
  }

  openCoursesListDialog() {
    let courses = this.userInformation?.purchasedCourses;
    if (courses == undefined || courses.length <= 0) {
      console.log('entro');
      
      Swal.fire({
        title: 'No cuentas con ningún curso aún :(',
        text: 'Hecha un vistazo a nuestra lista de cursos',
        icon: 'info',
        confirmButtonColor: '#21a282',
        customClass: {
          container: 'swal-question',
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#6c757e'
      }).then(async (result) => {
        result.isConfirmed ? this.router.navigate(['/cursos']) : '';
      });
      return;
    }
    const dialogRef = this.dialog.open(CoursesListComponent, {
      width: '90vw',
      panelClass: 'dialog-class',
      data: {
        courseList: this.userInformation.purchasedCourses
      }
    })
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      maxHeight: '500px'
    });
  }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: true,
    });
  }
}
