import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Course } from 'src/app/models/course';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoursesService } from 'src/app/services/courses/courses.service';
import { UserService } from 'src/app/services/user/user.service';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import { StripePaymentElementComponent, StripeService, } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent
} from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { Cart, CartItem, ItemType } from 'src/app/models/cart';
import { AlertService } from 'src/app/services/alerts/alert.service';
import { User } from 'src/app/models/user';
import { packs } from 'src/app/services/packs/packs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;
  elements: StripeElements;
  card: StripeCardElement;
  public errorMessage = "";
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'es-419',
    loader: "always",
  };
  public paymentIntent: string = ''
  public subscriptionId?;
  public isLoadingPI: boolean = true;
  public showError: boolean = false;
  public paying: boolean = false;

  // CartInfo
  public cartItems: any = [];
  public cart: Cart;

  // COUPON
  public coupon: string = "";
  public couponError = false;
  public couponErrorMessage: string;
  public couponRedeemed: any = null;
  public couponDiscount: number = 0;
  public isCouponRedeemed: boolean = false;

  // Course & User Info
  public courseID: string;
  public courseInfo: Course | any;
  public userUid;
  public userInfo: User;

  public fromCourse: boolean = true;
  public isSubscription: boolean = false;

  // Card Payment

  public loaderpage: boolean = false;
  public loaderinit: boolean = false;

  public packs = packs;

  constructor(
    private courseService: CoursesService,
    private stripeService: StripeService,
    private userService: UserService,
    private auth: AuthService,
    private couponService: CouponService,
    private checkoutService: CheckoutService,
    private cartService: CartService,
    private alertService: AlertService,

    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {
  }

  async ngOnInit() {
    this.loaderinit = true;
    await this.getUserInformation()
    this.validateType()
    // Llaves de prueba de Dapper
    //pruebas
    // Conekta.setPublicKey("key_PwqSpXgGm1wqjvSLD6y2txg");
    // Conekta.setLanguage('es');
    //produccion
    // Conekta.setPublicKey('key_PwqSpXgGm1wqjvSLD6y2txg');
  }

  async getUserInformation() {
    try {
      // Get Stripe client ID
      let response = await this.auth.isLoggedIn();

      this.userUid = response.uid;
      this.userInfo = await this.userService.getUserData(this.userUid);
      this.loaderinit = false;
    } catch (error) {
      console.log(error);
      this.loaderinit = false;
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Tuvimos un error al cargar los datos del usuario, serás redireccionado.',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then(() => {
        this.router.navigate(['/cursos']);
      })
    }
  }

  validateType() {
    this.activatedRoute.queryParamMap.subscribe(async (params: any) => {
      const purchaseType = params.get('type');
      const parsedPurchasedTyped = parseInt(purchaseType);

      // * Plan
      if (parsedPurchasedTyped == ItemType.plan) {
        this.setFromPlaState(params.params)
        return;
      }

      // * 1 course
      if (parsedPurchasedTyped == ItemType.course) {
        const courseId = this.activatedRoute.snapshot.paramMap.get('id')
        this.courseID = courseId
        this.fromCourse = true
        this.setFromCourseState()
        return
      }

      // * Multiple courses
      this.setFromCartState();

    })
  }

  validatePaymentType() {
    if (this.isSubscription) {
      this.createSubscription();
    }
    return
  }

  async createSubscription() {
    this.isSubscription = true;
    const priceId = this.activatedRoute.snapshot.paramMap.get('id')
    const stripeClientId: string = this.userInfo.stripeclient_id;

    let response: any;
    if (this.isSubscription && this.isCouponRedeemed) {
      response = await this.userService.createSubscriptionWithCoupon(stripeClientId, priceId, this.couponRedeemed.name)
    } else {
      response = await this.userService.createSuscription(stripeClientId, priceId)
    }

    this.isLoadingPI = false;
    this.subscriptionId = response.subscription.id;
    this.elementsOptions.clientSecret = response.clientSecret!;
    this.paymentIntent = response.paymentIntent
    return
  }

  async setFromPlaState(params: any) {
    this.isSubscription = true;

    if(params.priceId == undefined || params.priceId == null) {
      const alertResponse = await this.alertService.shootConfirmAlert('error', 'Ocurrió un error en la solicitud de la inforomación', 'Será redireccionado');
      console.log('alerres', alertResponse);
    } 

    const buildItem: CartItem = {
      coverImage: params.coverImage,
      description: params.description,
      name: params.name,
      price: params.price,
      priceId: params.priceId,
      type: params.type
    }

    this.courseID = buildItem.priceId
    this.courseInfo = buildItem
    this.fromCourse = true
    return
  }

  async setFromCartState() {
    this.getCartLS();
    this.fromCourse = false;

    console.log('cart', this.cart.total);
    

    this.createPaymentIntent(this.cart.total)
      .subscribe(pi => {
        this.isLoadingPI = false
        this.elementsOptions.clientSecret = pi.response.client_secret!;
        this.paymentIntent = pi.response.id
        this.createPreOrder(this.paymentIntent)
      });
    return
  }


  async setFromCourseState() {
    try {
      var res = await this.courseService.getCourse(this.courseID);
      this.courseInfo = res

      this.createPaymentIntent(this.courseInfo.price)
        .subscribe(pi => {
          this.isLoadingPI = false
          console.log(pi);
          this.elementsOptions.clientSecret = pi.response.client_secret!;
          this.paymentIntent = pi.response.id
          this.createPreOrder(this.paymentIntent)
        });

    } catch (error: any) {
      this.loaderinit = false;
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Tuvimos un error al cargar los datos del curso, serás redireccionado.',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then(() => {
        this.router.navigate(['/cursos']);
      })
    }
  }

  private createPaymentIntent(amount: number): Observable<{ response: PaymentIntent }> {
    return this.http.post<{ response: PaymentIntent }>(
      `${environment.URL_API}/create-payment-intent`,
      { amount }
    );
  }

  async createPreOrder(paymentIntent: string) {
    // try {
    //   let preOrder = {
    //     course: this.cart?.items || [],
    //     client: this.userInfo,
    //     total: this.cart.total || ,
    //     date: new Date(),
    //     status: 'pending',
    //     payment_intent: paymentIntent
    //   }

    //   let response = await this.checkoutService.createPreOrder(preOrder);

    //   if (response != null || response != undefined) {
    //     return true
    //   } else {
    //     return false
    //   }
    // } catch (error) {
    //   console.log(error);
    //   throw error;
    // }
  }

  async getCartLS() {
    let cart = JSON.parse(localStorage.getItem('Carrito'));
    this.cart = cart;
    
    if(cart?.items == null || cart?.items == undefined) {
      console.log('enelfid');
      
      const alertResponse = await this.alertService.shootConfirmAlert('error', 'Ocurrió un error en la solicitud de la información', 'Asegurate de tener elementos en tu carrito de compras o de haber seleccionado un curso/plan. Será redireccionado');
      this.router.navigate(['/cursos'])
    }

    cart.items.forEach(product => {
      this.cartItems.push(product);
    });
  }

  async validateCoupon() {

    console.log('entro');


    this.couponError = false;
    this.isCouponRedeemed = false;
    let total: number = 0;

    if (this.fromCourse) {

      total = this.courseInfo.price
    } else {
      console.log('en el else');
      total = this.cart.total
    }

    let response = await this.couponService.validateCoupon(this.coupon.trim().toUpperCase(), total);

    if (!response.isValid) {
      switch (response.message) {
        case 'not-found':
          this.couponErrorMessage = "No se encuentra el cupón ingresado.";
          this.couponError = true;
          break;

        case 'expired':
          this.couponErrorMessage = "La fecha límite del cupón ingresado ha expirado.";
          this.couponError = true;
          break;

        case 'no-left':
          this.couponErrorMessage = "El cupón ingresado ya no puede redimirse.";
          this.couponError = true;
          break;

        case 'minimum-buy':
          this.couponErrorMessage = "Se requiere un mínimo de compra para redimir el cupón.";
          this.couponError = true;
          break;

        default:
          break;
      }
    } else {
      this.couponRedeemed = response.data[0];
      this.isCouponRedeemed = true;
      this.couponDiscount = (total * (this.couponRedeemed.percentDiscount / 100));
      this.coupon = '';
    }
  }

  async pay() {
    if (this.isLoadingPI == true) return;

    this.showError = false;
    this.loaderpage = true;
    this.paying = true;

    let amount: number = 0;
    let updatePaymentIntent;

    if (this.fromCourse) {
      amount = this.courseInfo.price - this.couponDiscount;
    } else {
      amount = this.cart.total - this.couponDiscount;
    }

    updatePaymentIntent = await this.checkoutService.updatePaymentIntentStripe(this.paymentIntent, amount);

    let intentAlreadyExists = await this.checkoutService.getOrderByPaymentIntent(this.paymentIntent);

    if (intentAlreadyExists == null) {
      var response = this.createPaymentDatabaseElement(updatePaymentIntent, this.paymentIntent, amount);
      if (!response) return;
    } else {
      var response = this.updatePaymentDatabaseElement(updatePaymentIntent, this.paymentIntent, intentAlreadyExists, amount);
      if (!response) return;
    }

    this.loaderpage = true;
    this.stripeService.confirmPayment({
      elements: this.paymentElement.elements,
      confirmParams: {
        return_url: `https://proceso-resultado.web.app/confirmacion-compra/${this.paymentIntent}`,
        // return_url: `https://localhost:4200/confirmacion-compra/${this.paymentIntent}`,
      },
      redirect: 'if_required',
    }).subscribe(result => {
      this.paying = false;
      console.log('Result', result);
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.alertService.shootSimpleAlert('error', result.error.message)
        this.loaderpage = false;
        this.showError = true;
        this.errorMessage = result.error.message || "Ocurrió un error inesperado";
        console.error({ success: false, error: result.error.message });
      } else {
        // The payment has been processed!
        if (result?.paymentIntent?.status === 'succeeded') {
          // Show a success message to your customer
          this.paying = false;
          console.log({ success: true });

          if (this.fromCourse) {
            this.router.navigate([`/confirmacion-compra/${this.paymentIntent}/course`])
            return
          }

          if (this.isSubscription) {
            const queryParams = {
              coverImage: this.courseInfo.coverImage,
              description: this.courseInfo.description,
              name: this.courseInfo.name,
              price: this.courseInfo.price,
              priceId: this.courseInfo.priceId,
              type: this.courseInfo.type,
              finalPrice: amount
            }

            this.router.navigate([`/confirmacion-compra/${this.paymentIntent}/course`], { queryParams })
            return
          }

          this.router.navigate([`/confirmacion-compra/${this.paymentIntent}/cart`])
        }
      }
    });
  }

  async createPaymentDatabaseElement(updatePaymentInfo: any, pi: any, amount: number) {
    try {
      let courseInfoArr = []

      if (this.fromCourse) {
        courseInfoArr.push(this.courseInfo)
      }

      let order = {
        course: this.fromCourse ? courseInfoArr : this.cart.items,
        client: this.userInfo,
        total: amount,
        date: new Date(),
        status: 'pending',
        payment_intent: pi
      }

      let response = await this.checkoutService.createOrder(order);

      if (response != null || response != undefined) {
        return true
      } else {
        return false
      }
    } catch (error) {
      throw error;
    }
  }

  async updatePaymentDatabaseElement(updatePaymentInfo: any, pi: any, intentAlreadyExists: any, amount: number) {
    try {
      let order = {
        course: this.courseInfo,
        client: this.userInfo,
        total: amount,
        date: new Date(),
        status: 'pending',
        payment_intent: pi
      }

      let response = await this.checkoutService.updateOrder(order, this.paymentIntent);

      if (response != null || response != undefined) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  removeCode() {
    this.couponDiscount = 0
    this.isCouponRedeemed = false;
    this.couponRedeemed = null;
  }
}