<div class="custom-container" *ngIf="portfolioData.length > 0">
    <div class="general-wrapper">
        <div class="user-data-wrapper">
            <!-- <div class="info">
                <div class="field">
                    <h2>Retorno histórico esperado:</h2>
                    <h2 class="field-number bold">{{sumRates}}%</h2>
                </div>
                <div class="field">
                    <h2>Tasa de inflación:</h2>
                    <h2 class="field-number bold">{{portfolioData[0].inflationPercentage}}%</h2>
                </div>
                <div class="field">
                    <h2>Edad-inicial:</h2>
                    <h2 class="field-number">{{portfolioData[0].initialAge}} años</h2>
                </div>
                <div class="field">
                    <h2>Retiro:</h2>
                    <h2 class="field-number">{{portfolioData[0].retirementAge}} años</h2>
                </div>
                <div class="field">
                    <h2>Temporalidad:</h2>
                    <h2 class="field-number">{{getTemporality(portfolioData[0].temporality)}}</h2>
                </div>
                <div class="field">
                    <h2>Aportación inicial:</h2>
                    <h2 class="field-number">{{portfolioData[0].startingAmount | currency: 'MXN'}}</h2>
                </div>
                <div class="field">
                    <h2>Mejor aportación:</h2>
                    <h2 class="field-number">{{portfolioData[0].bestContribution | currency: 'MXN'}}</h2>
                </div>
                <div class="field">
                    <h2>Peor aportación:</h2>
                    <h2 class="field-number">{{portfolioData[0].worstContribution | currency: 'MXN'}}</h2>
                </div>
            </div> -->

            <h2>Listado de instrumentos y comparativas:</h2>
            <div class="instruments-list">
                <div class="info" *ngFor="let item of portfolioData; let i = index">
                    <div class="field">
                        <h2>Instrumento</h2>
                        <h2 class="field-number">{{item.category}}</h2>
                    </div>
                    <div class="field">
                        <h2>Vol Ponderado</h2>
                        <h2 class="field-number">{{((item.standarDeviation * item.percentage) / 100).toFixed(2)}}%</h2>
                    </div>
                    <div class="field">
                        <h2>Vol</h2>
                        <h2 class="field-number">{{(item.standarDeviation).toFixed(2)}}%</h2>
                    </div>
                    <div class="field">
                        <h2>Asignación inicial</h2>
                        <h2 class="field-number">{{(item.percentage).toFixed(2)}}%</h2>
                    </div>
                    <div class="field">
                        <h2>Rendimiento anual</h2>
                        <h2 class="field-number">{{(item.historicalPer).toFixed(2)}}%</h2>
                    </div>
                    <div class="field">
                        <h2>Rendimiento ponderado</h2>
                        <h2 class="field-number">{{((item.percentage * item.historicalPer) / 100).toFixed(2)}}%</h2>
                    </div>
                    <div class="field">
                        <hr>
                    </div>
                    <div class="field">
                        <h2>Comparativa:</h2>
                        <h2 class="field-number">{{comparisonResults[i].category}}</h2>
                    </div>
                    <div class="field">
                        <h2>Porcentaje calculado</h2>
                        <h2 class="field-number">{{comparisonResults[i].historicalPer}}</h2>
                    </div>
                    <div class="field">
                        <h2>Porcentaje elegido</h2>
                        <h2 class="field-number">{{comparisonResults[i].percentage}}</h2>
                    </div>
                    <div class="field">
                        <h2>Porcentaje * Porcentaje elegido</h2>
                        <h2 class="field-number">{{comparisonResults[i].percentage}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <ng-container>
            <!-- * PARA EL PDF -->
            <div class="mat-tab-group-wrapper">
                <div class="average-wrapper" label="Promedio">
                    <div class="description description-average-contribution">
                        <h3>Resultados "Promedio"</h3>
                        <p>Esta sección muestra los posibles resultados de tu inversión basados en un escenario promedio
                            de rendimiento basado en tus mejores y peores aportaciones. La gráfica te muestra una
                            proyección de cómo tu inversión puede crecer a lo
                            largo del tiempo, lo que te permitirá visualizar el potencial de tu inversión a largo plazo.
                        </p>
                    </div>
                    <div echarts [options]="averageChartOption" theme="dark" id="average-chart"
                        class="chart average-chart">
                    </div>
                    <div class="average-wrapper">
                        <table class="table table-hover text-center average-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of averageResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="best-contribution-wraper">
                    <div class="description description-best-contribution">
                        <h3>Resultados "Mejor aportación"</h3>
                        <p>En esta sección, se muestra el mejor escenario posible para tu inversión, basado en la mejor
                            aportación mensual que puedes hacer. La gráfica te muestra cómo puede crecer tu inversión si
                            mantienes este nivel de aportación y se cumplen las mejores expectativas de rendimiento.</p>
                    </div>
                    <div echarts [options]="bestContributionChartOption" theme="dark" id="best-contribution-chart"
                        class="chart best-contribution-chart"></div>
                    <div class="best-contribution-wrapper">
                        <table class="table table-hover text-center best-contribution-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of bestContributionResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="worst-contribution-wrapper" label="Peor aportación">
                    <div class="description description-worst-contribution">
                        <h3>Resultados "Menor aportación"</h3>
                        <p>En esta sección, se muestra el peor escenario posible para tu inversión, basado en la peor
                            aportación mensual que puedes hacer. La gráfica te muestra cómo puede crecer tu inversión si
                            mantienes este nivel de aportación y se cumplen las peores expectativas de rendimiento.</p>
                    </div>
                    <div echarts [options]="worstContributionChartOption" theme="dark" id="worst-contribution-chart"
                        class="chart worst-contribution-chart"></div>
                    <div class="worst-contribution-wrapper">
                        <table class="table table-hover text-center worst-contribution-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of worstContributionResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="disclaimer">
                    <h3>Importante:</h3>
                    <p>Por favor, ten en cuenta que <b> los resultados presentados por esta herramienta son aproximados
                            y no
                            definitivos.</b> La inversión en la bolsa de valores implica un riesgo y los resultados
                        pueden
                        variar debido a
                        factores externos e impredecibles como las fluctuaciones del mercado, la inflación, cambios
                        políticos y
                        económicos, entre otros. Los resultados presentados en esta herramienta se basan en estimaciones
                        y
                        proyecciones de rendimiento que pueden no ser precisas. Por lo tanto, te recomendamos tomar
                        estos
                        resultados
                        como una guía general para ayudarte a tomar decisiones informadas sobre tu inversión, pero
                        siempre
                        debes
                        considerar los posibles riesgos y estar preparado para afrontar cualquier eventualidad que pueda
                        afectar el
                        rendimiento de tu inversión.</p>
                </div>
            </div>
        </ng-container>

    </div>

</div>