import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalSubscriptionComponent } from 'src/app/components/modal-subscription/modal-subscription.component';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-tools-pre-selection',
    templateUrl: './tools-pre-selection.component.html',
    styleUrls: ['./tools-pre-selection.component.scss']
})
export class ToolsPreSelectionComponent implements OnInit {

  public userSubs:any;

    constructor(
        private auth_service: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private user_service: UserService
    ) { }

    ngOnInit(): void {
    }

    async validateLogin(type: string) {
        Swal.fire('Cargando...',);
        Swal.showLoading();

        let isAuth = await this.auth_service.isLoggedIn();

        if (isAuth == null) {
            this.router.navigate(['inicio-sesion'])
            Swal.close();
            return;
        } else {
            this.validateSub(type);
            this.getSub()
        }
    }

    async validateSub(type: string) {
        Swal.fire('Cargando...',);
        Swal.showLoading();

        try {
            const user_id = await this.auth_service.getUser();
            const user_info: User = await this.user_service.getUserData(user_id);

            if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
                Swal.close();
                return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
            }

            const subs = await this.user_service.getUserSubscriptions(user_info.stripeclient_id);


            if (type == 'indicadores') {
                if (subs.data.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            //PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRTjKMwORlDQK', subs: subs.data }
                            //TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Mr7k9SbTpoeDpM', subs: subs.data }
                        });
                }

                var subAnalysis = subs.data

                if (subAnalysis.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            //PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRTjKMwORlDQK', subs: subs.data }
                            //TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Kjv5TTKil1eFl6', subs: subs.data }
                        });
                }

                let someActive = subAnalysis.some((sub) => sub.status == 'active' || sub.status == 'trialing');
                let currentDate = new Date().setHours(0, 0, 0, 0);
                let someCurrentPeriodActive = subAnalysis.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);

                if (someActive || someCurrentPeriodActive) {
                    Swal.close();
                    this.router.navigate([`/tendencias/active`]);
                    return;
                }

                Swal.close();
                return Swal.fire('Lo sentimos', 'Actualmente no cuentas con una suscripción activa para acceder a este contenido', 'info');


            }
            if (type == 'screeners') {
                if (subs.data.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            // PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRIO8Dru4WWF3', subs: subs.data }
                            // TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Kjv6xNIFkf2ohZ', subs: subs.data }
                        });
                }

                var subAnalysis = subs.data

                if (subAnalysis.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            // PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRIO8Dru4WWF3', subs: subs.data }
                            // TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Kjv6xNIFkf2ohZ', subs: subs.data }
                        });
                }
                let someActive = subAnalysis.some((sub) => sub.status == 'active' || sub.status == 'trialing');
                let currentDate = new Date().setHours(0, 0, 0, 0);
                let someCurrentPeriodActive = subAnalysis.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);
                if (someActive || someCurrentPeriodActive) {
                    Swal.close();
                    this.router.navigate([`/screeners/active`]);
                    return;
                }
                Swal.close();
                return Swal.fire('Lo sentimos', 'Actualmente no cuentas con una suscripción activa para acceder a este contenido', 'info');

            }
            if (type == 'portafolio') {
                if (subs.data.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            // PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRIO8Dru4WWF3', subs: subs.data }
                            // TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Kjv6xNIFkf2ohZ', subs: subs.data }
                        });
                }

                var subAnalysis = subs.data

                if (subAnalysis.length == 0) {
                    Swal.close();
                    return this.dialog.open(ModalSubscriptionComponent,
                        {
                            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
                            // PROD
                            data: { data: user_info, user_id: user_id, prod_id: 'prod_LIRIO8Dru4WWF3', subs: subs.data }
                            // TEST
                            // data: { data: user_info, user_id: user_id, prod_id: 'prod_Kjv6xNIFkf2ohZ', subs: subs.data }
                        });
                }
                let someActive = subAnalysis.find((sub) => sub.status == 'active' || sub.status == 'trialing');
                let currentDate = new Date().setHours(0, 0, 0, 0);
                let someCurrentPeriodActive = subAnalysis.find((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);

                let plan = someCurrentPeriodActive.plan.product;

                if (plan == "prod_Mr7kA4aIiMnKYs" || plan == "prod_MzNMjbd23qEZYh" || plan == "prod_NiIBGHsa5AN6Ta") {

                    Swal.close();
                    this.router.navigate([`/portafolio/active`]);
                    return;
                }
                Swal.close();
                return Swal.fire('Lo sentimos', 'Necesitas adquirir el paquete "DIAMOND" para acceder a esta herramienta', 'info');

            }
        } catch (error) {
            Swal.close();
            return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
        }


    }

    async getSub(){
      try{
        const user_id = await this.auth_service.getUser();
        const user_info: User = await this.user_service.getUserData(user_id);

        if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
            Swal.close();
            return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
        }

        this.userSubs = await this.user_service.getUserSubscriptions(user_info.stripeclient_id);
        console.log('====================================');
        console.log("Subs: ", this.userSubs);
        console.log('====================================');
      }catch(error){
        console.log('====================================');
        console.log("Error: ", error);
        console.log('====================================');
      }
    }
}
