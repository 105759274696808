import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


interface Authenticated {
    res?: boolean;
    user?: string;
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    private validUserForSignInURL: string = `${environment.URL_API}/user/validUserForSignIn`;
    private updateLoginId: string = `${environment.URL_API}/user/loginId`;
    private createUserAccountURI: string = `${environment.URL_API}/user/create`;
    private createSimpleAccountURI: string = `${environment.URL_API}/user/create-simple-account`;
    private createClientStripeURI: string = `${environment.URL_API}/user/stripe/create`;

    public userUid;
    public userRef: AngularFirestoreDocument<any>;
    public obs$: Observable<any>
    public userInfo;
    private session: Subscription;
    constructor(
        private http: HttpClient,
        public afAuth: AngularFireAuth,
        public afs: AngularFirestore,
        private router: Router,
    ) { }

    async check(user_info) {
        await this.afAuth.signInWithEmailAndPassword(user_info.email, user_info.password);
        this.sendEmailVerified();
    }
    async sendEmailVerified() {
        (await this.afAuth.currentUser).sendEmailVerification();
        this.router.navigate(['/email-verified']);
        this.logoutUser();
        // console.log(loge.sendEmailVerification());  
    }
    async simpleCheck(user_info) {
        await this.afAuth.signInWithEmailAndPassword(user_info.email, user_info.password);
    }

    sendRecoveryEmail(email:any) {
        return new Promise((resolve, reject) => {
          this.afAuth.sendPasswordResetEmail(email).then((res:any) => {
            resolve(true);
          }).catch((err:any) => {
            reject(false)
          })
        })
      }

    async getUser() {
        try {
            let currentUser = await this.afAuth.currentUser;
            this.userUid = currentUser != null ? currentUser.uid : undefined
            return this.userUid;
        } catch (error) {
            console.log(error);
        }
    }

    getUserObservable(userId?: string) {
        try {
            if (userId != undefined) {
                this.userRef = this.afs.collection("users").doc(userId);
                this.obs$ = this.userRef.valueChanges();
                this.session = this.obs$.subscribe(data => {
                    let loginId = localStorage.getItem('loginId');
                    if (data.loginId != parseInt(loginId)) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Nuevo inicio de sesión',
                            text: 'Se ha cerrado la sesión en este dispositivo. Se ha iniciado la sesión en un dispositivo distinto o ha habido una actualización en la información de tu cuenta',
                            confirmButtonText: "Aceptar",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            allowEnterKey: false,
                            confirmButtonColor: "#e5514e",
                        }).then(async () => {
                            await this.afAuth.signOut();
                            this.router.navigate(['/inicio']);
                            this.session.unsubscribe();
                        })
                    }
                    return;
                });
            } else {
            }

        } catch (error) {
            console.log(error);

        }
    }

    async loginUser(newEmail: string, newPassword: string): Promise<any> {
        return this.afAuth.signInWithEmailAndPassword(newEmail, newPassword);
    }

    async userCanSignIn(userEmail: string) {
        try {
            const answer = await this.http.post<{ code: number, message: string, data: User, }>(`${this.validUserForSignInURL}`, { userEmail: userEmail }).toPromise();
            return answer;
        } catch (error) {
            throw error;
        }
    }

    async logoutUser(): Promise<any> {
        await this.afAuth.signOut();
        this.session.unsubscribe();
    }

    async setLoginId(randomId, userId) {
        try {
            const answer = await this.http.post<{ code: number; message: string; data: User; }>(this.updateLoginId, { randomId: randomId, userId: userId }).toPromise();
            return answer;
        } catch (error) {
            console.log(error);
        }
    }

    async register(user_info: any) {
        try {
            const response = await this.http.post<{ code: number, message: string, user_id: string }>(this.createUserAccountURI, user_info).toPromise();
            this.check(user_info);
            return response.user_id;
        } catch (error) {
            throw error;
        }
    }
    
    async createSimpleAccount(user_info: any) {
        try {
            const response = await this.http.post<{ code: number, message: string, user_id: string }>(this.createSimpleAccountURI, user_info).toPromise();
            this.simpleCheck(user_info);
            return response.user_id;
        } catch (error) {
            throw error;
        }
    }

    async createStripeClient(user_info: any, user_uid: string) {
        try {
            const response = await this.http.post<{ code: number, message: string }>(`${this.createClientStripeURI}`, { user_info: user_info, user_uid: user_uid }).toPromise();
            return response;
        } catch (error) {
            throw error;
        }
    }

    async isAuthenticated(): Promise<Authenticated> {
        const user = await this.isLoggedIn();
        if (user) {
            return { res: true, user: user.uid };
        } else {
            return { res: false };
        }
    }

    async isLoggedIn() {
        return await this.afAuth.authState.pipe(first()).toPromise();
    }
}
