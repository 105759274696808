import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Course } from 'src/app/models/course';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CoursesService } from 'src/app/services/courses/courses.service';
import { UserService } from '../../services/user/user.service';
import { CartService } from '../../services/cart/cart.service';
import { OrdersService } from '../../services/orders/orders.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BuyNoAccountDialogComponent } from '../buy-no-account-dialog/buy-no-account-dialog.component';
import Swal from 'sweetalert2';
import { CartItem, ItemType } from 'src/app/models/cart';

@Component({
  selector: 'app-course-datails',
  templateUrl: './course-datails.component.html',
  styleUrls: ['./course-datails.component.scss']
})

export class CourseDatailsComponent implements OnInit {

  cart: any = [];

  public courseID: string;
  public courseInfo: Course;
  public loaderpage: boolean = false;
  public userUid;
  public userInfo;
  public isAuth: boolean = false
  public courseMaterials = [];

  constructor(
    private route: ActivatedRoute,
    private service: CoursesService,
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private cartService: CartService,
    private ordersService: OrdersService,
    private location: Location,
    private dialogRef: MatDialog
  ) {

  }

  async ngOnInit() {
    this.loaderpage = true;
    this.isAuth = await this.verifyAuth();
    this.courseID = this.route.snapshot.params['id'];

    try {
      this.auth.afAuth.onAuthStateChanged(async user => {
        if (user != null) {
          this.userUid = user.uid;
          const answer = await this.userService.getUserData(this.userUid);
          this.userInfo = answer;
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Tuvimos un error al cargar los datos del usuario, serás redireccionado.',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then(() => {
        this.router.navigate(['/cursos']);
      })
    }
    try {
      var res = await this.service.getCourse(this.courseID);
      this.courseInfo = res

      const materialRes = await this.service.getCourseMaterials(this.courseID);

      this.courseMaterials = materialRes

      if (this.courseInfo.time.hour.toString().length == 1) {
        this.courseInfo.time.hourString = "0" + this.courseInfo.time.hour;
      } else {
        this.courseInfo.time.hourString = this.courseInfo.time.hour.toString();
      }

      if (this.courseInfo.time.minutes.toString().length == 1) {
        this.courseInfo.time.minString = "0" + this.courseInfo.time.minutes;
      } else {
        this.courseInfo.time.minString = this.courseInfo.time.minutes.toString();
      }
      this.loaderpage = false

    } catch (error: any) {

      if (error.error.message == "Courses doesn't have material") {
        this.loaderpage = false;
        this.courseMaterials = [];
        return
      }

      this.loaderpage = false;
      console.log('en la linea 97', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Tuvimos un error al cargar los datos del curso, serás redireccionado.',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        this.router.navigate(['/cursos']);
      })
    }
  }

  async verifyAuth() {
    let isAuth = await this.auth.isLoggedIn();
    return isAuth != null
  }

  async validateAuth(courseId: string) {
    let isAuth = await this.verifyAuth();
    if (isAuth) {

      let queryParams = {
        type: ItemType.course
      }

      this.router.navigate(['/verificar-compra/' + courseId], { queryParams })
    } else {
      let noAccountResponse = this.dialogRef.open(BuyNoAccountDialogComponent, {
        width: '600px', height: 'auto', panelClass: 'dialog-class',
      });
      noAccountResponse.afterClosed().subscribe(async result => {
        if (result) {

          let queryParams = {
            type: 'course'
          }

          this.router.navigate(['/verificar-compra/' + courseId], { queryParams })
        }
      })
    }
  }

  async addCartItem(courseInfo: Course) {
    const mapCourseToCartItem: CartItem = {
      coverImage: courseInfo.coverImage,
      description: courseInfo.description,
      name: courseInfo.name,
      price: courseInfo.price,
      type: ItemType.course
    }
    this.cartService.addCartItem(mapCourseToCartItem, this.userInfo)
  }

  goBack() {
    this.location.back();
  }

  navigate(url: string) {
    console.log('entro', url);

  }
}
