import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: true,
    });
  }

  async shootConfirmAlert(icon: SweetAlertIcon, title: string, text?: string) {
    return await Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
      cancelButtonText: 'Cancelar',
    });
  }
}
