import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'proceso-resultado';

    constructor(private auth: AuthService) {
        this.setObservable();
    }

    async setObservable() {
        try {
            const userId = await this.auth.getUser();
            await this.auth.getUserObservable(userId);
        } catch (error) {
            console.log(error);

        }
    }

    async ngOnInit() {

    }
}
