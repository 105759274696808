import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BlogService } from 'src/app/services/blog/blog.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  public isSubscribed: Promise<boolean>;
  filterPost = '';
  public tags: any;
  public filterSelected: string;
  public allPosts = [];
  public loader = false;
  public filteredPosts = [];
  public allDummyPosts = [];
  public isLoggedIn: boolean = false;

  posts = [];
  constructor(public service: BlogService,
    private auth: AuthService,
    private userService: UserService,
    ) {
    this.service.getPosts().subscribe((res) => {
      this.posts = [];
      res.forEach((element) => {
        element.description = element.description.substring(0, 200) + '...';
        if (element.tags == undefined || element.tags == null) {
          element.tags = [];
        }
        this.allPosts.push(element);
      });
    });
  }

  async ngOnInit() {
    this.loader = true;
    this.tags = await this.getTags();
    this.selectFilter('all');
    this.loader = false;
    this.filteredPosts = this.allPosts;
    this.isSubscribed = this.validateSub();
    console.log(this.isSubscribed);
    
  }

  async validateSub() {
    const user_id = await this.auth.getUser();
    if(user_id == undefined) {
      this.isLoggedIn = false
      return;
    }
    const user_info: User = await this.userService.getUserData(user_id);    
    
    if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
      Swal.close();
      return false
    }
    
    const subs = await this.userService.getUserSubscriptions(user_info.stripeclient_id);  

    let isActiveSub: boolean = false;

    let someActive = subs.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');
    let currentDate = new Date().setHours(0, 0, 0, 0);
    let someCurrentPeriodActive = subs.data.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);
    if(someActive || someCurrentPeriodActive) {
      return true
    } else {
      return false
    }
}

  applyFilter(event: Event) {
    // this.allDummyPosts = this.allPosts
    const filterValue = (event.target as HTMLInputElement).value;
    this.posts = this.allPosts.filter(post => post.name.includes(filterValue.trim().toLowerCase()));
  }

  async getTags() {
    try {
      const answer = await this.service.getAllTags();
      return answer;
    } catch (error) {}
  }

  selectFilter(tag) {
    this.posts = this.allPosts;
    if (tag == 'all') {
      this.filterSelected = 'Todas';
      this.posts = this.allPosts;
    } else {
      this.filterSelected = tag.tag;

      this.posts = this.allPosts.filter((post) => post.tags.includes(tag.id));
    }
  }
}
