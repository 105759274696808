<section>
    <button mat-raised-button class="go-back-button" (click)="goBack()">
        <mat-icon matPrefix aria-hidden="false" aria-label="Example home icon">chevron_left</mat-icon>
        Regresar
    </button>
    <div class="img-container" [ngStyle]="{'background-image': 'url('+ post?.image +')'}" *ngIf="!loader"></div>
    <div class="loading" *ngIf="loader">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="blog-container container">
        <div class="title">
            <div class="info">
                <div class="date">
                    <img src="assets/images/custom/calendar.svg" alt="">
                    <p>{{$any(post)?.date._seconds * 1000 | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="author">
                    <mat-icon>person</mat-icon>
                    <p>Autor: {{post?.author}}</p>
                </div>
            </div>
            <h1>{{post?.title}}</h1>
        </div>
        <hr>
        <div class="blog-content" [innerHTML]="post?.description"></div>
        <div *ngIf="post?.files?.length > 0" class="blog-files">
            <ng-container *ngFor="let file of post.files">
                <!-- <iframe style="margin: 2rem 0;" width="100%" height="800px" [src]="file.url | sanitizeUrl" frameborder="0"></iframe> -->
            </ng-container>
            <!-- <ng-container *ngFor="let file of post.files">
                <embed style="margin: 2rem 0;" width="100%" height="800px" [src]="file.url | sanitizeUrl">
            </ng-container> -->
            
        </div>
    </div>
</section>