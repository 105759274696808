export interface Pack {
        monthId: string;
        yearId: string;
        biannualId: string;
        default_price_biannual?: string;
        default_price_yearly?: string;
        image: string;
        title: string;
        titleYear: string;
        titleBiannual: string;
        price: number;
        price_year: number;
        description: string;
        showMore: boolean;
        info: string;
        moreInfo: string[];
        active: boolean;
}

export const packs: Pack[] = [
    {
      // PROD
      monthId: 'prod_Mr7kxwJNqW5b4J',
      yearId: 'prod_MzNNlTSlrrAqfO',
      biannualId: "prod_NiI35sYc6pHBNc",
      // TEST
      // monthId: 'prod_MqgdD2bvXBXh4B',
      // yearId: 'prod_MzM3Qd2KCtTPmN',
      // biannualId: "prod_NjLhbVW5T5mth9",

      image: "assets/images/packs/pack-3.jpg",
      title: "SILVER",
      titleYear: "SILVER YEAR",
      titleBiannual: "SILVER SEMESTRAL",
      price: 2800,
      price_year: 4300,
      description: 'Costo Anual $4,300.00',
      showMore: false,
      info: 'Lorem Ipsum',
      moreInfo: [
        'Curso Análisis Fundamental Básico',
        'Curso ETFs Herramientas de Inversión y Estrategias',
        'Curso Portafolios para el Retiro con Indexación Pasiva',
        'Indicadores',
        'Screeners',
        'Datos Históricos de Empresas (STATS)',
        'PDF de Investigación y Análisis',
        'Reconocimiento por curso',
        '1 Análisis de Empresa',
        'Curso Economía para Inversionistas y emprendedores',
        ''
      ],
      active: true
    },
    {
      // PROD
      monthId: 'prod_Mr7k9SbTpoeDpM',
      yearId: 'prod_MzNN2IB6np0Wt1',
      biannualId: "prod_NiI4b2VGVoyjWQ",
      // TEST
      // monthId: 'prod_MqgfhU3O6sifWA',
      // yearId: 'prod_MzM57r4ptt2NRJ',
      // biannualId: "prod_NjLjnRCs8t4Mu6",
      image: "assets/images/packs/pack-1.jpg",
      title: "GOLD",
      titleYear: "GOLDEN YEAR",
      titleBiannual: "GOLDEN SEMESTRAL",
      price: 3200,
      price_year: 4600,
      description: 'Costo Anual $4,600.00',
      showMore: false,
      info: 'Lorem Ipsum',
      moreInfo: [
        'Curso Análisis Fundamental Avanzado',
        'Curso Análisis de Ventajas Competitivas Duraderas',
        'Curso Estrategias de Inversión y Especulación',
        'Indicadores',
        'Screeners',
        'PDF de Investigación y Análisis',
        'Datos Históricos de Empresas (STATS)',
        '1 Análisis Mensual de Empresa',
        'Lista de Seguimiento de Empresas',
        'Reconocimiento por curso',
        'Curso Economía para Inversionistas y emprendedores'
      ],
      active: true
    },
    {
      // PROD
      monthId: 'prod_Mr7kA4aIiMnKYs',
      yearId: 'prod_MzNMjbd23qEZYh',
      biannualId: "prod_NiIBGHsa5AN6Ta",
      // TEST
      // monthId: 'prod_MqgfKqfVFPBkbI',
      // yearId: 'prod_MzM74wsYhgnjTa',
      // biannualId: "prod_NjLkLFmgq50LNo",
    
      image: "assets/images/packs/pack-2.jpg",
      title: "DIAMOND",
      titleYear: "DIAMOND YEAR",
      titleBiannual: "DIAMOND SEMESTRAL",
      price: 3500,
      price_year: 5500,
      description: 'Costo Anual $5,500.00',
      showMore: false,
      info: 'PROXIMAMENTE',
      moreInfo: [
        'Curso Análisis Fundamental Avanzado',
        'Curso Análisis de Ventajas Competitivas Duraderas',
        'Curso Estrategias de Inversión y Especulación',
        'Indicadores',
        'Screeners',
        'PDF de Investigación y Análisis',
        'Datos Históricos de Empresas (STATS)',
        '1 Análisis Mensual de Empresa',
        'Lista de Seguimiento de Empresas',
        'Reconocimiento por curso',
        'Sesión personalizada (1 sesión de forma semestral y 2 sesiones para anualidades)'
      ],
      active: true
    },
  ];