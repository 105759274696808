import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results-contributions-tabs',
  templateUrl: './results-contributions-tabs.component.html',
  styleUrls: ['./results-contributions-tabs.component.scss']
})
export class ResultsContributionsTabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
