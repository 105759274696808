import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { color, EChartsOption } from 'echarts';

@Component({
  selector: 'app-points-chart',
  templateUrl: './points-chart.component.html',
  styleUrls: ['./points-chart.component.scss']
})
export class PointsChartComponent implements OnInit {
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      data: ['Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20', 'Dec-20'],
    },
    yAxis: {
      type: 'value',
      scale: true,
      splitArea: {
        show: true
      }
    },
    series: [
      {
        name: 'Buy/Acc',
        type: 'line',
        data: [
          {
            name: 'min',
            value: 'close'
          }
        ],
        symbol: 'triangle',
        symbolSize: 20,
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        }
      },
      {
        name: 'FDX',
        type: 'line',
        data: [
          { value: 200, itemStyle: { color: 'black' }, symbol: 'triangle', symbolRotate: 0, symbolSize: 20 },
          { value: 50, itemStyle: { color: 'red' }, symbol: 'triangle', symbolRotate: 180, symbolSize: 20 },
          { value: 200, itemStyle: { color: 'black' }, symbol: 'triangle', symbolRotate: 0, symbolSize: 20 },
          { value: 30, itemStyle: { color: 'red' }, symbol: 'triangle', symbolRotate: 180, symbolSize: 20 },
        ],
        smooth: true,
        lineStyle: {
          opacity: 0.5,
          color: '#32CDEC'
        }
      },
      {
        name: 'Close/Hedge',
        type: 'line',
        data: [200, 600],
        smooth: true,
        lineStyle: {
          opacity: 0.5,
        }
      },
      {
        name: 'Trend',
        type: 'line',
        itemStyle: { color: 'green' },
        data: [500, 300, 500, 300, 500, 300, 500, 300, 500, 300, 500, 300],
        smooth: true,
        lineStyle: {
          opacity: 0.5,
          color: '#63E86B',
          type: 'dotted'
        }
      },
      {
        name: 'MA 6mo',
        type: 'line',
        data: [100, 50, 240, 100, 50, 240, 100, 50, 240],
        smooth: true,
        lineStyle: {
          opacity: 0.5,
          type: 'dotted',
          color: 'green',
        },
        itemStyle: {
          color: 'green',
          borderColor: 'green',
          borderType: 'solid'
        }
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    legend: {
      data: ['Buy/Acc', 'FDX', 'Close/Hedge', 'Trend', 'MA 6mo']
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%'
    },


  };

  options: Observable<any>;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {

  }
}