<h2>Selecciona el tipo de plan que deseas</h2>

<mat-dialog-content>
    <form class="form" [formGroup]="planTypeForm">
        <div class="row-input">
            <mat-label>Tipo<span class="required-color">*</span></mat-label>
            <mat-form-field appearance="outline">
                <mat-select formControlName="type">
                    <mat-option value="biannual">Semestral</mat-option>
                    <mat-option value="yearly">Anual</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error
                *ngIf="planTypeForm.controls['type'].invalid && planTypeForm.controls['type'].touched">
                * Requerido
            </mat-error>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="buttons-wrapper">
        <button class="btn btn-cancel" mat-dialog-close>Salir</button>
        <button class="btn btn-confirm" (click)="onConfirmType()">Confirmar</button>
    </div>
</mat-dialog-actions>