<div class="screeners-main">
    <div class="screeners-title">
        <h1>Screeners</h1>
        <p class="screener-info">
            Los Screeners utilizan factores fundamentales para seleccionar empresas.
            Todos han sido probados con backtests.Los podemos utilizar de dos formas:
        </p>

    <ul>
        <p class="screener-ul">
            - Como filtros para reducir el universo de empresas y seleccionar solo aquellas que nos parezcan atractivas para estudiar y valuar.
        </p>

        <p class="screener-ul">
            - Armar una cartera completa con todas ellas y rebalancearla periodicamente.
        </p>
    </ul>  

        <p *ngIf="!emptyScreeners && !loader">Selecciona a continuación el screener que quieres visualizar:</p>
        <p *ngIf="emptyScreeners">No se encontraron Screeners para mostrar por el momento.</p>
        <p *ngIf="!emptyScreeners && loader">Buscando... El proceso puede tardar unos segundos.</p>
    </div>
    <div class="spinner" *ngIf="loader"></div>
    <div class="screeners-list" *ngIf="!loader">
        <mat-chip-list aria-label="Fish selection">
            <mat-chip *ngFor="let chip of chips; let i = index" (click)="setActive(chip, i)"
                [ngClass]="{'active': selectedChipIndex == i}">{{chip.name}}</mat-chip>
        </mat-chip-list>
    </div>
    <div class="screener-table" *ngIf="!emptyScreeners && !loader">
        <div class="table-container" style="overflow-x:auto;"> <!-- lo agregue -->
            <p *ngIf="!emptyScreeners && !loader">Viendo actual: <span>{{selectedChip.name}}</span></p>
            <div>
                <table class="table text-center">
                    <thead class="thead-dark">
                        <tr>
                            <th *ngFor="let header of selectedChip.headers">{{header}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of selectedChip.rows">
                            <td *ngFor="let item of row">{{item}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>