import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Course, CourseMaterial } from 'src/app/models/course';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  private getCoursesURL: string = `${environment.URL_API}/course/get`;
  private getCourseURL: string = `${environment.URL_API}/course/getCourse`;
  private getMaterialURL: string = `${environment.URL_API}/course/material/get`;
  private getCourseRecommendedURL: string = `${environment.URL_API}/course/recommendedCourse`;

  constructor(private http: HttpClient) { }

  async getCourses() {
    try {
      const answer = await this.http
        .get<{
          code: number;
          message: string;
          data: Array<Course>;
          size: number;
        }>(`${this.getCoursesURL}`)
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getCourse(itemId: string) {
    try {
      const answer = await this.http
        .post<{
          code: number,
          message: string,
          data: Course
        }>(`${this.getCourseURL}`, { courseId: itemId })
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getCourseMaterials(itemId: string) {
    try {
      const answer = await this.http
        .post<{
          code: number,
          message: string,
          data: Array<CourseMaterial>
        }>(`${this.getMaterialURL}`, { courseId: itemId })
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getRecommendedCourse(category: number) {
    try {
      const answer = await this.http
        .post<{
          code: number,
          message: string,
          data: Array<Course>
        }>(`${this.getCourseRecommendedURL}`, { category: category })
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


}
