import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventsService {
  private getCalendarEventsURL: string = `${environment.URL_API}/calendar/get`;

  constructor(private http: HttpClient) {}

  async getCalendarEvents() {
    try {
      const answer = await this.http
        .get<{ code: number; message: string; data: any; size: number }>(
          `${this.getCalendarEventsURL}`
        )
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
    }
  }
}
