import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public recoverForm: FormGroup;
  public userUid;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit(): void {

  }

  async submit() {
    var mail = this.recoverForm.value.email.trim();

    try {
      Swal.fire('Cargando...', 'Enviando correo electrónico de recuperación');
      Swal.showLoading();

      var response = await this.authService.sendRecoveryEmail(mail);
      console.log(response)

      if (response == 404) {
        Swal.close();
        Swal.fire('Oops...', 'Por favor verifique su correo electrónico e intente de nuevo', 'error');
      } else {
        Swal.close();
        Swal.fire('¡Gracias!', 'Hemos enviado un enlace a tu correo electrónico para que puedas reestablecer tu contraseña', 'success');
        this.router.navigate(['/inicio-sesion']);
      }
    }catch(error) {
    console.log(error);

    Swal.fire('Oops...', 'Por favor verifique su correo electrónico e intente de nuevo', 'error');
  }

}

}
