<!-- <section class="desktop">
    <div class="container-fluid main">
        <div class="col-12">
            <div class="row align-items-center">
                <div class="col-2 logo-header" [routerLink]="['/inicio']">
                    <img src="../../../assets/images/logo-proceso.png" alt="">
                </div>
                <div class="col-10 bar-menu">
                    <ul>
                        <li><a [routerLink]="['/cursos']">Cursos</a></li>
                        <li><a [routerLink]="['/tool-select']">Suscripciones y Herramientas</a></li>
                        <li><a [routerLink]="['/analysis-select']">Análisis de Empresas</a></li>
                        <li class="us-li"><a [routerLink]="['/nosotros']">Nosotros</a></li>
                        <li class="bar">|</li>
                        <li class="login-li" *ngIf="!session"><a [routerLink]="['/login']">Iniciar Sesión </a></li>
                    </ul>
                    <button class="btn btn-success" [routerLink]="['/register']" *ngIf="!session">Registrarse</button>
                    <button class="btn btn-warning" *ngIf="session" (click)="showPortal()">Mis suscripciones</button>
                    <button class="btn btn-success" *ngIf="session" (click)="submit()">Cerrar Sesión</button>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="movil">
    <div class="container-fluid main">
        <div class="col-12">
            <div class="row align-items-center">
                <div class="col-4 hamburguer">
                    <img src="../../../assets/icons/menu-movil.svg" alt="" (click)="showMenu = true">
                </div>
                <div class="col-4 logo-header">
                    <img src="../../../assets/images/logo-proceso.png" alt="">
                </div>
                <div class="col-4 btn-regist-movil">
                    <button class="btn btn-success">Registrarse</button>
                </div>
            </div>
        </div>
    </div>
    <div class="back-overlay" (click)="showMenu = false" *ngIf="showMenu"></div>
    <div class="col-12 bar-menu-movil" [class.show-menu]="showMenu">
        <ul class="top-list">
            <li *ngIf="!session"><a routerLink="/login">Iniciar Sesión</a></li>
            <li *ngIf="!session"><a routerLink="/register">Registrarse</a></li>
            <li *ngIf="session"><a (click)="showPortal()">Mis suscripciones</a></li>
            <li *ngIf="session"><a (click)="submit()">Cerrar sesión</a></li>
        </ul>
        <ul>
            <li><a routerLink="/cursos">Cursos</a></li>
            <li><a routerLink="/tool-select">Suscripciones y Herramientas</a></li>
            <li><a href="">Análisis de Empresas</a></li>
            <li><a routerLink="/nosotros">Nosotros</a></li>
            <li *ngIf="!session"><a routerLink="/login">Iniciar Sesión</a></li>
        </ul>
        <button class="btn btn-default close-menu" (click)="showMenu = false"><i class="fa fa-times"></i></button>

    </div>
</section> -->

<nav [ngClass]="scrollFlag">
  <div class="container-fluid container">
    <div class="logo">
      <a href="https://landing.procesoresultado.com/" target="_blank"><img src="assets/images/logo-proceso-blanco.png" alt="" /></a>
    </div>

    <div class="hamburguer">
      <mat-icon (click)="closeMobileMenu()">menu</mat-icon>
    </div>

    <div class="menu" [ngClass]="{ showMenu: showMobileMenu }">

      <ul>
        <li class="close">
          <a href="javascript:void(0)" (click)="closeMobileMenu()">
            <mat-icon>close</mat-icon>
          </a>
        </li>
        <li>
          <a (click)="closeMobileMenu()" [routerLink]="['/cursos']">Cursos</a>
        </li>
        <li>
          <a (click)="closeMobileMenu()" [routerLink]="['/selecccionar-herramienta']"
            >Suscripciones y Herramientas</a
          >
        </li>
        <li>
          <a (click)="closeMobileMenu()" [routerLink]="['/analisis']"
            >Análisis de empresas</a
          >
        </li>
        <li>
          <a (click)="closeMobileMenu()" [routerLink]="['/nosotros']"
            >Nosotros</a
          >
        </li>
        <li>
          <a (click)="closeMobileMenu()" [routerLink]="['/blog']">Blog</a>
        </li>

        <li *ngIf="session" class="my-profile">
          <a (click)="closeMobileMenu()" [routerLink]="['/mi-perfil']">Mi perfil</a>
        </li>


        <li *ngIf="session" class="cart-icon menu-cart">
          <mat-icon (click)="closeMenuCart()" [matBadge]="(data$ | async)?.items.length" matBadgeColor="warn" matBadgeSize="small">shopping_cart</mat-icon>
        </li>

        <li *ngIf="session" class="cart-icon menu-cart"><!-- My profile -->
          <mat-icon (click)="closeMobileMenu()" [routerLink]="['/mi-perfil']" style="height: 28px; width: 28px; font-size: 28px;">account_circle</mat-icon>
        </li>


        <li *ngIf="!session">
          <button
            class="btn btn-suscripciones"
            (click)="closeMobileMenu()"
            [routerLink]="['/inicio-sesion']"
          >
            Iniciar Sesión
          </button>
        </li>
        <li *ngIf="!session">
          <button class="btn primary-btn" [routerLink]="['/registro']" (click)="closeMobileMenu()">
            Registrarse
          </button>
        </li>
        <li *ngIf="session">
          <button class="btn btn-cerrar-sesion" (click)="submit()">
            Cerrar Sesión
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="session" class="cart-icon outside-cart">
    <mat-icon (click)="closeMenuCart()" [matBadge]="(data$ | async)?.items.length" matBadgeColor="warn" matBadgeSize="small">shopping_cart</mat-icon>
  </div>
</nav>

<div class="overlay" (click)="closeMobileMenu()" *ngIf="showMobileMenu"></div>

<ng-container *ngIf="(data$ | async)!=null">
  <div class="cart" [ngClass]="{ showCart: showCartMenu }">
    <h2 class="cart-title">Tu carrito</h2>
    <div class="cart-content">
      <div class="cart-box">
        <div class="detail-box" *ngIf="!loader">
          <ng-container *ngFor="let item of (data$ | async)?.items">
            <div class="container-carrito">
              <img src="{{item.coverImage}}" class="cart-img" alt="">
              <div class="cart-product-title"><p > {{item.name}} </p></div>
              <div class="cart-price">${{item.price}}</div>
              <!-- Remove cart -->
            <i class="bx bxs-trash-alt cart-remove" (click)="delete(item.name)"></i>
            </div>
          </ng-container>
          <div>
            <hr>
            <p class="total-title">Total</p>
            <h4 class="real-price" *ngIf="!loader && (data$ | async).realPrice != undefined && (data$ | async).realPrice > 0"><span>${{(data$ | async).realPrice | number:'1.2-2'}} MXN</span> - 15% Desc.</h4>
            <h4 class="total-price" *ngIf="!loader">${{(data$ | async).total | number:'1.2-2'}} <strong> MXN</strong></h4>
          </div>
        </div>
      </div>
    </div>
    <button type="button" (click)="closeMenuCart()" class="btn-buy" [routerLink]="['/verificar-compra/']">Comprar ahora</button>
    <i class="bx bx-x" id="close-cart" (click)="closeMenuCart()"></i>
  </div>

</ng-container>
