<div *ngIf="loaderpage" class="loading-page">
    <div class="spinner"></div>
</div>
<section>
    <button mat-raised-button class="go-back-button" (click)="goBack()">
        <mat-icon matPrefix aria-hidden="false" aria-label="Example home icon">chevron_left</mat-icon>
        Regresar
    </button>
    <div class="banner">
        <div class="routing">
            <span> Proceso Resultado</span>
            <span> <i class="fas fa-chevron-right"></i></span>
            <span>Cursos</span>
            <span> <i class="fas fa-chevron-right"></i></span>
            <span *ngIf="courseInfo?.category == 1">Principiantes</span>
            <span *ngIf="courseInfo?.category == 2">Avanzados</span>
            <span *ngIf="courseInfo?.category == 3">Especializados</span>
        </div>
        <div class="title">
            <h1>{{courseInfo?.name}}</h1>
        </div>
    </div>
    <div class="content-container-main">
        <div class="content">
            <div class="content-course">
                <h1>Contenido del curso</h1>
            </div>
            <div class="content-details">
                <ng-container>
                    <div class="content-list" [innerHTML]="courseInfo?.syllabus"></div>
                </ng-container>
                <ng-container *ngIf="courseMaterials.length > 0">
                    <h3>Materiales:</h3>
                    <div class="content-list">
                        <ul class="materials-ul">
                            <ng-container *ngFor="let material of courseMaterials">
                                <li class="material-item">
                                    <span style="margin-right: 5px; font-size: 19px;">•</span>
                                    <ng-container *ngIf="material.isUrl"><a [href]="material.url" target="_blank">{{material.title}} <i class="fa-solid fa-link"></i></a></ng-container>
                                    <ng-container *ngIf="!material.isUrl"><a [href]="material.coverImage.url" target="_blank">{{material.title}} <i class="fa-solid fa-link"></i></a></ng-container>
                                </li>
                                <div class="material-description" [innerHTML]="material.description"></div>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>

            <div class="content-course">
                <h1>Descripción</h1>
            </div>
            <div class="description">
                <div [innerHTML]="courseInfo?.description"></div>
            </div>
        </div>
        <div class="payment-data">
            <div class="payment-column">
                <div class="course-img">
                    <img src="{{courseInfo?.coverImage}}" alt="">
                </div>
                <div class="course-info">
                    <h1 class="course-price">Precio: ${{courseInfo?.price}}.00 MXN</h1>
                    <div class="course-date">
                        <span class="course-date">Fecha: {{courseInfo?.date | date}}</span>
                        <span class="course-date">Hora:
                            {{courseInfo?.time.hourString}}:{{courseInfo?.time.minString}}</span>
                    </div>
                    <div class="btns-curse">
                        <button class="btn btn-buy" mat-flat-button (click)="validateAuth(courseID)">Comprar
                            Ahora</button>
                        <button *ngIf="isAuth" class="btn btn-cart" mat-flat-button
                            (click)="addCartItem(courseInfo)">Agregar al Carrito</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
