import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnInit {

  public userUid: string = '';
  public isSubActive: boolean = false;
  public isLoading: boolean = true;
  public pageLoader: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public subscriptionData: any,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    let isLoggedIn = await this.isLoggedIn();
    if (isLoggedIn) {
      this.getUserData();
    } else {
      this.router.navigate(['/inicio'])
    }
  }

  async isLoggedIn() {
    let response = await this.authService.isLoggedIn();
    this.userUid = response.uid
    return response.uid != undefined
  }

  async getUserData() {
    try {
      let response = await this.userService.getUserData(this.userUid);
      let stripeclient_id = response.stripeclient_id
      const subsResponse = await this.userService.getUserSubscriptions(stripeclient_id);  
      if(subsResponse.data.length <= 0) {
        this.isSubActive = false;
        this.isLoading = false;
        return
      }
      this.isSubActive = subsResponse.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
      throw error
    }
  }

  async cancelSubscription() {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro que deseas cancelar tu membresía?',
      text: 'Perderás todos tus beneficios actuales.',
      confirmButtonColor: "#e5514e",
      cancelButtonColor: "#757575",
      confirmButtonText: "Aceptar",
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: false,
      allowEnterKey: false,
  }).then(async (response) => {
    if(response.isConfirmed) {
      try {
        let response = await this.userService.deleteSuscription(this.subscriptionData.subscription.id)
        if (response.status == 'canceled') {
          this.shootSimpleAlert('success', 'Su membresía ha sido cancelada correctamente')
        } else {
          this.shootSimpleAlert('error', 'Algo salió mal, por favor intente de nuevo más tarde')
        }
      } catch (error) {
        this.shootSimpleAlert('error', 'Algo salió mal, por favor intente de nuevo más tarde')
        throw error
      }
      this.dialog.closeAll();
    }
  })

  }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
    });
  }
}
