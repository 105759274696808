import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/services/courses/courses.service';

@Component({
  selector: 'app-recommended-courses',
  templateUrl: './recommended-courses.component.html',
  styleUrls: ['./recommended-courses.component.scss']
})
export class RecommendedCoursesComponent implements OnInit {

  public courses: Array<Course>;
  public category: string;
  public loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private service: CoursesService
  ) { }

  async ngOnInit() {
    this.category = this.route.snapshot.params['category'];
    this.loading = true;
    try {
      var res = await this.service.getRecommendedCourse(parseInt(this.category));
      this.courses = res;
      this.loading = false;

    } catch (error: any) {
      console.log(error.message);
      this.loading = false;

    }
  }

}
