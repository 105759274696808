import { formatDate, ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { EChartsOption, color, List, graphic } from 'echarts';

import { FinancesService } from 'src/app/services/finances/finances.service';
import Swal from 'sweetalert2';
import { element } from 'protractor';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import html2canvas from "html2canvas";
import dark from "../../../assets/echarts-themes/dark.js"
import { ActivatedRoute } from '@angular/router';
import { Logger } from 'html2canvas/dist/types/core/logger.js';
import { ErrorLogsService } from 'src/app/services/error/error-logs.service';

@Component({
    selector: 'app-form-tendencias',
    templateUrl: './form-tendencias.component.html',
    styleUrls: ['./form-tendencias.component.scss']
})
export class FormTendenciasComponent implements OnInit {

    public spreedsheetId: string;
    public userUid: string;
    public isCreatingImage: boolean = false;
    currentTicker;
    currentTendTicker;
    currentBayesTicker1;
    currentBayesTicker2;
    form: FormGroup;
    hasTendenciesResult = false;
    hasBayesResult = false;
    hasPredictiveResult = false;
    hasResultComapre = false;
    trendingType: number;
    loader = false;
    tableTreding = [];
    tableTendencyPredictive = [];
    tableTredingToSheet = { fdx: [], date: [] };
    tableTredingCompare = { firstTicker: [], secondTicker: [] };
    tableTredingCompareToSheet = {
        date: [],
        firstTickerClose: [],
        secondTickerClose: [],
    };

    tablePredictive = [];
    tablePredictiveToSheet = { fdx: [], date: [] };
    tablePredictiveCompare = { firstTicker: [], secondTicker: [] };
    tablePredictiveCompareToSheet = {
        date: [],
        firstTickerClose: [],
        secondTickerClose: [],
    };

    errors = [];

    public temporalityFrontTendencies;
    public temporalityFrontBayes;

    public tendencyChartImg;
    public momentumChartImg;
    public bayesChartImg;
    public predictiveChartImg;

    public tendencyCreated;
    public momentumCreated;
    public bayesCreated;
    public predictiveCreated;

    public percentBarr: any;
    public max;

    public isMobile: boolean = false;

    public chartTendency: any;

    scrHeight: any;
    scrWidth: any;
    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        this.isMobile = false;
        if (this.scrWidth <= 768) this.isMobile = true;
    }

    ///////////////////////////////////
    // TENDENCY CHART OPTIONS
    ///////////////////////////////////
    pointChartOption: EChartsOption = {

        color: ['#5470c6', '#fac858', '#91cc75', '#3DFF00', '#FF0000'],
        textStyle: {
            fontSize: 13
        },
        xAxis: {
            type: 'category',
            data: [],
            inverse: true,
        },
        yAxis: [
            {
                type: 'value',
            },
            {
                type: 'value',
                offset: 0,
            },
        ],
        series: [

        ],
        media: [
            {
                query: {
                    maxAspectRatio: 1,
                    minWidth: 300,
                    maxWidth: 500
                },
                option: {

                }
            }
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: function (param: any) {

                //console.log("Parametro", param);
                let markerup = `<span style=\"display:inline-block;margin-right:4px;border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 10px solid black;\"></span>`;
                let markerdown = `<span style=\"display:inline-block;margin-right:4px;border-left: 7px solid transparent;border-right: 7px solid transparent;border-top: 10px solid red;\"></span>`;

                let res = param[0].name + '<br/>';

                // for (let i = 0; i < param.length; i++) {
                if (param.seriesIndex == 0) {
                    res +=
                        `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>` +
                        ' ' +
                        param.seriesName +
                        ': ' +
                        parseFloat(param.data['value']).toFixed(2) +
                        '<br/>';
                }
                if (param.seriesIndex == 1) {
                    res +=
                        `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#fac858;"></span>` +
                        ' ' +
                        param.seriesName +
                        ': ' +
                        parseFloat(param.data['value']).toFixed(2) +
                        '<br/>';
                }
                if (param.seriesIndex == 2) {
                    res +=
                        `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#91cc75;"></span>` +
                        ' ' +
                        param.seriesName +
                        ': ' +
                        parseFloat(param.data['value']).toFixed(2) +
                        '<br/>';
                }
                if (param.seriesIndex == 3) {
                    let value = param.data['value'] == 'NaN' ? '' : param.data['value'];
                    res += markerup + ' ' + param.seriesName + ': ' + value + '<br/>';
                }
                if (param.seriesIndex == 4) {
                    let value = param.data['value'] == 'NaN' ? '' : param.data['value'];

                    res += markerdown + ' ' + param.seriesName + ': ' + value + '<br/>';
                }
                // }
                return res;
            },
        },
        legend: {
            data: [],
            top: '5%',
        },
        grid: {
            left: '10%',
            right: '10%',
            bottom: '15%',
            top: '15%'
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 50,
                xAxisIndex: [0, 1],
            },
            {
                type: 'inside',
                realtime: true,
                start: 0,
                end: 50,
                xAxisIndex: [0, 1],
            },
        ],
    };

    ///////////////////////////////////
    // MOMENTUM CHART OPTIONS
    ///////////////////////////////////
    barChartOption: EChartsOption = {
        textStyle: {
            fontSize: 16
        },
        title: {
            text: '',
        },
        legend: {
            data: [],
        },
        toolbox: {},
        tooltip: {},
        xAxis: {
            data: [],
            splitLine: {
                show: false,
            },
            inverse: true,
        },
        yAxis: {},
        series: [
            // {
            //   name: 'RoC',
            //   type: 'bar',
            //   data: [],
            //   emphasis: {
            //     focus: 'series'
            //   },
            //   animationDelay: function (idx) {
            //     return idx * 10;
            //   }
            // },
            {
                name: 'Trend',
                type: 'bar',
                data: [],
                emphasis: {
                    focus: 'series',
                },
                animationDelay: function (idx) {
                    return idx * 10 + 100;
                },
            },
        ],
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 50,
                xAxisIndex: [0, 1],
            },
            {
                type: 'inside',
                realtime: true,
                start: 0,
                end: 50,
                xAxisIndex: [0, 1],
            },
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
            return idx * 5;
        },
    };

    ///////////////////////////////////
    // BAYES CHART OPTIONS
    ///////////////////////////////////
    barChartCompareOption: EChartsOption = {
        toolbox: {},
        legend: {},
        textStyle: {
            fontSize: 16
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: [],
            axisTick: {
                alignWithLabel: true,
            },
            axisLabel: { interval: 0, rotate: 90, fontSize: 18 },
        },
        yAxis: {
            type: 'value',
            scale: true,
            axisLabel: { formatter: '{value}%' },
            // max: 100,
            min: 0,
            splitArea: {
                show: true,
            },
        },
        series: [
            {
                data: [

                ],
                type: 'bar',
                barWidth: '50%',
                //color of bar
                itemStyle: {
                    shadowBlur: 5,
                    shadowOffsetX: 5,
                    shadowColor: "#EBEBEB",
                    borderRadius: 10,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        // { offset: 0, color: '#83bff6' },
                        // { offset: 1, color: '#188df0' }
                        // { offset: 0, color: '#ffd769' },
                        // { offset: 1, color: '#FEC006' }
                        { offset: 0, color: '#F2B807 ' }, //amarillo
                        { offset: 1, color: '#F2A007' } //claro
                    ])
                },
                //color of cursor
                emphasis: {
                    itemStyle: {
                        shadowColor: "black",
                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                            // { offset: 0, color: '#2378f7' },
                            // { offset: 1, color: '#83bff6' }
                            { offset: 0, color: '#5CCD82' },
                            { offset: 1, color: '#3d8a57' }
                            // { offset: 0, color: '#078C41' },
                            // { offset: 1, color: '#037337' }
                        ])
                    }
                }
            },
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
            return idx * 5;
        },
    };

    public predictiveChartConfig: EChartsOption = null;
    public predictiveChartExtendConfig: EChartsOption = null;
    public userLoader: boolean;

    options: Observable<any>;
    hasPermission: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private auth: AuthService,
        private service: FinancesService,
        private viewScroller: ViewportScroller,
        private route: ActivatedRoute,
        private errorService: ErrorLogsService
    ) {
        this.getScreenSize();
        this.form = this.formBuilder.group({
            ticker: ['SPY', Validators.required],
            ticker2: ['AAPL'],
            frequency: ['1d', Validators.required],
            compare: [false],
        });
    }

    async ngOnInit(): Promise<void> {
        this.userLoader = true;
        this.hasPermission = this.route.snapshot.params['status'];

        this.auth.afAuth.onAuthStateChanged(async user => {
            let userId = user.uid;
            const answer: any = await this.userService.getUserData(userId);
            console.log('usuario',answer);
            this.spreedsheetId = answer.googleSheetId;
            this.userLoader = false;
        })
    }

    checkCompare() {
        if (this.form.value.compare) {
            this.form.controls.ticker2.setValidators([Validators.required]);
            this.form.controls.ticker2.updateValueAndValidity();
        } else {
            this.form.controls.ticker2.setValue('');
            this.form.controls.ticker2.clearValidators();
            this.form.controls.ticker2.updateValueAndValidity();
        }
    }

    createImage(chart) {
        this.isCreatingImage = true;
        switch (chart) {
            case 1:
                html2canvas(document.querySelector("#tendencies-chart")).then(canvas => {
                    this.tendencyChartImg = canvas.toDataURL();

                });
                this.tendencyCreated = true;
                this.isCreatingImage = false;
                break;

            case 2:
                html2canvas(document.querySelector("#momentum-chart")).then(canvas => {
                    this.momentumChartImg = canvas.toDataURL();
                });
                this.momentumCreated = true;
                this.isCreatingImage = false;
                break;

            case 3:
                html2canvas(document.querySelector("#bayes-chart")).then(canvas => {
                    this.bayesChartImg = canvas.toDataURL();
                });
                this.bayesCreated = true;
                this.isCreatingImage = false;
                break;

            case 4:
                html2canvas(document.querySelector("#predictive-chart")).then(canvas => {
                    this.predictiveChartImg = canvas.toDataURL();
                });
                this.predictiveCreated = true;
                this.isCreatingImage = false;
                break;

            default:
                break;
        }
    }

    searchTendencies() {
        this.tendencyChartImg = "";
        this.momentumChartImg = "";
        this.bayesChartImg = "";
        this.predictiveChartImg = "";
        this.tendencyCreated = false;
        this.momentumCreated = false;
        this.bayesCreated = false;
        this.predictiveCreated = false;

        let timeCounter = setInterval(() => {
            Swal.fire(
                'Lo sentimos',
                'El proveedor de datos de Yahoo está experimentando un problema',
                'error'
            );
            this.errorService.sendErrorLog(new Error('Time counter'), 'searchTendencies');
            clearInterval(timeCounter);
            this.loader = false;
            return
        }, 60001);

        if (
            this.form.value.ticker == null || this.form.value.ticker == '' || this.form.value.ticker == undefined
            || this.form.value.frequency == null || this.form.value.frequency == '' || this.form.value.frequency == undefined
        ) {
            Swal.fire(
                'Error',
                'Favor de llenar los campos requeridos',
                'error'
            );

            return;
        }

        this.loader = true;
        this.hasTendenciesResult = false;
        this.form.value.ticker = this.form.value.ticker.toUpperCase();
        this.currentTendTicker = this.form.value.ticker;
        this.errors = [];
        this.tableTreding = [];
        this.tableTredingToSheet = { fdx: [], date: [] };
        this.tableTredingCompare = { firstTicker: [], secondTicker: [] };
        this.tableTredingCompareToSheet = {
            date: [],
            firstTickerClose: [],
            secondTickerClose: [],
        };
        this.temporalityFrontTendencies = this.form.value.frequency;

        var period1 = new Date();
        period1.setHours(0, 0, 0);
        let body = {
            period1: 0,
            period2: Math.floor(period1.getTime() / 1000),
            symbol: this.form.value.ticker,
            frequency: this.form.value.frequency,
            filter: 'history',
        };
        // OBTENGO INFO PARA API
        switch (this.form.value.frequency) {
            case '1d':
                var period2 = this.getTempoToDay(new Date(), this.form.value.frequency);
                body.period1 = period2;

                break;
            case '1wk':
                var period2 = this.getTempoToDay(new Date(), this.form.value.frequency);
                body.period1 = period2;
                break;
            case '1mo':
                var period2 = this.getTempoToDay(new Date(), this.form.value.frequency);
                body.period1 = period2;
                break;

            default:
                break;
        }
        // CONSULTA DE API
        //console.log('body', body);

        this.service.getHistoricalData(body).subscribe({
            next: async (data: any) => {
                // VALIDACION SI EXISTE DATA O PRICES
                // SI EL NOMBRE DEL TICKER TRAE INFO, ENTONCES EL TICKER EXISTE
                if (
                    data == null ||
                    Object.keys(data).length == 0 ||
                    data['prices'].length == 0
                ) {
                    Swal.fire(
                        'Lo sentimos',
                        'No hemos econtrado resultados para los datos ingresados, intentelo más tarde o ingrese otros datos',
                        'warning'
                    );
                    this.loader = false;
                    this.hasTendenciesResult = false;
                    this.form.reset();
                    clearInterval(timeCounter);
                } else {
                    // console.log(data['prices'])
                    // LLENADO DE ARRAY PARA LA TABLA: tableTreding
                    let prices: Array<Element> = data['prices'];
                    prices.sort(function (a, b) {
                        return a.date - b.date;
                    })
                    prices.reverse();
                    let previousPrice;
                    let closeCounter = 0;
                    prices = popNoPrice(prices);
                    await Promise.all(
                        prices.map(async (element) => {
                            if (element.type == undefined) {
                                var d = new Date(0);
                                d.setUTCSeconds(element.date);
                                // TODO: La siguiente validación hace que la gráfica caiga a 0
                                let closed =
                                    element.close != undefined ? element.close.toFixed(2) : 0.0;
                                if (element.close != undefined) {
                                    if (previousPrice == element.close) {
                                        closeCounter++
                                        if (closeCounter == 5) {
                                            Swal.fire(
                                                'Lo sentimos',
                                                'El proveedor de datos de Yahoo está experimentando un problema',
                                                'error'
                                            );
                                            clearInterval(timeCounter);
                                        }
                                    } else {
                                        closeCounter == 0;
                                    }
                                    previousPrice = element.close
                                    this.tableTreding.push({
                                        close: element.close,
                                        date: d,
                                        high: element.high,
                                        low: element.low,
                                        open: element.open,
                                    });
                                }
                                if (closed != 0) {
                                    this.tableTredingToSheet['fdx'].push(closed);
                                    this.tableTredingToSheet['date'].push(
                                        formatDate(d, 'MMM-dd-yy', 'en-US')
                                    );
                                }
                            }
                        })
                    );

                    //console.log("tableTredingToSheet",this.tableTredingToSheet);


                    this.service
                        .sendTickerInfoToSheet(this.tableTredingToSheet, this.spreedsheetId)
                        .subscribe({
                            next: (data) => {
                                this.service
                                    .getDataToPointChart(this.currentTendTicker, this.spreedsheetId)
                                    .subscribe({
                                        next: async (datachart) => {

                                            console.log("------------chart---------", datachart["result"].data);


                                            //console.log("------------NEW---chart---------", datachart["result"]);
                                            // this.currentTendTicker
                                            // console.log(this.currentTendTicker);

                                            this.pointChartOption.legend['data'] = [
                                                this.currentTendTicker,
                                                'TREND',
                                                'MA 6mo',
                                                'Buy/Acc',
                                                'Close/Hedge',
                                            ];


                                            this.chartTendency = datachart['result'].data;
                                            //console.log("Información de la grafica: ", this.chartTendency);

                                            //Elimina el ultimo valor si llega en 0
                                            this.chartTendency.forEach(element => {
                                                let lastValue = element.data.slice(-1)[0].value
                                                console.log("El ultimo valor de " + element.name + " es : ", lastValue);
                                                if (lastValue == 0) {
                                                    element.data.pop();
                                                    let penValue = element.data.slice(-1)[0]
                                                    element.data.push(penValue);
                                                }
                                            });


                                            this.pointChartOption.xAxis['data'] = [];
                                            this.barChartOption.xAxis['data'] = [];

                                            await Promise.all(
                                                this.tableTreding.map(async (element) => {

                                                    let date = formatDate(
                                                        element.date,
                                                        'dd-MMM-yy',
                                                        'en-US'
                                                    );
                                                    this.pointChartOption.xAxis['data'].push(date);
                                                    this.barChartOption.xAxis['data'].push(date);
                                                })
                                            );

                                            //this.pointChartOption.series = datachart['result'].data;
                                            this.pointChartOption.series = this.chartTendency;
                                            console.log("pointChartOption.series", this.pointChartOption.series);
                                            // this.barChartOption.series[0].data = datachart['result'].analysisData.roc;
                                            this.barChartOption.series[0].data =
                                                datachart['result'].analysisData.trend;

                                            console.log(this.chartTendency);


                                            this.loader = false;
                                            this.hasTendenciesResult = true;
                                            this.form.reset();
                                            clearInterval(timeCounter);
                                            //console.log("pointChartOption", this.pointChartOption);
                                        },
                                        error: (error) => {
                                            // console.log(error);
                                            this.errorService.sendErrorLog(error, 'getDataToPointChart');
                                            Swal.fire(
                                                'Lo sentimos',
                                                'ha ocurrido un error inesperado, intente de nuevo mas tarde',
                                                'error'
                                            );
                                            this.loader = false;
                                            this.hasTendenciesResult = false;
                                            this.form.reset();
                                            clearInterval(timeCounter);
                                        },
                                    });
                            },
                            error: (error) => {
                                // console.log(error);
                                this.errorService.sendErrorLog(error, 'sendTickerInfoToSheet');
                                Swal.fire(
                                    'Lo sentimos',
                                    'ha ocurrido un error inesperado, intente de nuevo mas tarde',
                                    'error'
                                );
                                this.loader = false;
                                this.hasTendenciesResult = false;
                                this.form.reset();
                                clearInterval(timeCounter);
                            },
                        });
                }
            },
            error: (error) => {
                console.error('There was an error!', error);
                this.errorService.sendErrorLog(error, 'getHistoricalData');
                Swal.fire(
                    'Lo sentimos',
                    'Hubo un error en el proceso. Intente de nuevo.',
                    'warning'
                );
                this.loader = false;
                this.hasPredictiveResult = false;
                this.form.reset();
                clearInterval(timeCounter);
            },
        });
    }

    searchBayes() {
        this.tendencyChartImg = "";
        this.momentumChartImg = "";
        this.bayesChartImg = "";
        this.predictiveChartImg = "";
        this.tendencyCreated = false;
        this.momentumCreated = false;
        this.bayesCreated = false;
        this.predictiveCreated = false;

        let timeCounter = setInterval(() => {
            Swal.fire(
                'Lo sentimos',
                'El proveedor de datos de Yahoo está experimentando un problema',
                'error'
            );
            this.errorService.sendErrorLog(new Error('Time counter'), 'searchBayes');
            clearInterval(timeCounter);
            this.loader = false;
            return
        }, 60001);

        if (
            this.form.value.ticker == null || this.form.value.ticker == '' || this.form.value.ticker == undefined
            || this.form.value.ticker2 == null || this.form.value.ticker2 == '' || this.form.value.ticker2 == undefined
            || this.form.value.frequency == null || this.form.value.frequency == '' || this.form.value.frequency == undefined
        ) {
            Swal.fire(
                'Error',
                'Favor de llenar los campos requeridos',
                'error'
            );

            return;
        }

        this.loader = true;
        this.hasBayesResult = false;
        this.form.value.ticker = this.form.value.ticker.toUpperCase();
        this.form.value.ticker2 = this.form.value.ticker2.toUpperCase();
        this.currentBayesTicker1 = this.form.value.ticker;
        this.currentBayesTicker2 = this.form.value.ticker2;
        this.errors = [];
        this.tableTreding = [];
        this.tableTredingToSheet = { fdx: [], date: [] };
        this.tableTredingCompare = { firstTicker: [], secondTicker: [] };
        this.tableTredingCompareToSheet = {
            date: [],
            firstTickerClose: [],
            secondTickerClose: [],
        };

        // SE VALIDA SI NO ES EL MISMO
        if (this.form.value.ticker == this.form.value.ticker2) {
            this.errors.push({
                error: 'Los tickers a comparar no pueden ser los mismos',
            });
            this.loader = false;
            this.hasBayesResult = false;
            this.form.reset();
            clearInterval(timeCounter);
        } else {
            var period0 = new Date();
            period0.setHours(0, 0, 0);

            // OBTENGO INFO PARA la consuolta de la API
            let firstTickerBody = {
                period1: 0,                              //setea el dia de consulta de 0 hasta el dia de hoy

                //
                //le quitamos los decimales al cociente
                period2: Math.floor(period0.getTime() / 1000),
                symbol: this.form.value.ticker,         //abreviatura del ticker a consultar
                frequency: this.form.value.frequency,   //El periodo: ¿¿dia, semana, mes??
                filter: 'history',                      //???  Ni idea
            };

            let secondTickerBody = {
                //Pada ver los comentarios, ver FirstTickerBody (es el de arriba)
                period1: 0,
                period2: Math.floor(period0.getTime() / 1000),
                symbol: this.form.value.ticker2,
                frequency: this.form.value.frequency,
                filter: 'history',
            };
            //?????
            //      Nota puesta por mike
            //Creo que lo que hace es:
            //Transforma la fecha. Pasarla de fecha normal a segundos,
            //dependiendo de la periodicidad.
            //La api lee las fechas en segundos.
            //No entendí muy bien como lo hace :c

            //Este switch debería setear period1 de ambos tickers de la siguiente forma:
            //dependiendo de la periodicidad, periode1 debe ser 150 registros menor de la
            //fecha actual (period2)
            switch (this.form.value.frequency) {
                case '1d':
                    // console.log('Preiodo1 Funcion')
                    var periodfirstTicker2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    // console.log('Preiodo2 Funcion')
                    var periodsecondTickerBody2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    // console.log('Preiodo1')
                    firstTickerBody.period1 = periodfirstTicker2;
                    // console.log(firstTickerBody.period1)
                    // console.log('Preiodo2')
                    secondTickerBody.period1 = periodsecondTickerBody2;
                    // console.log(secondTickerBody.period1)

                    break;
                case '1wk':
                    var periodfirstTicker2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    var periodsecondTickerBody2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    firstTickerBody.period1 = periodfirstTicker2;
                    secondTickerBody.period1 = periodsecondTickerBody2;

                    break;
                case '1mo':
                    var periodfirstTicker2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    var periodsecondTickerBody2 = this.getTempoToDay(
                        new Date(),
                        this.form.value.frequency
                    );
                    firstTickerBody.period1 = periodfirstTicker2;
                    secondTickerBody.period1 = periodsecondTickerBody2;

                    break;
                default:
                    break;
            }
            this.temporalityFrontBayes = this.form.value.frequency;
            // CONSULTO DE API CON TICKER 1
            this.service.getHistoricalData(firstTickerBody).subscribe({
                next: async (data) => {
                    //VALIDACION SI EXISTE DATA O PRICES PARA TICKER 1
                    //Validamos que nos traiga datos la api
                    //si el symbol no existe, la api no regresará nada
                    //De esta manera detectamos si el existe el ticker ingresado o no
                    if (data == null || data['prices'].length == 0) {
                        Swal.fire(
                            'Lo sentimos',
                            'No hemos econtrado resultados para el ticker "'
                            + firstTickerBody.symbol +
                            '", verifiquelo e intente de nuevo o ingrese otro diferente',
                            'warning'
                        );
                        this.loader = false;
                        this.hasBayesResult = false;
                        this.form.reset();
                        clearInterval(timeCounter);
                    }
                    //Quitamos los objetos que no son type price
                    //esto debido a que debemos saber especificamente
                    //cuantos objetos type price nos otorga la api
                    let prices: Array<Element> = popNoPrice(data['prices']);
                    // console.log(prices)
                    //Si la api nos otorga menos de 150, el GoogleSheets tendrá un error
                    //y no podrá graficar.
                    if (prices.length < 150) {
                        Swal.fire(
                            'Lo sentimos',
                            'Datos de cierre insuficientes en el ticker "'
                            + firstTickerBody.symbol +
                            '", ingrese otros datos',
                            'warning'
                        );
                        this.loader = false;
                        this.hasTendenciesResult = false;
                        this.form.reset();
                        clearInterval(timeCounter);
                    } else {
                        //let prices = data['prices'];
                        //console.log(prices);
                        await Promise.all(
                            prices.map(async (element) => {
                                if (element.type == undefined) {   //Filtra los objetos type 'Dividend' y 'Split'
                                    // console.log(element);
                                    var d = new Date(0);
                                    d.setUTCSeconds(element.date);

                                    let closed =
                                        element.close != undefined ? element.close.toFixed(2) : 0.0;
                                    this.tableTredingCompare['firstTicker'].push({
                                        close: closed,
                                        date: d,
                                        high: element.high,
                                        low: element.low,
                                        open: element.open,
                                    });
                                }
                            })
                        );
                        // CONSULTO DE API CON TICKER 2
                        this.service.getHistoricalData(secondTickerBody).subscribe({
                            next: async (data) => {
                                // VALIDACION SI EXISTE DATA O PRICES PARA TICKER 2
                                //Validamos que nos traiga datos la api
                                //si el symbol no existe, la api no regresará nada
                                //De esta manera detectamos si el existe el ticker ingresado o no
                                if (data == null || data['prices'].length == 0) {
                                    Swal.fire(
                                        'Lo sentimos',
                                        'No hemos econtrado resultados para el ticker "' +
                                        secondTickerBody.symbol +
                                        '", verifiquelo e intente de nuevo o ingrese otro diferente',
                                        'warning'
                                    );
                                    this.loader = false;
                                    this.hasBayesResult = false;
                                    this.form.reset();
                                    clearInterval(timeCounter);
                                }
                                //Quitamos los objetos que no son type price
                                //esto debido a que debemos saber especificamente
                                //cuantos objetos type price nos otorga la api
                                let prices: Array<Element> = popNoPrice(data['prices']);
                                // console.log(prices)
                                //Si la api nos otorga menos de 150, el GoogleSheets tendrá un error
                                //y no podrá graficar.
                                if (prices.length < 150) {
                                    Swal.fire(
                                        'Lo sentimos',
                                        'Datos de cierre insuficientes en el ticker "'
                                        + secondTickerBody.symbol +
                                        '", ingrese otros datos',
                                        'warning'
                                    );
                                    this.loader = false;
                                    this.hasTendenciesResult = false;
                                    this.form.reset();
                                    clearInterval(timeCounter);
                                } else {
                                    //let prices = data['prices'];
                                    //console.log(prices);
                                    await Promise.all(
                                        prices.map(async (element) => {
                                            if (element.type == undefined) {   //Filtra los objetos type 'Dividend' y 'Split'
                                                // console.log(element);
                                                var d = new Date(0);
                                                d.setUTCSeconds(element.date);
                                                let closed =
                                                    element.close != undefined ? element.close.toFixed(2) : 0.0;

                                                this.tableTredingCompare['secondTicker'].push({
                                                    close: closed,
                                                    date: d,
                                                    high: element.high,
                                                    low: element.low,
                                                    open: element.open,
                                                });

                                                this.tableTredingCompareToSheet['date'].push(
                                                    formatDate(d, 'MMM-dd-yy', 'en-US')
                                                );
                                            }
                                        })
                                    );
                                    // LLENADO DE DATOS PARA SHEET
                                    await Promise.all(
                                        this.tableTredingCompare['firstTicker'].map((ticker1) => {
                                            this.tableTredingCompareToSheet['firstTickerClose'].push(
                                                ticker1.close
                                            );
                                        })
                                    );
                                    await Promise.all(
                                        this.tableTredingCompare['secondTicker'].map((ticker2) => {
                                            this.tableTredingCompareToSheet['secondTickerClose'].push(
                                                ticker2.close
                                            );
                                        })
                                    );

                                    this.service
                                        .sendTickersComparationToSheet(
                                            this.tableTredingCompareToSheet, this.spreedsheetId
                                        )
                                        .subscribe({
                                            next: (datacompare) => {
                                                this.service.getDataComparationToBarChart(this.spreedsheetId).subscribe({
                                                    next: async (datachart) => {
                                                        let result = datachart['result'].data;
                                                        let SUMbothUp = 0;
                                                        let SUMbothDown = 0;
                                                        let SUMUpDown = 0;
                                                        let SUMdownUp = 0;

                                                        await Promise.all(
                                                            result['bothUp'].map((bothUp, index) => {
                                                                SUMbothUp += bothUp == null ? 0 : bothUp;
                                                            })
                                                        );
                                                        await Promise.all(
                                                            result['bothDown'].map((bothDown) => {
                                                                SUMbothDown += bothDown == null ? 0 : bothDown;
                                                            })
                                                        );
                                                        await Promise.all(
                                                            result['upDown'].map((upDown) => {
                                                                SUMUpDown += upDown == null ? 0 : upDown;
                                                            })
                                                        );
                                                        await Promise.all(
                                                            result['downUp'].map((downUp) => {
                                                                SUMdownUp += downUp == null ? 0 : downUp;
                                                            })
                                                        );

                                                        let LabelForChart = [
                                                            'Prob. ' +
                                                            this.form.value.ticker +
                                                            ' suba dado a ' +
                                                            this.form.value.ticker2 +
                                                            ' baje',
                                                            'Prob. ' +
                                                            this.form.value.ticker +
                                                            ' suba dado a ' +
                                                            this.form.value.ticker2 +
                                                            ' sube',
                                                            'Prob. ' +
                                                            this.form.value.ticker +
                                                            ' baje dado a ' +
                                                            this.form.value.ticker2 +
                                                            ' baje',
                                                            'Prob. ' +
                                                            this.form.value.ticker +
                                                            ' baje dado a ' +
                                                            this.form.value.ticker2 +
                                                            ' sube',
                                                            'Prob. ' +
                                                            this.form.value.ticker2 +
                                                            ' suba dado a ' +
                                                            this.form.value.ticker +
                                                            ' baje',
                                                            'Prob. ' +
                                                            this.form.value.ticker2 +
                                                            ' suba dado a ' +
                                                            this.form.value.ticker +
                                                            ' sube',
                                                            'Prob. ' +
                                                            this.form.value.ticker2 +
                                                            ' baje dado a ' +
                                                            this.form.value.ticker +
                                                            ' baje',
                                                            'Prob. ' +
                                                            this.form.value.ticker2 +
                                                            ' baje dado que ' +
                                                            this.form.value.ticker +
                                                            ' sube',
                                                        ];

                                                        this.barChartCompareOption.xAxis['data'] =
                                                            LabelForChart;

                                                        let SUMTicker2Up = SUMbothUp + SUMdownUp;
                                                        let SUMTicker2Down = SUMUpDown + SUMbothDown;
                                                        let SUMTicker1Up = SUMbothUp + SUMUpDown;
                                                        let SUMTicker1Down = SUMdownUp + SUMbothDown;

                                                        let percentTicker1Up =
                                                            (SUMUpDown / SUMTicker2Down) * 100;
                                                        let percentTicker1Up2 =
                                                            (SUMbothUp / SUMTicker2Up) * 100;
                                                        let percentTicker1Down =
                                                            (SUMbothDown / SUMTicker2Down) * 100;
                                                        let percentTicker1Down2 =
                                                            (SUMUpDown / SUMTicker2Up) * 100;

                                                        let percentTicker2Up =
                                                            (SUMdownUp / SUMTicker1Down) * 100;
                                                        let percentTicker2Up2 =
                                                            (SUMbothUp / SUMTicker1Up) * 100;
                                                        let percentTicker2Down =
                                                            (SUMbothDown / SUMTicker1Down) * 100;
                                                        let percentTicker2Down2 =
                                                            (SUMUpDown / SUMTicker1Up) * 100;

                                                        this.barChartCompareOption.series[0].data = [
                                                            { value: parseFloat(percentTicker1Up.toFixed(2),) },
                                                            { value: parseFloat(percentTicker1Up2.toFixed(2),) },
                                                            { value: parseFloat(percentTicker1Down.toFixed(2),) },
                                                            { value: parseFloat(percentTicker1Down2.toFixed(2)) },
                                                            { value: parseFloat(percentTicker2Up.toFixed(2),) },
                                                            { value: parseFloat(percentTicker2Up2.toFixed(2),) },
                                                            { value: parseFloat(percentTicker2Down.toFixed(2),) },
                                                            { value: parseFloat(percentTicker2Down2.toFixed(2)) },
                                                        ];
                                                        let array = this.barChartCompareOption.series[0].data.map((item) => item.value)
                                                        this.max = Math.max.apply(null, array);


                                                        this.loader = false;
                                                        this.hasBayesResult = true;
                                                        this.form.reset();
                                                        clearInterval(timeCounter);
                                                        this.percentBarr = this.barChartCompareOption.series[0].data;
                                                        console.log("percentBarr", this.percentBarr);
                                                        let indice = this.percentBarr.findIndex((item) => item.value == this.max)

                                                        console.log("Indice del mayor", indice);
                                                        this.barChartCompareOption.series[0].data[indice].itemStyle = { color: '#70CA94' }
                                                    },
                                                    error: (errorchart) => {
                                                        this.errorService.sendErrorLog(errorchart, 'getDataComparationToBarChart');
                                                        Swal.fire(
                                                            'Lo sentimos',
                                                            'Hubo un error en el proceso. Intente de nuevo.',
                                                            'warning'
                                                        );
                                                        this.loader = false;
                                                        this.hasPredictiveResult = false;
                                                        this.form.reset();
                                                        clearInterval(timeCounter);
                                                    },
                                                });
                                            },
                                            error: (err2) => {
                                                this.errorService.sendErrorLog(err2, 'sendTickersComparationToSheet');
                                                console.log(err2);
                                                Swal.fire(
                                                    'Lo sentimos',
                                                    'Hubo un error en el proceso. Intente de nuevo.',
                                                    'warning'
                                                );
                                                this.loader = false;
                                                this.hasPredictiveResult = false;
                                                this.form.reset();
                                                clearInterval(timeCounter);
                                            },
                                        });
                                }
                            },
                            error: (err2) => {
                                this.errorService.sendErrorLog(err2, 'getHistoricalData');
                                console.log(err2);
                                Swal.fire(
                                    'Lo sentimos',
                                    'Hubo un error en el proceso. Intente de nuevo.',
                                    'warning'
                                );
                                this.loader = false;
                                this.hasPredictiveResult = false;
                                this.form.reset();
                                clearInterval(timeCounter);
                            },
                        });
                    }
                },
                error: (err) => {
                    this.errorService.sendErrorLog(err, 'getHistoricalData');
                    console.log(err);
                    Swal.fire(
                        'Lo sentimos',
                        'Hubo un error en el proceso. Intente de nuevo.',
                        'warning'
                    );
                    this.loader = false;
                    this.hasPredictiveResult = false;
                    this.form.reset();
                    clearInterval(timeCounter);
                },
            });
        }
    }

    //Regresa 150 periodos con respecto a la fecha actual,
    //y lo retorna.
    getTempoToDay(date, tempo) {
        let period2: number;

        //Establece la hora del dia actual en 00:00:00
        date.setHours(0, 0, 0);

        // SE OBTIENE FECHAS PASADAS DEPENDIENDO DE this.form.value.frequency
        switch (tempo) {
            case '1d':
                //Redondeo hacia abajo
                // console.log(new Date(date.setDate(date.getDate() - 150)))
                period2 = Math.floor(
                    new Date(date.setDate(date.getDate() - 150)).getTime() / 1000
                );
                break;
            case '1wk':
                period2 = Math.floor(
                    new Date(date.setDate(date.getDate() - 150 * 7)).getTime() / 1000
                );
                break;
            case '1mo':
                period2 = Math.floor(
                    new Date(date.setMonth(date.getMonth() - 150)).getTime() / 1000
                );
                break;
            default:
                break;
        }

        return period2;
    }

    async searchPredictive() {

        this.tendencyChartImg = "";
        this.momentumChartImg = "";
        this.bayesChartImg = "";
        this.predictiveChartImg = "";
        this.tendencyCreated = false;
        this.momentumCreated = false;
        this.bayesCreated = false;
        this.predictiveCreated = false;

        let timeCounter = setInterval(() => {
            Swal.fire(
                'Lo sentimos',
                'El proveedor de datos de Yahoo está experimentando un problema',
                'error'
            );
            this.errorService.sendErrorLog(new Error('Time counter'), 'searchPredictive');
            clearInterval(timeCounter);
            this.loader = false;
            return
        }, 80001);

        if (this.form.value.ticker == null || this.form.value.ticker == '') {
            Swal.fire(
                'Error',
                'Favor de llenar los campos requeridos',
                'error'
            );

            return;
        }

        this.loader = true;
        this.hasPredictiveResult = false;
        this.form.value.ticker = this.form.value.ticker.toUpperCase();
        this.currentTicker = this.form.value.ticker;
        this.errors = [];
        this.tablePredictive = [];
        this.tablePredictiveToSheet = { fdx: [], date: [] };
        this.tablePredictiveCompare = { firstTicker: [], secondTicker: [] };
        this.tablePredictiveCompareToSheet = {
            date: [],
            firstTickerClose: [],
            secondTickerClose: [],
        };

        var period1 = new Date();
        period1.setHours(0, 0, 0);
        let body = {
            period1: -778528800,
            period2: Math.floor(period1.getTime() / 1000),
            symbol: this.form.value.ticker,
            frequency: this.form.value.frequency,
            filter: 'history',
        };

        // let body = {
        //   symbol: this.form.value.ticker,
        // }

        console.log('body', body);


        // CONSULTA DE API
        this.service.getAllHistoricalDataPerDay(body).subscribe({
            next: async (data) => {
                console.log('Data: ', data);

                // VALIDACION SI EXISTE DATA O PRICES
                if (data == null || data['prices'].length == 0) {
                    Swal.fire(
                        'Lo sentimos',
                        'No hemos econtrado resultados para los datos ingresados, intentelo más tarde o ingrese otros datos',
                        'warning'
                    );
                    this.loader = false;
                    this.hasPredictiveResult = false;
                    this.form.reset();
                    clearInterval(timeCounter);
                } else {
                    // LLENADO DE ARRAY PARA LA TABLA: tableTrending
                    let prices = data['prices'];
                    await Promise.all(
                        prices.map(async (element) => {
                            if (element.type == undefined) {
                                var d = new Date(0);
                                d.setUTCSeconds(element.date);
                                let closed =
                                    element.close != undefined ? element.close.toFixed(2) : 0.0;
                                this.tablePredictive.push({
                                    close: element.close,
                                    date: d,
                                    high: element.high,
                                    low: element.low,
                                    open: element.open,
                                });
                                this.tablePredictiveToSheet['fdx'].push(closed);
                                this.tablePredictiveToSheet['date'].push(
                                    formatDate(d, 'MMM-dd-yy', 'en-US')
                                );
                            }
                        })
                    );

                    //Creacion de la tabla
                    console.log(this.tablePredictiveToSheet);

                    this.service
                        .sendPredictiveInfoToSheet(this.tablePredictiveToSheet, this.spreedsheetId)
                        .subscribe((data: any) => {
                            if (data.result.status == 200) {
                                this.service
                                    .getTendencyPredictive(this.spreedsheetId)
                                    .subscribe(async (data: any) => {
                                        if (data.result.status == 200) {
                                            this.tableTendencyPredictive = data.result.data;
                                            // console.log(this.tableTendencyPredictive);

                                            try {
                                                const data = await this.service.getScreenerPredictive(this.spreedsheetId);
                                                console.log("data", data);
                                                clearInterval(timeCounter);
                                                function printLable(close: number, porcent: number): string {
                                                    let vuelta: string;
                                                    vuelta = close + '\n' + (porcent * 100).toFixed(2) + '%';
                                                    return vuelta;
                                                };
                                                this.predictiveChartConfig = {
                                                    textStyle: {
                                                        fontSize: 12
                                                    },
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        axisPointer: {
                                                            type: "shadow"
                                                        }
                                                    },
                                                    legend: {
                                                        top: '5%',
                                                        data: ['Closed', 'Predicción promedio', 'Probabilidad de retornos positivos', 'Probabilidad de retornos negativos'],
                                                    },
                                                    grid: {
                                                        left: '2%',
                                                        right: '9%',
                                                        bottom: '3%',
                                                        top: '15%',
                                                        containLabel: true,
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        boundaryGap: false,

                                                        data: data.headerValues.concat(['', '', '']),
                                                    },
                                                    yAxis: {
                                                        type: 'value',
                                                        // min: data.min - 1,
                                                        min: Math.min.apply(Math, data.closeValues.concat([data.min])),
                                                        // max: data.max
                                                        max: Math.max.apply(Math, data.closeValues.concat([data.max]))
                                                    },
                                                    series: [
                                                        //Colores
                                                        //Promedio: #228B22
                                                        //Min:      #B22222
                                                        //Max:      #1E90FF
                                                        {
                                                            name: 'Closed',
                                                            type: 'line',
                                                            stack: 'Closed',
                                                            data: data.closeValues,
                                                            color: 'white',
                                                            z: 3,
                                                            markLine: {
                                                                z: 0,
                                                                silent: true,
                                                                symbol: ['none', 'none'],
                                                                label: { show: false, },
                                                                lineStyle: {
                                                                    width: 2,
                                                                },
                                                                data: [
                                                                    {
                                                                        xAxis: 90,
                                                                        valueIndex: 1,
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            name: 'Probabilidad de retornos positivos',
                                                            type: 'line',
                                                            color: '#1E90FF',
                                                            z: 1,
                                                            emphasis: {
                                                                focus: 'none',
                                                            },
                                                            data: data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return value;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([parseFloat((data.pMax * 100).toFixed(2))]),
                                                            markLine: {
                                                                data: [
                                                                    [
                                                                        {
                                                                            symbol: 'none',
                                                                            x: '90%',
                                                                            yAxis: data.max
                                                                        }, {
                                                                            symbol: 'circle',
                                                                            label: {
                                                                                fontSize: 14,
                                                                                position: 'start',
                                                                                formatter: data.max + ' (' + (data.pMax * 100).toFixed(2) + '%)',
                                                                                //formatter: data.max + ' (' + (this.tableTendencyPredictive[0].cell_2 * 100).toFixed(2) + '%)',
                                                                            },
                                                                            value: data.max,
                                                                            xAxis: "max",
                                                                            yAxis: data.max
                                                                        }
                                                                    ]
                                                                ]
                                                            },
                                                        },
                                                        {
                                                            name: 'Predicción promedio',
                                                            type: 'line',
                                                            color: '#228B22',
                                                            z: 2,
                                                            emphasis: {
                                                                focus: 'none',
                                                            },

                                                            markLine: {
                                                                data: [
                                                                    [
                                                                        {
                                                                            symbol: 'none',
                                                                            x: '90%',
                                                                            yAxis: data.average
                                                                        }, {
                                                                            symbol: 'circle',
                                                                            label: {
                                                                                fontSize: 14,
                                                                                position: 'start',
                                                                                formatter: data.average + ' (' + ((1 - data.pMax - data.pMin) * 100).toFixed(2) + '%)',
                                                                            },
                                                                            value: data.average,
                                                                            xAxis: "max",
                                                                            yAxis: data.average
                                                                        }
                                                                    ]
                                                                ]
                                                            },

                                                            data: data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return value;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([parseFloat(((1 - data.pMax - data.pMin) * 100).toFixed(2))])
                                                        },
                                                        {
                                                            name: 'Probabilidad de retornos negativos',
                                                            type: 'line',
                                                            color: '#B22222',
                                                            z: 1,
                                                            emphasis: {
                                                                focus: 'none',
                                                            },
                                                            data: (data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return value;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([parseFloat((data.pMin * 100).toFixed(2))])
                                                            ),
                                                            markLine: {
                                                                data: [
                                                                    [
                                                                        {
                                                                            symbol: 'none',
                                                                            x: '90%',
                                                                            yAxis: data.min
                                                                        }, {
                                                                            symbol: 'circle',
                                                                            label: {
                                                                                fontSize: 14,
                                                                                position: 'start',
                                                                                formatter: data.min + ' (' + (data.pMin * 100).toFixed(2) + '%)',
                                                                                //formatter: data.min + ' (' + (this.tableTendencyPredictive[1].cell_2 * 100).toFixed(2) + '%)',
                                                                            },
                                                                            value: data.min,
                                                                            xAxis: "max",
                                                                            yAxis: data.min
                                                                        }
                                                                    ]
                                                                ]
                                                            },
                                                        },

                                                        //Areas
                                                        //Se hicieron las lineas transparentes, sin enfasis y sin lables
                                                        {
                                                            name: 'AreaMínima',
                                                            type: 'line',
                                                            color: 'transparent',
                                                            stack: 'predict',
                                                            z: 0,
                                                            tooltip: {
                                                                show: false
                                                            },
                                                            data: data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return value;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([data.min]),
                                                        },
                                                        {
                                                            name: 'AreaMedia',
                                                            type: 'line',
                                                            color: 'transparent',
                                                            stack: 'predict',
                                                            z: 0,
                                                            areaStyle: {
                                                                color: '#B22222',
                                                            },
                                                            tooltip: {
                                                                show: false
                                                            },
                                                            data: data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return 0;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([data.average - data.min]),
                                                        },
                                                        {
                                                            name: 'AreaMáxima',
                                                            type: 'line',
                                                            color: 'transparent',
                                                            stack: 'predict',
                                                            z: 0,
                                                            areaStyle: {
                                                                color: '#1E90FF',
                                                            },
                                                            tooltip: {
                                                                show: false
                                                            },
                                                            data: data.closeValues
                                                                .map((value, index) => {
                                                                    if (index == data.closeValues.length - 1) {
                                                                        return 0;
                                                                    }
                                                                    return null;
                                                                })
                                                                .concat([data.max - data.average]),
                                                        },
                                                    ],
                                                };
                                            } catch (error) { }
                                            this.loader = false;
                                            this.hasPredictiveResult = true;
                                            this.form.reset();
                                            clearInterval(timeCounter);
                                        } else {
                                            Swal.fire(
                                                'Lo sentimos',
                                                'Hubo un error en el proceso. Intente de nuevo.',
                                                'warning'
                                            );
                                            this.loader = false;
                                            this.hasPredictiveResult = false;
                                            this.form.reset();
                                            clearInterval(timeCounter);
                                        }
                                    });
                            } else {
                                Swal.fire(
                                    'Lo sentimos',
                                    'Hubo un error en el proceso. Intente de nuevo.',
                                    'warning'
                                );
                                this.loader = false;
                                this.hasPredictiveResult = false;
                                this.form.reset();
                                clearInterval(timeCounter);
                            }
                        });
                }
            },
            error: (error) => {
                this.errorService.sendErrorLog(error, 'getAllHistoricalDataPerDay');
                console.error('There was an error!', error);
                Swal.fire(
                    'Lo sentimos',
                    'Hubo un error en el proceso. Intente de nuevo.',
                    'warning'
                );
                this.loader = false;
                this.hasPredictiveResult = false;
                this.form.reset();
                clearInterval(timeCounter);
            },
        });
    }

    goToChart(type) {

        switch (type) {
            case 1:
                if (this.isMobile) {
                    this.viewScroller.scrollToPosition([0, 2750]);
                } else {
                    this.viewScroller.scrollToPosition([0, 2050]);
                }
                break;
            case 2:
                if (this.isMobile) {
                    this.viewScroller.scrollToPosition([0, 4200]);
                } else {
                    this.viewScroller.scrollToPosition([0, 3000]);
                }
                break;
            default:
                break;
        }
    }

    // scroll(el: HTMLElement) {
    //     console.log(el);

    //     el.scrollIntoView({block: "center", behavior: "smooth"});
    // }
}

function popNoPrice(prices: Array<Element>): Array<Element> {
    let substitutePrices: Array<Element> = [];
    prices.forEach(element => {
        if (element.type == undefined) {
            substitutePrices.push(element)
        }
    })
    return substitutePrices;
}

interface Element {
    adjclose: number;
    close: number;
    date: number;
    high: number;
    low: number;
    open: number;
    volume: number;
    type: string;
}
