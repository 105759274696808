import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  public slidesStore = [
    {
      id: "photo1",
      src: "../../../assets/images/banner-test.jpg",
      alt: "test",
      title: "test"
    },
    {
      id: "photo2",
      src: "../../../assets/images/banner-test.jpg",
      alt: "test2",
      title: "test2"
    },
    {
      id: "photo3",
      src: "../../../assets/images/banner-test.jpg",
      alt: "test3",
      title: "test3"
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  constructor() { }

  ngOnInit() {
  }

}
