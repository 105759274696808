import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Course } from 'src/app/models/course';
import { Order, OrderFirebase } from 'src/app/models/order';
import { environment } from 'src/environments/environment';

interface SimpleResponse {
  code: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  // EM
  private CREATE_PAYMENTINTENT_URL = `${environment.URL_API}/orders/generate-payment-intent`;
  private CONFIRM_PAYMENTINTENT_URL = `${environment.URL_API}/orders/confirm-payment-intent`;
  private CREATE_ORDER_URL: string = `${environment.URL_API}/orders/create-order`;

  // OLD
  private createOrderPaypal: string = `${environment.URL_API}/payment/paypal`;
  private createOrderCard: string = `${environment.URL_API}/payment/conekta/save`;
  private getOrderInformationURL: string = `${environment.URL_API}/order/information`;

  constructor(private http: HttpClient) { }

  public async createOrder(order_info: any) {
    try {
      var response = await this.http
        .post<{
          code: number;
          message: string;
          data: any;
        }>(this.CREATE_ORDER_URL, { order_info: order_info })
        .toPromise();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }


  async createPaymentIntent(order_info: { amount: number; customer_id: string }) {
    try {
      let response = await this.http.post<SimpleResponse>(this.CREATE_PAYMENTINTENT_URL, { order_info: order_info }).toPromise();
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async confirmPaymentIntent(
    payment_id: string,
    card_stripe_id: string
  ) {
    try {
      var response = await this.http
        .post<{ code: number; message: string; data: any }>(
          this.CONFIRM_PAYMENTINTENT_URL,
          { payment_id: payment_id, card_stripe_id: card_stripe_id }
        )
        .toPromise();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }



  async getOrderInformation(orderId: string) {
    try {
      const answer = await this.http
        .post<{
          code: number,
          message: string,
          data: Order
        }>(`${this.getOrderInformationURL}`, { orderId: orderId })
        .toPromise();
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
