<section>
  <div class="col-12 main">
    <div class="col-12 title">
      <h1>BLOG</h1>
      <h2>Últimas Publicaciones</h2>
    </div>
    <div class="overflowed-section">
      <div class="cards-section">
        <div class="row">
          <ng-container *ngFor="let item of posts | slice:0:4">
            <div class="col-3 card-container">
              <div class="col-12 no-padding">
                <div class="card" style="width: 100%;">
                  <div class="card-img-top" [ngStyle]="{'background-image': 'url('+item.image+')'}"></div>
                  <div class="card-body">
                    <h5 class="card-title">{{item.title}}</h5>
                    <div class="card-text" [innerHTML]="item.description"></div>
                    <a [routerLink]="['/blog-entrada/'+item.id]" class="card-link">Ver más</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</section>