<div *ngIf="pageLoader" class="loading-page">
  <div class="spinner"></div>
  <p>Cargando...</p>
</div>

<section class="analysis-main">
  <div class="banner-section">
    <div class="content">
            <h1>Análisis de empresas</h1>
    </div>
  </div>
  <div class="input-box">
    <span class="prefix"><mat-icon>search</mat-icon></span>
    <input
      class="search-input"
      matInput
      type="text"
      placeholder="Buscar una entrada..."
      name="filterPost"
      [(ngModel)]="filterPost"
    />
  </div>
</section>
<section class="posts" *ngIf="!pageLoader">
  <div class="card-container">
    <div class="row">
      <ng-container *ngFor="let item of posts | filterAnalytics:filterPost">
        <div class="col-4 col">
          <div class="card">
            <div
              class="card-img-top"
              [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
            >
            <div class="card-badge" [ngClass]="item.pay != true ? 'card-badge-free':'card-badge-premium'">
              <p>
                  {{item.pay == true ? 'Membresía':'Gratuito'}}
              </p>
          </div></div>
            <div class="card-body">
              <h2 class="card-title">{{ item.title }}</h2>
              <button
                class="card-btn btn"
                (click)="goToPost(item.id, item.views, item.pay)"
              >
                Ver más
              </button>
            </div>
            <div class="card-footer">
              <h1>Autor: {{item.author}}</h1>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
