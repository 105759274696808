<div *ngIf="loaderpage" class="loading-page">
    <div class="spinner"></div>
    <p>Generando orden. Espere por favor.</p>
    <p>No recargue o cierra esta pestaña</p>
</div>
<div *ngIf="loaderinit" class="loading-page">
    <div class="spinner"></div>
</div>
<div class="checkout-main">
    <div class="info-column">

        <mat-horizontal-stepper linear #stepper>
            <mat-step>
                <ng-template matStepLabel>Información de la compra</ng-template>
                <div class="user-info">
                    <h1>Tus datos</h1>
                    <div class="info-row two-col">
                        <div class="info-group">
                            <span class="data-label">Nombres:</span><br>
                            <span>{{userInfo?.name || '-'}}</span>
                        </div>
                        <div class="info-group">
                            <span class="data-label">Apellidos:</span><br>
                            <span>{{userInfo?.lastname || '-'}}</span>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-group">
                            <span class="data-label">Correo electrónico:</span><br>
                            <span>{{userInfo?.email}}</span>
                        </div>
                    </div>

                    <div class="info-row product-details" *ngIf="cartItems.length == 0">
                        <div class="product-info">
                            <h2>{{courseInfo?.name}}</h2>
                            <div class="row-item">
                                <span class="span-bold">Precio:</span>
                                <span>${{courseInfo?.price}}.00 MXN</span>
                            </div>
                        </div>
                        <div class="row-item mb-3">
                            <span class="span-bold">Total:</span>
                            <span>${{courseInfo?.price - couponDiscount}}.00 MXN</span>
                        </div>

                        <div class="coupon-wrapper" *ngIf="cartItems.length == 0">
                            <div class="row-item">
                                <span *ngIf="isCouponRedeemed" class="span-bold">Código aplicado:</span>
                                <span *ngIf="isCouponRedeemed" class="price">"{{couponRedeemed.name}}"</span>
                                <input *ngIf="!isCouponRedeemed" matInput [disabled]="isCouponRedeemed" matInput
                                    [(ngModel)]="coupon" (keydown.enter)="validateCoupon()" type="text"
                                    placeholder="Código de descuento">
                            </div>
                            <button *ngIf="!isCouponRedeemed" mat-button (click)="validateCoupon(courseInfo.price)"
                                [disabled]="isCouponRedeemed">Redimir</button>
                        </div>
                        <mat-error *ngIf="couponError" class="coupon-error">{{couponErrorMessage}}</mat-error>

                        <div *ngIf="couponDiscount > 0" class="row-item mb-3">
                            <span class="span-bold">Descuento por código:</span>
                            <span>${{couponDiscount}}.00 MXN</span>
                        </div>

                        <div class="buy-wrapper" *ngIf="cartItems.length == 0">
                            <button mat-button matStepperNext (click)="validatePaymentType()">Continuar al pago</button>
                            <button *ngIf="isCouponRedeemed" mat-button (click)="removeCode()">Remover código</button>
                        </div>
                    </div>

                    <div class="info-row product-details" *ngIf="cartItems.length > 0">
                        <div *ngFor="let item of cartItems" class="product-info">
                            <h2>{{item.name}}</h2>
                            <div class="row-item">
                                <span class="span-bold">Precio:</span>
                                <span class="price">${{item.price}}.00 MXN</span>
                            </div>
                        </div>
                        <div class="row-item mb-3" *ngIf="cart.realPrice != undefined && cart.realPrice > 0">
                            <span class="span-bold">Subtotal:</span>
                            <span class="subtotal">${{cart.realPrice}}.00 MXN</span>
                        </div>
                        <div class="row-item mb-3">
                            <span class="span-bold">Total:</span>
                            <span class="span-bold" *ngIf="cart.realPrice != undefined && cart.realPrice > 0">15%
                                Desc.</span>
                            <span class="total">${{cart.total - couponDiscount}}.00 MXN</span>
                        </div>
                        <div class="coupon-wrapper">
                            <div class="row-item">
                                <span *ngIf="isCouponRedeemed" class="span-bold">Código aplicado:</span>
                                <span *ngIf="isCouponRedeemed" class="price">"{{couponRedeemed.name}}"</span>
                                <input *ngIf="!isCouponRedeemed" matInput [disabled]="isCouponRedeemed" matInput
                                    [(ngModel)]="coupon" (keydown.enter)="validateCoupon()" type="text"
                                    placeholder="Código de descuento">
                            </div>
                            <button *ngIf="!isCouponRedeemed" mat-button (click)="validateCoupon()"
                                [disabled]="isCouponRedeemed">Redimir</button>
                        </div>
                        <mat-error *ngIf="couponError" class="coupon-error">{{couponErrorMessage}}</mat-error>
                        <div class="buy-wrapper">
                            <button mat-button matStepperNext (click)="validatePaymentType()">Continuar al pago</button>
                            <button *ngIf="isCouponRedeemed" mat-button (click)="removeCode()">Remover código</button>
                        </div>
                    </div>


                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Método de pago</ng-template>
                <div class="payment-method">
                    <h1>Ingresa los datos para el pago</h1>
                    <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
                        <ngx-stripe-payment [clientSecret]="clientSecret">
                        </ngx-stripe-payment>
                    </ng-container>
                    <button mat-button (click)="pay()">Confirmar el pago</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>

    <div class="checkout-column" *ngIf="courseID">
        <div class="product-info">
            <h2>{{courseInfo?.name}}</h2>
            <hr>
            <img [src]="courseInfo?.coverImage" alt="">
            <div class="row-item">
                <span class="span-bold">Precio:</span>
                <span>${{courseInfo?.price}}.00 MXN</span>
            </div>
        </div>
        <div class="row-item mb-3">
            <span class="span-bold">Total:</span>
            <span>${{courseInfo?.price - couponDiscount}}.00 MXN</span>
        </div>

        <mat-error *ngIf="couponError" class="coupon-error">{{couponErrorMessage}}</mat-error>

        <div *ngIf="couponDiscount > 0" class="row-item mb-3">
            <span class="span-bold">Descuento por código:</span>
            <span>${{couponDiscount}}.00 MXN</span>
        </div>
    </div>

    <div *ngIf="cartItems.length > 0" class="checkout-column">
        <div *ngFor="let item of cartItems" class="product-info">
            <h2>{{item.name}}</h2>

            <div class="cover-wrapper">
                <img [src]="item.coverImage" alt="">
            </div>

            <div class="row-item">
                <span class="span-bold">Precio:</span>
                <span class="price">${{item.price}}.00 MXN</span>
            </div>
        </div>
        <div class="row-item mb-3" *ngIf="cart.realPrice != undefined && cart.realPrice > 0">
            <span class="span-bold">Subtotal:</span>
            <span class="subtotal">${{cart.realPrice}}.00 MXN</span>
        </div>
        <div class="row-item mb-3">
            <span class="span-bold">Total:</span>
            <span class="span-bold" *ngIf="cart.realPrice != undefined && cart.realPrice > 0">15% Desc.</span>
            <span class="total">${{cart.total - couponDiscount}}.00 MXN</span>
        </div>
        <div class="coupon-wrapper">
            <div class="row-item">
                <span *ngIf="isCouponRedeemed" class="span-bold">Código aplicado:</span>
                <span *ngIf="isCouponRedeemed" class="price">"{{couponRedeemed.name}}"</span>
            </div>
        </div>
    </div>
</div>