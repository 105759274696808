<div class="banner-section">
    <div class="content">
        <h1>¿Qué herramienta quieres ver?</h1>
        <h2>El objetivo de las herramientas es triple:</h2>
        <ul>
            <li>Mejorar nuestra toma de decisiones a la hora de invertir.</li>
            <li>Ayudarnos a encontrar Oportunidades de Inversión o Especulación.</li>
            <li>Ahorra tiempo y dinero ya que toda esta información por separado con diferentes suscripciones externas
                costaría 5 o 10 veces más. Y todo lo anterior usando datos y evidencia probada y robusta.</li>
        </ul>
    </div>
</div>
<div class="preselect-main">
    <div class="preselect-data">
        <div class="select-container">
            <div class="select-cards">
                <div class="head"></div>
                <div class="img-container"><img src="assets/images/indicators.png" alt=""></div>
                <h1 class="title">Indicadores</h1>
                <p class="desc">Herramientas de análisis técnico que al complementarlas de esta forma específica nos
                    ayudan a encontrar un mejor punto de entrada con altas probabilidades de éxito y a su vez eliminar
                    falsos positivos.</p>
                <button class="btn card-btn" (click)="validateLogin('indicadores')">Ver</button>
            </div>
            <div class="select-cards">
                <div class="head"></div>
                <div class="img-container"><img src="assets/images/screeners.png" alt=""></div>
                <h1 class="title">Screeners</h1>
                <p class="desc">Filtros cuantitativos para escoger un conjunto de empresas que han sido probados con
                    simulaciones para que ese portafolio tenga altas probabilidades de dar rendimientos por arriba del
                    índice.
                    También nos sirve para obtener ideas de inversión e investigarlas y analizarlas.</p>
                <button class="btn card-btn" (click)="validateLogin('screeners')">
                    Ver
                </button>
            </div>
            <div class="select-cards">
                <div class="head"></div>
                <div class="img-container"><img src="assets/images/img-grafica.webp" alt=""></div>
                <h1 class="title">Portafolio porcentaje de interés</h1>
                <p class="desc">Herramienta que cálcula una aproximación en los resultados de tu inversión basados en un escenario promedio de
                    rendimiento, con gráficas de proyección de como tu inversión puede crecer a lo largo del tiempo.</p>
                <button class="btn card-btn" (click)="validateLogin('portafolio')">
                    Ver
                </button>
            </div>
            <!-- <button mat-raised-button (click)="validateSub('indicadores')">Indicadores</button>
            <button mat-raised-button (click)="validateSub('screeners')">Screeners</button> -->
        </div>
    </div>
</div>