import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';
import { OrdersService } from '../../services/orders/orders.service';
import { Course } from '../../models/course';
import { CartService } from 'src/app/services/cart/cart.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    // Carrito LocalStorage
     data$: Observable<any>;

    public cart: any;

    showLogoBlack = false
    public showMobileMenu: boolean = false;
    public showCartMenu: boolean = false;
    public scrollFlag: string = '';
    private scrollListener: boolean = true;
    public actualRoute: string = '/';

    public showMenu: boolean = false;
    public session: boolean = false;
    private subscription: Subscription;
    public user_id: any;
    public loader = false;


    constructor(
        private router: Router,
        private afAuth: AngularFireAuth,
        private service: UserService,
        private authService: AuthService,
        private orderService: OrdersService,
        private cartService: CartService
    ) {
        this.data$ = cartService.Cart;
        this.subscription = this.afAuth.authState.subscribe(session => {
            if (session) {
                this.session = true;
            } else {
                this.session = false;
            }
        });
    }

    async ngOnInit() {
        this.loader = true;
        this.loader = false;
    }

    delete(name: string) {
        let currentCart = JSON.parse(localStorage.getItem('Carrito'));

        currentCart.items = currentCart.items.filter(course => {
            return course.name !== name
        });

        currentCart.total = currentCart.items.reduce((total, course) => total + course.price, 0);

        if (currentCart.items.length < 3) {
            currentCart.realPrice = 0;
        }

        this.cartService.CartData = currentCart;
    }

    closeMobileMenu() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    closeMenuCart() {
        this.showCartMenu = !this.showCartMenu;
    }

    async submit() {
        try {
            Swal.fire('Cargando...', 'Cerrando sesión');
            Swal.showLoading();
            await this.authService.logoutUser();
            Swal.close();
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
            });

            Toast.fire({
                icon: 'success',
                title: 'Sesion cerrada'
            })
            this.router.navigate(['/home']);
        } catch (error) {
            // Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
            console.log(error);

            Swal.close();

        }
    }

    async showPortal() {
        try {
            this.user_id = await this.authService.getUser();
            const user_info: User = await this.service.getUserData(this.user_id);
            var portal = await this.service.getUserPortal(user_info.stripeclient_id);
            window.open(portal.url, '_blank').focus();
        } catch (error) {
            console.log(error);

        }
    }

    async ngOnDestroy() {
        await this.authService.logoutUser();
    }


}
