import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItem } from 'src/app/models/cart';
import { Course } from 'src/app/models/course';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private localStorageCart: any = JSON.parse(localStorage.getItem('Carrito')!)
  private cart: BehaviorSubject<any> = new BehaviorSubject<any>(this.localStorageCart);

  constructor() { }

  get Cart() {
    return this.cart.asObservable();
  }

  set CartData(data: any) {
    localStorage.setItem('Carrito', JSON.stringify(data));
    this.cart.next(data);
    location.reload()
  }


  EmptyCart() {
    localStorage.removeItem('Carrito')
    let emptyCart = this.localStorageCart.items = [];
    let emptyTotal = this.localStorageCart.total = 0;
    let realPrice = this.localStorageCart.realPrice = 0;
  }


  async addCartItem(planInfo: CartItem, userInfo: any) {

    console.log('información del item del carrito',planInfo);
    

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    let course: Course = planInfo;

    let user = userInfo;
    if (user == undefined) user = {};

    let localCart = JSON.parse(localStorage.getItem('Carrito')!);

    if (localCart == null || localCart.length == 0 || localCart == undefined) {
      localCart = {
        modifiedtime: new Date(),
        total: course.price,
        userInfo: user,
        items: [course]
      }
      Toast.fire({
        icon: 'success',
        title: 'Curso agregado correctamente!'
      })
    } else {
      let foundCourse = localCart.items.find(courseItem => courseItem.name === course.name)
      if (foundCourse == undefined) {
        localCart.items.push(course)
        let newTotal = localCart.total + course.price
        localCart.total = newTotal;
        localCart.modifiedtime = new Date();
        // user retro
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'Curso agregado correctamente!'
        })
      } else {
        Swal.fire({
          title: 'Ya has agregado este curso al carrito',
          text: 'Intenta agregar uno diferente',
          icon: 'warning',
          confirmButtonText: "Aceptar",
        })
        return;
      }
    }

    if (localCart.items.length > 2) {
      let discount = localCart.total - (localCart.total * .15)
      localCart.realPrice = localCart.total
      localCart.total = discount
    }

    this.CartData = localCart;
  }
}
