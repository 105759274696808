<div *ngIf="loaderpage" class="loading-page">
    <div class="spinner"></div>
</div>
<div class="checkout-main">
        <div class="info-column">
            <div class="opps" id="print-section">
                <div class="opps-header">
                    <div class="opps-reminder">Confirmación de compra</div>
                    <div class="opps-info">
                        <div class="opps-description">
                            
                        </div>
                        <div class="opps-ammount">
                            <h3>Total de tu compra</h3>
                            <h2>$ {{orderInformation?.total}} <sup>MXN</sup></h2>
                        </div>
                    </div>
                </div>
                <div class="opps-body">
                    <div class="user-info">
                        <p>Nombre: <span>{{orderInformation?.client.name}} {{orderInformation?.client.lastname}}</span></p>
                        <p>Correo electrónico: <span>{{orderInformation?.client.email}}</span></p>
                    </div>
                    <div class="order-info">
                        <ng-container *ngFor="let course of orderInformation?.course">
                            <p>Precio: <span>${{course.price}}.00</span></p>
                            <h1>{{course.name}}</h1>
                            <img [src]="course.coverImage" alt="">
                            <hr>
                        </ng-container>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="checkout-column">
            <div class="logo-wrapper">
                <img src="assets/images/logo-footer.png" alt="">
            </div>
            <h1>¡Muchas gracias por tu compra!</h1>
            <div class="info">
                <h2>Tu compra se completó correctamente, recibirás un correo electrónico con la confirmación de tu compra.
                </h2>
            </div>
            <button mat-flat-button printSectionId="print-section" [routerLink]="['/cursos']">Regresar a
                los cursos</button>
        </div>
</div>