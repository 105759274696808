import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-plan-type-dialog',
  templateUrl: './select-plan-type-dialog.component.html',
  styleUrls: ['./select-plan-type-dialog.component.scss']
})
export class SelectPlanTypeDialogComponent implements OnInit {

  public planTypeForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SelectPlanTypeDialogComponent>
  ) { 
    this.planTypeForm = this.formBuilder.group({
      type: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
  }

  onConfirmType() {
    if(this.planTypeForm.invalid) {
      this.planTypeForm.markAllAsTouched();
      return
    }
    const type = this.planTypeForm.value
    this.dialogRef.close(type);
  }

}
