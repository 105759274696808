<h2 mat-dialog-title>Cambia tu contraseña</h2>

<mat-dialog-content class="mat-typography">
  <h3>Ingrese su nueva contraseña con al menos 6 caracteres</h3>

  <form action="" [formGroup]="passwordForm">
    <br />
      <mat-label>Nueva contraseña</mat-label>
      <input matInput formControlName="password" type='text'/>
      <mat-error *ngIf="passwordForm.controls['password'].invalid && passwordForm.controls['password'].touched">
        La contraseña debe tener un mínimo de 6 caracteres
      </mat-error>

  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close *ngIf="!loader">Cancelar</button>

  <button
    *ngIf="!loader"
    mat-button
    (click)="updatePassword()"
  >
    Aceptar
  </button>

  <mat-spinner [diameter]="32" *ngIf="loader" style="margin: 0 auto;"></mat-spinner>
</mat-dialog-actions>