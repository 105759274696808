import { Course } from "./course";
import { User } from "./user";

export interface Cart {
    modifiedtime: Date;
    total: number;
    userInfo: User;
    items: Array<CartItem>;
}

export interface CartItem {
    coverImage: string;
    description: string;
    name: string;
    price: number;
    priceId?: string;
    type: ItemType
}

export enum ItemType {
    course,
    plan
}