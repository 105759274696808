import { Location } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BlogService } from 'src/app/services/blog/blog.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-normal-blog-post',
  templateUrl: './normal-blog-post.component.html',
  styleUrls: ['./normal-blog-post.component.scss']
})
export class NormalBlogPostComponent implements OnInit {
  public post!: any;
  loader: boolean = false;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private auth: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.paramMap.get('id') || '';
    await this.getPostById(id);
  }


  private async getPostById(id: string) {
    try {
      this.loader = true
      let res = await this.blogService.getById(id);

      if (res.data.post.isPaid) {
        let hasAccess = await this.validateSub();
        if (!hasAccess) {
          Swal.fire({
            title: 'Esta entrada de blog es solo para miembros',
            text: '',
            icon: 'error',
            confirmButtonColor: '#d82d2a',
          });
          this.router.navigate(['/blog'])
        }

        this.post = res.data.post;
        this.loader = false
        return;
      }

      this.post = res.data.post;
      this.loader = false
    
    } catch (err) {
      console.error(err.error.code)
      this.router.navigateByUrl('**');
    }
  }

  async validateSub() {
    const user_id = await this.auth.getUser();
    const user_info: User = await this.userService.getUserData(user_id);

    if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
      Swal.close();
      return false
    }

    const subs = await this.userService.getUserSubscriptions(user_info.stripeclient_id);
    let isActiveSub: boolean = false;

    let someActive = subs.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');
    let currentDate = new Date().setHours(0, 0, 0, 0);
    let someCurrentPeriodActive = subs.data.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);
    if(someActive || someCurrentPeriodActive) {
      isActiveSub = true
    } else {
      isActiveSub = false
    }
    return isActiveSub
  }

  goBack() {
    this.location.back();
  }

}
