<section class="hero">
    <div class="container">
      <div class="hero__wrapper">
        <div class="hero__left">
          <div class="hero__left-wrapper">
            <h1 class="hero__heading">¡ Registro exitoso !</h1>
            <p class="hero__info">
                Gracias por registrarse, por favor verifica el mensaje que se envió a tu correo para poder activar su cuenta.
            </p>
            <small>*En caso de no recibir el mensaje, verifique la bandeja de spam.</small>
          </div>
        </div>
        <div class="hero__right">
          <div class="hero__imgWrapper">
            <img src="assets/images/startup.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>