import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuestionnaireComponent } from 'src/app/components/questionnaire/questionnaire.component';
import { Course } from 'src/app/models/course';
import { CoursesService } from 'src/app/services/courses/courses.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/user';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { BuyNoAccountDialogComponent } from 'src/app/components/buy-no-account-dialog/buy-no-account-dialog.component';
import { Pack, packs } from 'src/app/services/packs/packs';
import { SelectPlanTypeDialogComponent } from 'src/app/components/select-plan-type-dialog/select-plan-type-dialog.component';
import { CartService } from 'src/app/services/cart/cart.service';

import Swal from 'sweetalert2';
import { CartItem, ItemType } from 'src/app/models/cart';
declare var Stripe: any;

// Live
const STRIPE = Stripe('pk_live_51JNLEKC7qnCJJDECPzOxWmNEWgFZdWCs4uCeh3mPpqyZAe8J3huax0nYSLSbHSkun1AXMJucVMD99bmK7zhThqSg005daaOLmp');
// TEST
// const STRIPE = Stripe('pk_test_51JNLEKC7qnCJJDECZn4Y2XicyCvI737yfcWLUEBQKFRF3bLZrJqJQ9otEvbC3fzZctJLmZwZRuGe4jfQWcAqrfy3007qERNpoc');

@Component({
    selector: 'app-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
    public selectedPass = '';
    public cardElement: any;
    public loaderSub: boolean = false;
    public nameHolder: string;
    public subscription_info: any;
    public currentInsertedDocId: string;
    public selectedPassTime = '';
    public packs = packs
    public userInfo: any;
    public userUid: string = '';

    courses: Array<Course> = [
        {
            abbreviation: "",
            category: 1,
            coverImage: "https://firebasestorage.googleapis.com/v0/b/proceso-resultado.appspot.com/o/courses%2Fcover_images%2Fxk5knp49j5f?alt=media&token=42f8a4c8-0b4b-4ffd-9318-6cdd65cd61b3",
            date: "2021-07-20T05:00:00.000Z",
            description: "<p>VER</p> ",
            name: "Curso GRATIS",
            price: 11,
            zoomURL:
                "zoom.com",
            time: { hour: 14, minutes: 0, hourString: "14", minString: "00" },
            syllabus: ''
        }];

    coursesDisplay = false;
    packsDisplay = true;
    showCalendar = true;
    showCalendar1 = false;
    showCalendar2 = false;
    showCalendar3 = false;
    showCalendar4 = false;

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: false,
        dots: true,
        navSpeed: 500,
        navText: ['<', '>'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 2
            }
        },
        nav: true,
        autoplay: true,
    }

    public slidesStore = [
        {
            id: "1",
            img: "../../../assets/images/course/card-1.png",
            text: 'Parte importante de los valores de esta asociación civil es crear consciencia e impulsar a que la educación financiera no sea un privilegio de unos cuantos, sino que pueda estar al alcance de la mano de toda la población a través de nuestro esquema de cursos enfocados a ellos.',
            title: 'Educación financiera',
        },
        {
            id: "2",
            img: "../../../assets/images/course/card-2.png",
            text: 'Los cursos van dirigidos a todas las personas desde las que inician con muy poco o nulo conocimiento hasta las que tienen grandes conocimientos al respecto, todos ellos son accesibles y flexibles, es decir procuramos que tanto los precios como los horarios de los mismos sean cómodos para las personas que lo toman.',
            title: 'Cursos para todos',
        },
        {
            id: "3",
            img: "../../../assets/images/course/card-3.png",
            text: 'Es por ello que todos nuestros cursos son impartidos en un horario cómodo para aquellos que deseen visualizarlos de forma en línea y en vivo; de igual manera todos son grabados y posteriormente compartidos a través de sus correos electrónicos a todos nuestros alumnos para aquellos que no puedan tomarlo en vivo o en su caso los que deseen ir a su propio ritmo.',
            title: 'Horarios flexibles',
        },
        {
            id: "4",
            img: "../../../assets/images/course/card-4.png",
            text: 'Todos nuestros cursos cuentan con una clase de dudas con la finalidad de finalizar de manera correcta y con los temas lo más claro posible.',
            title: 'Clase para dudas',
        },
        {
            id: "5",
            img: "../../../assets/images/course/card-5.png",
            text: 'Contamos de igual manera con Asesorías Personalizadas que permiten a las personas crear, revisar, corregir un portafolio más a fin a la persona en particular enfocada a sus necesidades y su visión propia.',
            title: 'Asesorías personalizadas',
        },
    ];

    constructor(
        private serviceCourse: CoursesService,
        private dialog: MatDialog,
        private router: Router,
        private auth: AuthService,
        private userService: UserService,
        private orderService: OrdersService,
        private cartService: CartService,
    ) { }

    async ngOnInit() {
        this.getCourses();
        this.getPasses();
        this.getUserInfo();
    }

    async getUserInfo() {
        try {
            this.auth.afAuth.onAuthStateChanged(async user => {
                if (user != null) {
                    this.userUid = user.uid;
                    const answer = await this.userService.getUserData(this.userUid);
                    this.userInfo = answer;
                }
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Ups...',
                text: 'Tuvimos un error al cargar los datos del usuario, serás redireccionado.',
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            }).then(() => {
                this.router.navigate(['/cursos']);
            })
        }
    }

    async getPasses() {
        try {
            let response: any = await this.userService.getPassList();

            this.packs.forEach((pack: Pack | any) => {
                let find = response.data.map((element) => {
                    if (element.id == pack.monthId) {
                        pack.default_price_monthly = element.default_price
                    }
                    if (element.id == pack.yearId) {
                        pack.default_price_yearly = element.default_price
                    }
                    if (element.id == pack.biannualId) {
                        pack.default_price_biannual = element.default_price;
                    }
                })
            });
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    showQuestionnaire() {
        const dialogRef = this.dialog.open(QuestionnaireComponent, {
            width: '550px',
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result != '') {
                // Mayoría de R1 se les recomienda la primera pestaña de cursos.
                // Mayoría de R2 se les recomienda pestaña 2
                // Mayoría de R3: se le recomienda entre pestaña 3
                let r1 = 0;
                let r2 = 0;
                let r3 = 0;
                // contar respuestas
                await Promise.all(result.map(element => {
                    if (element == '1') {
                        r1++;
                    }
                    if (element == '2') {
                        r2++;
                    }
                    if (element == '3') {
                        r3++;
                    }
                }))
                // sacar la respuesta con mayor cantidad 
                let numbers: number[] = [r1, r2, r3];
                var maxNum = numbers[0];
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i] > maxNum) {
                        maxNum = numbers[i];
                    }
                }
                let category = numbers.indexOf(maxNum) + 1;
                // mando a categoria recomendada
                this.router.navigate([`cursos-recomendados/${category}`])
            }

        });
    }

    async getCourses() {
        try {
            var res = await this.serviceCourse.getCourses();
            res.forEach(element => {
                this.courses.push(element)
            });
        } catch (error: any) {
            console.log(error.message);
        }
    }

    switchTabs(option) {

        if (option == 1) {
            this.coursesDisplay = false;
            this.packsDisplay = true;
        } else {
            this.coursesDisplay = true;
            this.packsDisplay = false;
        }

    }

    onTabChangedCourse($event) {
        let clickedIndex = $event.index;
        this.showCalendar1 = false;
        this.showCalendar2 = false;

        switch (clickedIndex) {
            case 1:
                this.showCalendar1 = true;
                break;
            case 2:
                this.showCalendar2 = true;
                break;
            default:
                this.showCalendar1 = false;
                this.showCalendar2 = false;
                break;
        }
    }

    onTabChangedPacks($event) {
        let clickedIndex = $event.index;
        this.showCalendar3 = false;
        this.showCalendar4 = false;

        switch (clickedIndex) {
            case 1:
                this.showCalendar3 = true;
                break;
            case 2:
                this.showCalendar4 = true;
                break;
            default:
                this.showCalendar3 = false;
                this.showCalendar4 = false;
                break;
        }
    }

    showMore(index: number) {
        this.packs[index].showMore = !this.packs[index].showMore;
    }

    async verifyAuth(dialog: TemplateRef<any>, passType: Pack) {
        let isAuth = await this.auth.isLoggedIn();

        if (isAuth == null) {
            let noAccountResponse = this.dialog.open(BuyNoAccountDialogComponent, {
                width: '600px', height: 'auto', panelClass: 'dialog-class',
            });

            noAccountResponse.afterClosed().subscribe(async result => {
                if (result) {
                    this.verifySubscription(dialog, passType);
                }
            })
            return;
        } else {
            this.verifySubscription(dialog, passType);
        }
    }

    async verifySubscription(dialog: TemplateRef<any>, passType: Pack) {
        Swal.showLoading();
        try {
            let activeSubFlag: boolean = false;
            const isLoggedIn = await this.auth.isLoggedIn();
            const userId = isLoggedIn.uid
            const user_info: User = await this.userService.getUserData(userId);

            const subs = await this.userService.getUserSubscriptions(user_info.stripeclient_id);

            console.log('subs', subs);


            activeSubFlag = subs.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');

            if (activeSubFlag) {
                Swal.fire('Ya cuentas con una suscripción activa')
                return;
            } else {
                let currentDate = new Date().setHours(0, 0, 0, 0);
                let someCurrentPeriodActive = subs.data.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);

                    Swal.close();
                    this.onSelectPlanTypeDialog(passType);
                // if(someCurrentPeriodActive) {
                //     Swal.fire('El periodo de tu suscripción aún no ha terminado')
                // } else {
                //     Swal.close();
                // }
            }
        } catch (error) {
            return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
        }
    }



    async openPayment(dialog: TemplateRef<any>, passType: any) {
        Swal.close();
        this.dialog.open(dialog, {
            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
        });

        this.selectedPass = passType

        setTimeout(() => {
            const elements = STRIPE.elements();
            this.cardElement = elements.create('card');
            this.cardElement.mount('#card-element');
            this.loaderSub = false;
        }, 2000);
    }

    async validateSub(courseType: string) {
        Swal.fire('Cargando...',);
        Swal.showLoading();

        let isLoggedIn = await this.auth.isLoggedIn();
        let userId: string = isLoggedIn.uid
        const user_info: User = await this.userService.getUserData(userId);

        if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
            Swal.close();
            return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
        }
    }

    async paySubscribe() {
        if (this.selectedPassTime == '') return Swal.fire('Error', 'No olvides seleccionar el tipo de suscripción', 'info');
        if (this.nameHolder == undefined || this.nameHolder == '') return Swal.fire('Error', 'Favor de llenar todos los campos', 'error');
        Swal.fire('Generando orden...', '');
        Swal.showLoading();
        try {
            await this.suscribeToPlan(this.selectedPass);
            await this.payment();
        } catch (error) {
            console.log(error);
            return Swal.fire('Lo sentimos', 'Hubo un problema generar la suscripción', 'info');
        }
    }

    async suscribeToPlan(pass) {
        this.loaderSub = true;
        const user_id = await this.auth.getUser();
        const user_info: User = await this.userService.getUserData(user_id);
        let default_price
        let priceOrder

        switch (this.selectedPassTime) {
            case 'yearly':
                default_price = pass.default_price_yearly
                priceOrder = pass.price_year
                break;
            // case 'monthly':
            //     default_price = pass.default_price_monthly
            //     priceOrder = pass.price
            //     console.log('entro en mensual', pass.price);
            //     break;
            case 'biannual':
                default_price = pass.default_price_biannual
                priceOrder = pass.price_year
                break;

            default:
                break;
        }

        if (user_info.stripeclient_id == undefined || user_info.stripeclient_id == '') {
            Swal.close();
            return Swal.fire('Lo sentimos', 'Hubo un problema al obtener datos, intente de nuevo más tade', 'info');
        }

        try {
            var response = await this.userService.createSuscription(user_info.stripeclient_id, default_price);

            this.subscription_info = response;

            this.loaderSub = false;

            let order_info: any = {
                course: pass,
                total: priceOrder,
                user_info: user_info,
                user_uid: user_id,
                status: 'pending',
                payment_intent: response.subscription.latest_invoice.payment_intent.id
            }
            let orderResponse = await this.orderService.createOrder(order_info);

        } catch (error) {
            console.log(error);
            this.loaderSub = false;
            throw error;
        }
    }

    async payment() {
        STRIPE.confirmCardPayment(this.subscription_info.clientSecret, {
            payment_method: {
                card: this.cardElement,
                billing_details: {
                    name: this.nameHolder,
                },
            }
        }).then(async (result) => {
            if (result.error) {
                Swal.close();
                var response = await this.userService.deleteSuscription(this.subscription_info.subscriptionId); // En caso de haber un error en el pago se cancela la suscripción
                console.log(`Payment failed: ${result.error.message}`);
                Swal.fire('Lo sentimos', `Hubo un problema: ${result.error.message}`, 'info');
                return

            } else {
                console.log(result);
                console.log(`Success! Redirecting to your account. ${result}`);
                // var portal = await this.user_service.getUserPortal(this.user_information.data.stripeclient_id);
                // console.log(portal);
                Swal.close();
                Swal.fire('¡Listo!', 'Gracias por suscribirte, ahora tendrás acceso a todo el contenido disponible para ti', 'success').then(() => {
                    this.dialog.closeAll();
                    this.router.navigate(['']);
                })

            }
        });
    }

    onSelectPlanTypeDialog(selectedPack: Pack) {
        console.log('selectedPack', selectedPack);

        const typePlan = this.dialog.open(SelectPlanTypeDialogComponent, {
            width: '600px', height: 'auto', autoFocus: false, disableClose: true, hasBackdrop: false,
        })


        typePlan.afterClosed().subscribe(async type => {

            if (!type) return
            type = type.type;
            const priceId = type == 'biannual' ? selectedPack.default_price_biannual : selectedPack.default_price_yearly;

            console.log('biannual', selectedPack.default_price_biannual);
            console.log('anual', selectedPack.default_price_yearly);


            let queryParams = {
                type: ItemType.plan,
                pack: selectedPack,
                priceId: type == 'biannual' ? selectedPack.default_price_biannual : selectedPack.default_price_yearly,
                name: type == 'biannual' ? selectedPack.titleBiannual : selectedPack.titleYear,
                coverImage: selectedPack.image,
                price: type == 'biannual' ? selectedPack.price : selectedPack.price_year,
                description: selectedPack.description,
            }

            this.router.navigate(['verificar-compra/' + priceId
            ], { queryParams })
        })
    }


    async addCartItem(planInfo: CartItem) {
        this.cartService.addCartItem(planInfo, this.userInfo);
    }
}
