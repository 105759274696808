<section style="border-top: 1.5px solid #b5b5b5;">
  <div class="col-12 main" id="desktop">
    <div class="row">
      <div class="col-3 footer-image">
        <img src="../../../assets/images/logo-footer.png" alt="">
      </div>
      <div class="col-3 middle-list">
        <ul>
          <li><a [routerLink]="['/nosotros']">¿Quiénes Somos?</a></li>
          <!-- <li><a href="">Recursos Gratis</a></li> -->
          <li><a [routerLink]="['/blog']">Blog</a></li>
          <li><a [routerLink]="['/preguntas-frecuentes']">FAQ</a></li>
        </ul>
      </div>
      <div class="col right-list">
        <ul>
          <li><a [routerLink]="['/cursos']">Cursos</a></li>
          <li><a [routerLink]="['/selecccionar-herramienta']">Suscripciones y Herramientas</a></li>
          <li><a [routerLink]="['/analisis']">Análisis de empresas</a></li>
        </ul>
      </div>
      <!-- politicas y privacidad -->
      <div class="col right-list">
        <ul>
          <li><a [routerLink]="['/politica-privacidad']">Politicas de Privacidad</a></li>
          <li><a [routerLink]="['/politica-cookies']">Politicas de Cookies</a></li>
        </ul>
      </div>
    </div>


    <div class="col-12 dapper-tech">
      <span>@Developed by Dapper Technologies {{footerDate | date:"yyyy"}}</span>
    </div>
  </div>

  <div class="col-12 main" id="mobile">
    <div class="row">
      <div class="col-12 mobile-list">
        <ul>
          <li><a [routerLink]="['/nosotros']">¿Quiénes Somos?</a></li>
          <li><a [routerLink]="['/cursos']">Cursos</a></li>
          <!-- <li><a href="">Recursos Gratis</a></li> -->
          <li><a [routerLink]="['/preguntas-frecuentes']">FAQ</a></li>
          <li><a [routerLink]="['/blog']">Blog</a></li>
          <li><a [routerLink]="['/selecccionar-analisis']">Análisis y Empresas</a></li>
          <li><a [routerLink]="['/selecccionar-herramienta']">Suscripciones y Herramientas</a></li>
          <li><a [routerLink]="['/politica-privacidad']">Politicas de Privacidad</a></li>
          <li><a [routerLink]="['/politica-cookies']">Politicas de Cookies</a></li>
          
        </ul>
      </div>
      <div class="col-12 footer-image">
        <img src="../../../assets/images/logo-proceso.png" alt="">
      </div>
    </div>
    <div class="col-12 dapper-tech">
      <span>@Developed by Dapper Technologies {{footerDate | date:"yyyy"}}</span>
    </div>
  </div>
</section>