import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private CREATE_ORDER_URL: string = `${environment.URL_API}/orders/create-order`;
  private UPDATE_ORDER_URL: string = `${environment.URL_API}/orders/update-order`;
  private CREATE_PREORDER_URL: string = `${environment.URL_API}/orders/create-pre-order`;
  private updatePaymentIntentURI: string = `${environment.URL_API}/orders/update-payment-intent`;
  private updateSubscriptionPaymentIntentURI: string = `${environment.URL_API}/orders/update-subscription-payment-intent`;
  private orderByPaymentIntentURI: string = `${environment.URL_API}/orders/paymentIntent/`;

  constructor(
    private http: HttpClient
  ) { }

  public async createOrder(order_info: any) {
    try {
      var response = await this.http
        .post<{
          code: number;
          message: string;
          data: any;
        }>(this.CREATE_ORDER_URL, { order_info: order_info })
        .toPromise();
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
  public async createPreOrder(order_info: any) {
    try {
      var response = await this.http
        .post<{
          code: number;
          message: string;
          data: any;
        }>(this.CREATE_PREORDER_URL, { order_info: order_info })
        .toPromise();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public async updateOrder(order_info: any, payment_intent: string) {
    try {
      var response = await this.http
        .post<{
          code: number;
          message: string;
          data: any;
        }>(this.UPDATE_ORDER_URL, { order_info: order_info, payment_intent: payment_intent })
        .toPromise();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updatePaymentIntentStripe(paymentIntent: string, amount: number) {
    let data = {
      paymentIntent: paymentIntent,
      amount: amount
    }

    try {
      let response = await this.http.post<any>(this.updatePaymentIntentURI, data).toPromise();
      return response?.data;
    } catch (error) {
    }
  }

  async updateSubscriptionPrice(subscriptionId: string, amount: number, coupon: string) {
    let data = {
      subscriptionId: subscriptionId,
      amount: amount,
      coupon: coupon
    }

    try {
      let response = await this.http.post<any>(this.updateSubscriptionPaymentIntentURI, data).toPromise();
      return response?.data;
    } catch (error) {
    }
  }

  public async getOrderByPaymentIntent(paymentIntent: string) {
    try {
      const response = await this.http.get<any>(this.orderByPaymentIntentURI + paymentIntent).toPromise();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
