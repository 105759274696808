import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  public form: FormGroup;
  constructor(
    private refDialog: MatDialogRef<QuestionnaireComponent>,
    private formbuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      questionOne: ['', Validators.required],
      questionTwo: ['', Validators.required],
      questionThree: ['', Validators.required],
      questionFour: ['', Validators.required],
    })
  }

  send() {
      let array = [this.form.value.questionOne, this.form.value.questionTwo, this.form.value.questionThree, this.form.value.questionFour]
    this.refDialog.close(array)
  }
}
