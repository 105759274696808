<section>
  <div class="banner">
    <div class="title container">
      <h1>Blog Proceso Resultado</h1>
    </div>
  </div>
  <div class="filters" *ngIf="!loader">
    <h1>
      Mostrando: <span>{{ filterSelected }}</span>
    </h1>
    <div class="filters-select container">
      <mat-chip-list aria-label="Fish selection" id="blog-chips">
        <mat-chip (click)="selectFilter('all')">Todas</mat-chip>
        <ng-container *ngIf="tags.length > 0">
          <mat-chip *ngFor="let tag of tags" (click)="selectFilter(tag)">{{
            tag.tag
            }}</mat-chip>
        </ng-container>
      </mat-chip-list>

      <input class="search-input" matInput type="text" placeholder="Buscar una entrada..." name="filterPost"
        [(ngModel)]="filterPost" (keyup)="applyFilter($event)" />
    </div>
  </div>
  <div class="overflowed-section container">
    <h1 class="header-posts">Ultimas entradas</h1>
    <div class="cards-section">
      <div class="row">
        <ng-container *ngFor="let item of posts | filter: filterPost">

          <div class="card">
            <div class="card-img-top" [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }">
              <!-- <div *ngIf="item.isPaid" class="members-icon">
                <img src="assets/icons/member.png" alt="">
              </div> -->
            </div>
            <div class="card-body">
              <h3 class="body-title">{{ item.title }}</h3>
              <div class="body-content" [innerHTML]="item.description"></div>
              <div class="card-footer">
                <button [routerLink]="['/blog-entrada/' + item.id]" class="body-link more">Ver
                  más</button>
                <a class="body-link author">
                  <mat-icon>person</mat-icon>
                  {{item.author}}
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>