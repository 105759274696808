<div class="post-main">
    <div class="big-container content" *ngIf="!loader">
        <div class="left" [ngClass]="type">
            <div class="img">
                <img [src]="post?.image" alt="">
            </div>
            <p>{{post?.category}}</p>
            <h1>{{post?.title}}</h1>
            <div class="info">
                <div class="date">
                    <img src="assets/images/custom/calendar.svg" alt="">
                    <h1>{{$any(post).date?._seconds * 1000 | date: 'dd/MM/yyyy'}}</h1>
                </div>
                <div class="author">
                    <mat-icon>person</mat-icon>
                    <p>Autor: {{post.author}}</p>
                </div>
            </div>

            <div class="content-wrapper">
                <div id="content-blog" [innerHTML]="post?.description"></div>
            </div>

            <div class="content-pdf">
                <ng-container *ngFor="let file of post.file">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <!-- <h2>{{file.name}} <a [href]="file.url" target="_blank"><mat-icon>open_in_new</mat-icon></a></h2> -->
                                    <h2>{{file.name}}</h2>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-panel-description>
                                <pdf-viewer
                                [src]="file.url"
                                [render-text]="true"
                                [original-size]="false"
                                style="width: 100%; height: 800px; border: 1px solid rgb(198, 198, 198);"
                            ></pdf-viewer>
                            </mat-panel-description>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            </div>
        </div>
        <!-- <div class="right" *ngIf="type == 'analytics-posts'">
            <ng-container *ngIf="(post.file && !isObjEmpty(post.file)) || post.isVideo; else elseSRC">
                <div class="categories">
                    <h1>Recursos</h1>
                    <div class="type-img" *ngFor="let file of post.file">
                        <a target="_blank" [href]="file.url">
                            <img src="assets/images/custom/pdf.svg" width="110" *ngIf="file?.ref.includes('.pdf')">
                            <img src="assets/images/custom/doc.svg" width="110"
                                *ngIf="file?.ref.includes('doc') || file?.ref.includes('docx')">
                            <img src="assets/images/custom/xls.svg" width="110"
                                *ngIf="file?.ref.includes('xlsx') || file?.ref.includes('xls')">
                            <img [src]="file?.url" width="110"
                                *ngIf="file?.ref.includes('jpeg') || file?.ref.includes('png')">
                        </a>
                        <p class="file-name">{{file.name}}</p>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseSRC>
                <div class="categories">
                    <h1>Recursos</h1>
                    <p>No se encontraron recursos para esta publicación</p>
                </div>
            </ng-template>
        </div> -->
    </div>
</div>

<!-- <button (click)="viewTime()">DAleeeee</button> -->
