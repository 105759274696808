<h1 mat-dialog-title class="text-center">Llena el siguiente cuestionario</h1>
<div mat-dialog-content class="scroll">
    <p class="text-left" style="font-size: 19px;margin-bottom: 3rem;margin-top: 1rem;">Responde las siguientes preguntas para ofrecerte los cursos que se adecúen mas a tus aptitudes.</p>
    <form [formGroup]="form">
        <p class="label">Cuál consideras que es tu nivel de conocimientos en Inversiones en Bolsa de Valores?</p>
        <mat-radio-group aasria-label="Seleccione una opción" formControlName="questionOne" class="example-radio-group">
            <mat-radio-button class="example-radio-button" value="1">Bajo</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="2">Medio</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="3">Alto</mat-radio-button>
        </mat-radio-group>
        <p class="label">Cuánto tiempo llevas invirtiendo??</p>

        <mat-radio-group aria-label="Seleccione una opción" formControlName="questionTwo" class="example-radio-group">
            <mat-radio-button class="example-radio-button" value="1">Menos de 3 años</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="2">De 3 a 5 años</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="3">Más de 5 años</mat-radio-button>
        </mat-radio-group>
        <p class="label">Para que quieres invertir?</p>
        <div style="width:350px">
            <mat-radio-group aria-label="Seleccione una opción" formControlName="questionThree" class="example-radio-group">
                <mat-radio-button class="example-radio-button" value="1">Retiro, educación de mis hijos, invertir mis ahorros, <br>etc con plazos de mas de 7 años</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="2">No tengo un objetivo claro, solo quiero hacer crecer mis ahorros</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="3">Especular, entrar y salir del mercado</mat-radio-button>
            </mat-radio-group>
        </div>
        <p class="label">Cuánto tiempo tienes disponible para aprender, investigar y dedicarle a los Mercados?</p>
        <mat-radio-group aria-label="Seleccione una opción" formControlName="questionFour" class="example-radio-group">
            <mat-radio-button class="example-radio-button" value="1">Casi no tengo tiempo</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="2">Tengo algo de tiempo libre</mat-radio-button>
            <mat-radio-button class="example-radio-button" value="3">Tengo tiempo y me quiero dedicar a esto</mat-radio-button>
        </mat-radio-group>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button style="background-color:#FEC006D1;" (click)="send()" cdkFocusInitial class="mr-3">Enviar</button>
    <button mat-button color="warn" mat-dialog-close>Cerrar</button>
</div>