import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private base_url = 'https://us-central1-proceso-resultado.cloudfunctions.net';

  constructor(private http: HttpClient) {}

  async sendMail(body) {
    try {
      const answer = await this.http
        .post<{
          code: number;
          message: string;
        }>(`${this.base_url}/api/email/contact`, body)
        .toPromise();
      return answer;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
