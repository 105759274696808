<div class="custom-container" *ngIf="portfolioData.length > 0">
    <div class="general-wrapper">
        <div class="user-data-wrapper">
            <div class="info">
                <div class="field">
                    <h2>Retorno histórico esperado:</h2>
                    <h2 class="field-number bold">{{sumRates}}%</h2>
                </div>
                <div class="field">
                    <h2>Tasa de inflación:</h2>
                    <h2 class="field-number bold">{{portfolioData[0].inflationPercentage}}%</h2>
                </div>
                <div class="field">
                    <p>Edad inicial:</p>
                    <p class="field-number">{{portfolioData[0].initialAge}} años</p>
                </div>
                <div class="field">
                    <p>Edad de retiro:</p>
                    <p class="field-number">{{portfolioData[0].retirementAge}} años</p>
                </div>
                <div class="field">
                    <p>Temporalidad:</p>
                    <p class="field-number">{{getTemporality(portfolioData[0].temporality)}}</p>
                </div>
                <div class="field">
                    <p>Aportación inicial:</p>
                    <p class="field-number">{{portfolioData[0].startingAmount | currency: 'MXN'}}</p>
                </div>
                <div class="field">
                    <p>Mejor aportación:</p>
                    <p class="field-number">{{portfolioData[0].bestContribution | currency: 'MXN'}}</p>
                </div>
                <div class="field">
                    <p>Peor aportación:</p>
                    <p class="field-number">{{portfolioData[0].worstContribution | currency: 'MXN'}}</p>
                </div>
            </div>

            <div class="instruments">
                <table class="table table-hover text-center average-table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Instrumento</th>
                            <th scope="col">Vol Ponderado</th>
                            <th scope="col">Vol</th>
                            <th scope="col">Asignación inicial</th>
                            <th scope="col">Rendimiento Anual Promedio</th>
                            <th scope="col">Rendimiento Ponderado</th>
                            <!-- <th scope="col">Max DD</th> -->
                            <!-- <th scope="col">Max DD Ponderado</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of portfolioData">
                            <td>{{item.category}}</td>
                            <td>{{((item.standarDeviation * item.percentage) / 100).toFixed(2)}}%</td>
                            <td>{{(item.standarDeviation).toFixed(2)}}%</td>
                            <td>{{(item.percentage).toFixed(2)}}%</td>
                            <td>{{(item.historicalPer).toFixed(2)}}%</td>
                            <td>{{((item.percentage * item.historicalPer) / 100).toFixed(2)}}%</td>
                            <!-- <td>53.11%</td> -->
                            <!-- <td>{{((item.maxDropDown * item.percentage) / 100).toFixed(2)}}%</td> -->
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <!-- <td>{{sumMaxDD.toFixed(2)}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mat-tab-group-wrapper">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Promedio">
                    <div class="description description-average-contribution">
                        <h3>Resultados "Promedio"</h3>
                        <p>Esta sección muestra los posibles resultados de tu inversión basados en un escenario promedio
                            de rendimiento basado en tus mejores y peores aportaciones. La gráfica te muestra una
                            proyección de cómo tu inversión puede crecer a lo
                            largo del tiempo, lo que te permitirá visualizar el potencial de tu inversión a largo plazo.
                        </p>
                    </div>
                    <div echarts [options]="averageChartOption" theme="dark" id="average-chart"
                        class="chart average-chart">
                    </div>
                    <div class="average-wrapper">
                        <table class="table table-hover text-center average-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of averageResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Mejor aportación">
                    <div class="description description-best-contribution">
                        <h3>Resultados "Mejor aportación"</h3>
                        <p>En esta sección, se muestra el mejor escenario posible para tu inversión, basado en la mejor
                            aportación mensual que puedes hacer. La gráfica te muestra cómo puede crecer tu inversión si
                            mantienes este nivel de aportación y se cumplen las mejores expectativas de rendimiento.</p>
                    </div>
                    <div echarts [options]="bestContributionChartOption" theme="dark" id="best-contribution-chart"
                        class="chart best-contribution-chart"></div>
                    <div class="best-contribution-wrapper">
                        <table class="table table-hover text-center best-contribution-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of bestContributionResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Peor aportación">
                    <div class="description description-worst-contribution">
                        <h3>Resultados "Menor aportación"</h3>
                        <p>En esta sección, se muestra el peor escenario posible para tu inversión, basado en la peor
                            aportación mensual que puedes hacer. La gráfica te muestra cómo puede crecer tu inversión si
                            mantienes este nivel de aportación y se cumplen las peores expectativas de rendimiento.</p>
                    </div>
                    <div echarts [options]="worstContributionChartOption" theme="dark" id="worst-contribution-chart"
                        class="chart worst-contribution-chart"></div>
                    <div class="worst-contribution-wrapper">
                        <table class="table table-hover text-center worst-contribution-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Edad</th>
                                    <th scope="col">Inversión</th>
                                    <th scope="col">Aportación (Anual)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of worstContributionResults">
                                    <td>{{item.age}}</td>
                                    <td>{{item.investment | currency: 'MXN' }}</td>
                                    <td>{{item.contribution | currency: 'MXN' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="disclaimer">
                <h3>Importante:</h3>
                <p>Por favor, ten en cuenta que <b> los resultados presentados por esta herramienta son aproximados y no
                        definitivos.</b> La inversión en la bolsa de valores implica un riesgo y los resultados pueden
                    variar debido a
                    factores externos e impredecibles como las fluctuaciones del mercado, la inflación, cambios
                    políticos y
                    económicos, entre otros. Los resultados presentados en esta herramienta se basan en estimaciones y
                    proyecciones de rendimiento que pueden no ser precisas. Por lo tanto, te recomendamos tomar estos
                    resultados
                    como una guía general para ayudarte a tomar decisiones informadas sobre tu inversión, pero siempre
                    debes
                    considerar los posibles riesgos y estar preparado para afrontar cualquier eventualidad que pueda
                    afectar el
                    rendimiento de tu inversión.</p>
            </div>
        </div>
    </div>

</div>