import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bars-chart',
  templateUrl: './bars-chart.component.html',
  styleUrls: ['./bars-chart.component.scss']
})
export class BarsChartComponent implements OnInit {

  public xAxisData = [];
  public data1 = [];
  public data2 = [];

  chartOption: EChartsOption = {
    title: {
      text: 'Barras de prueba'
    },
    legend: {
      data: ['RoC', 'Trend']
    },
    toolbox: {
      // y: 'bottom',
      feature: {
        magicType: {
          type: ['stack']
        },
        dataView: {},
        saveAsImage: {
          pixelRatio: 2
        }
      }
    },
    tooltip: {},
    xAxis: {
      data: this.xAxisData,
      splitLine: {
        show: false
      }
    },
    yAxis: {
    },
    series: [{
      name: 'RoC',
      type: 'bar',
      data: this.data1,
      emphasis: {
        focus: 'series'
      },
      animationDelay: function (idx) {
        return idx * 10;
      }
    }, {
      name: 'Trend',
      type: 'bar',
      data: this.data2,
      emphasis: {
        focus: 'series'
      },
      animationDelay: function (idx) {
        return idx * 10 + 100;
      }
    }],
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx) {
      return idx * 5;
    }


  };

  options: Observable<any>;

  constructor() { }

  ngOnInit(): void {
    // Aquí podemos poner las fechas ya que son los datos de abajo
    for (var i = 0; i < 100; i++) {
      this.xAxisData.push('Algo ' + i);
      this.data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
      this.data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
    }
  }

}
