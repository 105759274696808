import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { CalendarEventsService } from '../../services/calendar/calendar-events.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-activities-calendar',
  templateUrl: './activities-calendar.component.html',
  styleUrls: ['./activities-calendar.component.scss'],
})
export class ActivitiesCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public searching: boolean = false;

  public calendarOptions: CalendarOptions = {
    locales: [esLocale],
    locale: 'es',
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    showNonCurrentDates: false,
    fixedWeekCount: false,
    eventDisplay: 'none',
    dateClick: this.selectDay.bind(this),
  };

  public events1 = [];

  public events = [
    {
      title: 'Evento prueba',
      start: new Date(),
      end: new Date(),
    },
    {
      title: 'Evento prueba',
      start: new Date(),
      end: new Date(),
    },
    {
      title: 'Evento prueba',
      start: new Date(),
      end: new Date(),
    },
  ];

  public filterEvents = [];
  public titleDate = new Date();
  constructor(private calendarEvents: CalendarEventsService) { }

  async ngOnInit() {
    await this.getCalendarEvents();
    this.selectDayStart();
  }

  async getCalendarEvents() {
    try {
      let response = await this.calendarEvents.getCalendarEvents();
      response.forEach((event) => {
        this.events1.push(event);
      });
    } catch (error) {
      this.searching = false;
      throw new Error('Ocurrió un error al buscar los datos');
    }
  }

  selectDay($event) {
    
    let myDate = new Date();
    myDate.setHours(0, 0, 0, 0);

    this.filterEvents = [];
    this.titleDate = $event.date;

    this.events1.forEach((event) => {

      let dateString = event.date.split('T')[0];

      if (dateString === $event.dateStr) {
        this.filterEvents.push(event);
      }
    });
  }

  selectDayStart() {

    let myDate = new Date();
    myDate.setHours(0, 0, 0, 0);

    this.filterEvents = [];
    this.titleDate = myDate;

    this.events1.forEach((event) => {
      
      let dateString = event.date.split('T')[0];
      
      if (dateString === myDate.toLocaleDateString('sv')) {
        this.filterEvents.push(event);
      }
    });
  }
}
