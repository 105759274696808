import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alerts/alert.service';
import { AssetPricesService } from 'src/app/services/assetsPrices/asset-prices.service';
import { listOfCategories } from 'src/app/components/new-tool/data';
import { listOfRefRates } from 'src/app/components/new-tool/referenceRates';
import { CategoryForm, FrecuencyRecord, FormModel } from './models/form.model';
import { log } from 'console';
import { EChartsOption } from 'echarts';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import domtoimage from 'dom-to-image';
import { jsPDF, RGBAData } from "jspdf";
import { PdfServiceService } from 'src/app/services/pdf-service/pdf-service.service';
import { Observable } from 'rxjs-compat';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { packs } from 'src/app/services/packs/packs';


const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-new-tool',
  templateUrl: './new-tool.component.html',
  styleUrls: ['./new-tool.component.scss']
})


export class NewToolComponent implements OnInit {
  screenWidth: number;

  public isGeneratingPdf: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    if (window.innerWidth >= 375 && window.innerWidth <= 600) {
      this.initOpts = {
        renderer: 'svg',
        width: 330,
        height: 400
      }
    }

    if (window.innerWidth >= 600 && window.innerWidth <= 890) {
      this.initOpts = {
        renderer: 'svg',
        width: 550,
        height: 400
      }
    }

    if (window.innerWidth >= 1440) {
      this.initOpts = {
        renderer: 'svg',
        width: 1440,
        height: 400
      }
    }
  }

  initOpts = {
    renderer: 'svg',
    width: 900,
    height: 400
  };

  maxDate = new Date(2019, 11, 31); // año, mes (0-11), día
  minDate = new Date(2018, 11, 31); // año, mes (0-11), día

  public dataForm: FormGroup;

  public isLoading: boolean = false;
  public isGeneratingPdf$: Observable<boolean>;

  public categoriesList = listOfCategories;
  public refRatesList = listOfRefRates;
  public tableResults = [];
  public comparisonResults = [];

  public chartValues = [];
  public chartFrec = [];

  public charOptions: EChartsOption[] = [];
  public charOption: EChartsOption;

  public userInformation: any;
  public subscriptionName: string;
  public subscription
  public packs = packs
  public today

  constructor(
    private assetPricesService: AssetPricesService,
    private alertService: AlertService,
    private userService: UserService,
    private authService: AuthService,

    private renderer: Renderer2,

    private formBuilder: FormBuilder
  ) {
    this.dataForm = new FormGroup({
      startDate: new FormControl(this.dateToTest('31/12/1971'), [Validators.required]),
      endDate: new FormControl(this.dateToTest('31/12/2019'), [Validators.required]),
      categories: this.formBuilder.array([]),
      initialAge: new FormControl(25, [Validators.required]),
      retirementAge: new FormControl(50, [Validators.required]),
      startingAmount: new FormControl(320000, [Validators.required]),
      temporality: new FormControl('monthly', [Validators.required]),
      bestContribution: new FormControl(25000, [Validators.required]),
      worstContribution: new FormControl(0, [Validators.required]),
      // fullContribution: new FormControl(36000000, [Validators.required]),
      inflationPercentage: new FormControl('t-bills', [Validators.required]),
      customInflationPercentage: new FormControl('')
    })
  }

  ngOnInit(): void {
    this.addNewField();
    this.getUserInformation();
    this.today = new Date();
  }

  async getUserInformation() {
    try {
      const response = await this.authService.isLoggedIn();
      const userUid = response.uid;
      this.userInformation = await this.userService.getUserData(userUid)
      console.log(this.userInformation);
      this.getSubs();
    } catch (error) {
      console.log(error);
    }
  }

  async getSubs() {
    const subs = await this.userService.getUserSubscriptions(this.userInformation.stripeclient_id);

    if(subs.data.length == 0) {
      this.subscription = ''
      this.subscriptionName = ''
    }

    let isActiveSub: boolean = false;
    let someActive = subs.data.some((sub) => sub.status == 'active' || sub.status == 'trialing');
    let currentDate = new Date().setHours(0, 0, 0, 0);
    let someCurrentPeriodActive = subs.data.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);

    if(someActive || someCurrentPeriodActive) {
      this.packs.forEach(pack => {
        subs.data.map((element) => {
          
          if (element.plan.product == pack.monthId && element.plan.active) {
            this.subscription = element
            this.subscriptionName = pack.title
          }
          if (element.plan.product == pack.yearId) {
            this.subscription = element
            this.subscriptionName = pack.titleYear
          }
        })
      }); 
    } else {
      return false
    }
  }

  get categoriesForm(): FormArray {
    return this.dataForm.get('categories') as FormArray;
  }

  dateToTest(date: any) {
    const splitDate = date.split('/');
    const newDate = new Date(splitDate[2], splitDate[1], - 1, splitDate[0])
    return newDate;
  }

  addNewField() {
    this.categoriesForm.push(
      this.formBuilder.group({
        category: new FormControl('', [Validators.required]),
        categoryToCompare: new FormControl('', [Validators.required]),
        percentage: new FormControl(100, Validators.required)
      })
    )
  }

  addFieldToCompare() {
    this.categoriesForm.push(
      this.formBuilder.group({
        category: new FormControl('', [Validators.required]),
        categoryToCompare: new FormControl('', [Validators.required]),
        percentage: new FormControl(100, Validators.required)
      })
    )
  }

  deleteField(index: number) {
    this.categoriesForm.removeAt(index);
  }

  async sendData() {
    let formValues = this.dataForm.value
    const isValidForm = this.formValidations(formValues)

    if (isValidForm) {
      try {
        this.isLoading = true;
        Swal.fire('Cargando...', 'Enviando información');
        Swal.showLoading();
        const response = await this.assetPricesService.sendData(formValues);

        this.tableResults = response.selectedTools
        this.comparisonResults = response.comparisonTools

        console.log('table-results',this.tableResults);
        

        this.isLoading = false;
        Swal.close();

        this.chartValues = response.selectedTools[0].recordFrec.map((frecRecord: FrecuencyRecord) => { return frecRecord.value.toString() })
        this.chartFrec = response.selectedTools[0].recordFrec.map((frecRecord: FrecuencyRecord) => { return frecRecord.frecuency })

        this.charOptions = []

        for (let index = 0; index < response.selectedTools.length; index++) {
          this.chartValues = response.selectedTools[index].recordFrec.map((frecRecord: FrecuencyRecord) => { return frecRecord.value.toString(); })
          this.chartFrec = response.selectedTools[index].recordFrec.map((frecRecord: FrecuencyRecord) => { return frecRecord.frecuency; })

          this.charOption = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            title: {
              text: response.selectedTools[index].category,
              left: 'center',
            },
            grid: {
              containLabel: true
            },
            xAxis:
            {
              type: 'category',
              data: this.chartValues,
              axisTick: {
                alignWithLabel: true
              }
            }
            ,
            yAxis: {
              type: 'value',
              name: 'Frecuencia',
              position: 'left',
              axisLabel: {
                formatter: '{value}'
              }
            },
            series: [{
              name: 'Frecuencia',
              type: 'bar',
              data: this.chartFrec
            }]
          };

          this.charOptions.push(this.charOption)
        }

      } catch (error) {
        console.log(error, '-> TS');
        this.alertService.shootSimpleAlert('error', 'Ha ocurrido un error', error?.error?.message)
        this.isLoading = false;
      }
    }
  }

  formValidations(formValues: FormModel): boolean {
    // Validate empty fields
    if (this.dataForm.invalid) {
      this.dataForm.markAllAsTouched();
      return false
    }

    // Validate 100% percentage
    const sumPercentage = formValues.categories.reduce((total, category) => total + category.percentage, 0);

    console.log(sumPercentage);
    

    if (sumPercentage != 100) {
      this.alertService.shootSimpleAlert('info', 'La suma de todos los valores debe ser igual a 100%')
      return false
    }

    // Validate duplicated fields
    const isDuplicate = this.checkForDuplicates(formValues.categories);
    if (isDuplicate) {
      this.alertService.shootSimpleAlert('info', 'Los valores seleccionados están duplicados')
      return false
    }

    // Valid current and retirement age
    if (formValues.initialAge >= formValues.retirementAge) {
      this.alertService.shootSimpleAlert('info', 'La edad actual no puede ser mayor o igual a la edad de retiro')
      return
    }
    if (formValues.initialAge > 105 || formValues.retirementAge > 105) {
      this.alertService.shootSimpleAlert('info', 'La edad debe ser menor a 105')
      return
    }

    return true;
  }

  checkForDuplicates(source: any[]) {
    const names = source.map((el) => el.category.name);
    const uniqueNames = new Set(names);
    console.log(names.length === uniqueNames.size);

    return names.length !== uniqueNames.size;
  }

  dateFilter = (d: Date) => {
    if (d != null) {
      const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      return d.getDate() === lastDayOfMonth.getDate() ? true : false;
    }
  };

  validateInflation(event: any) {
    console.log(event);
    if (event.value == 'custom') {
      this.dataForm.controls['customInflationPercentage'].setValidators(Validators.required);
      this.dataForm.updateValueAndValidity();
    } else {
      this.dataForm.controls['customInflationPercentage'].clearValidators();
      this.dataForm.updateValueAndValidity();
    }
  }

  async downloadPDF() {
    try {
      this.isGeneratingPdf = true;
      Swal.fire({
        title: 'Cargando...',
        text: 'El pdf se está creando',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onOpen: () => {
           Swal.showLoading();
        }
     });

      setTimeout(async () => {
        const pdfContent = document.getElementById('pdf-content');

        const img: any = await this.loadImage(pdfContent);
        const { width, height } = img;

        const orientation = width > height ? 'l' : 'p';
        const doc = new jsPDF(orientation, 'px', [width, height]);        

        doc.addImage(img, 'PNG', 15, 15, width, height);
        const today = new Date().toLocaleDateString('en-GB')
        const filename = `calculo_inversiones_${today}.pdf`;
        doc.save(filename);

        this.isGeneratingPdf = false
        Swal.close();
      }, 500);
    } catch (error) {
      Swal.close();
      console.log('error', error);
      this.alertService.shootSimpleAlert('error', 'Ocurrió un error al generar pdf');
    }
  }

  loadImage(node) {
    return new Promise((resolve, reject) => {
      domtoimage.toPng(node, { bgcolor: '#fff' })
        .then(dataUrl => {
          const img = new Image();
          img.src = dataUrl;
          img.onload = () => resolve(img);
        })
        .catch(error => reject(error));
    });
  }


  getTemporality(temporality: string) {
    switch (temporality) {
      case 'biweekly':
        return 'Quincenal'

      case 'monthly':
        return 'Mensual'

      case 'quarterly':
        return 'Trimestral'

      case 'biannual':
        return 'Semestral'
    }
  }

}