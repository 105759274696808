import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-analysis-post',
    templateUrl: './analysis-post.component.html',
    styleUrls: ['./analysis-post.component.scss']
})
export class AnalysisPostComponent implements OnInit {

    public postId: string;
    constructor(
        private route: ActivatedRoute,

    ) { }

    ngOnInit(): void {
        this.postId = this.route.snapshot.params['post'];
    }

}
