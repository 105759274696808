import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'rxjs/add/operator/map';
import { BlogComment, BlogFamily } from 'src/app/models/blog';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    private getPostAnayticURI: string = `${environment.URL_API}/blog/get-analytic-by-type`;
    private getAllPostAnayticURI: string = `${environment.URL_API}/blog/get-all-analytic-posts`;
    private urlMultiple: string = `${environment.URL_API}/blog/get-all`;
    private urlPost: string = `${environment.URL_API}/blog/get-by-id`;
    private getAnayticByIdURI: string = `${environment.URL_API}/blog/get-analytic-by-id`;
    private updateViews: string = `${environment.URL_API}/analytic/increase-view`;
    private updateTime: string = `${environment.URL_API}/analytic/increase-time`;
    private addVisitCountURI: string = `${environment.URL_API}/analytic/add-visit-count`;

    constructor(
        public afs: AngularFirestore,
        private http: HttpClient
    ) { }

    getPosts() {
        var ref = this.afs.collection('posts', ref => ref.orderBy('date', 'desc'));
        var user$ = ref.snapshotChanges().map(actions => {
            return actions.map(action => {
                const data = action.payload.doc.data() as any;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
        });

        return user$;
    }

    async getAnalyticPosts(type: string) {
        try {
            const response = await this.http.get<{ code: number, message: string, data: { found: boolean, post: [] } }>(`${this.getPostAnayticURI}/${type}`).toPromise();
            return response.data.post;
        } catch (error) {
            return error;
        }
    }

    async getAllAnalyticPosts() {
        try {
            const response = await this.http.get<{ code: number, message: string, data: any }>(this.getAllPostAnayticURI).toPromise();
            return response.data.listPosts;
        } catch (error) {
            return error;
        }
    }

    public async getAllPosts() {
        try {
            const response = await this.http.post<BlogFamily>(this.urlMultiple, {}).toPromise();
            return response
        } catch (error) {
            throw error
        }
    }

    public async getById(id: string) {
        try {
            const response = await this.http.post<BlogFamily>(this.urlPost, { id }).toPromise();
            return response
        } catch (error) {
            throw error
        }
    }

    public async getAnalyticById(id: string) {
        try {
            const response = await this.http.post<BlogFamily>(this.getAnayticByIdURI, { id }).toPromise();
            return response
        } catch (error) {
            throw error
        }
    }

    public async updateViewsCount(id) {
        try {
            const response = await this.http.post<BlogFamily>(this.updateViews, { id }).toPromise();
            return response
        } catch (error) {
            throw error
        }
    }

    public async updateViewTime(id: string, time: any) {
        try {
            const res = await this.http.post<BlogFamily>(this.updateTime, { id, time }).toPromise();
        } catch (error) {
            throw error
        }
    }

    async getAllTags() {
        try {
            const snapshot = await this.afs.collection('tags').get().toPromise()
            return snapshot.docs.map(element => element.data());
        } catch (error) {
            throw error;
        }
    }

    async addCountVisit(id: string) {
        try {
            console.log('llego al servicio');
            
            let response = await this.http.post(this.addVisitCountURI, {id}).toPromise();
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    }
}

