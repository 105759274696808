<!-- <section class="section">
  <div class="main">
    <div class="col-12 title">
      <h1>¿Por qué elegirnos?</h1>
    </div>
    <div class="col-12 my-5">
      <div class="row">
        <div class="col-12 col-lg-4 why-section">
          <div class="col-12 section-image">
            <img src="../../../assets/icons/finance.svg" alt="" />
          </div>
          <div class="col-12 section-title my-3">
            <span>Calidad</span>
          </div>
          <div class="col-12 section-text">
            <p>
              Nuestro contenido y material, siempre tiene y tendrá un valor muy
              superior al precio. Las herramientas y cursos que enseñamos no
              suelen ser explicadas tan a detalle por ninguna academia ni
              universidad.
            </p>
          </div>
        </div>

        <div class="col-12 col-lg-4 why-section">
          <div class="col-12 section-image">
            <img src="../../../assets/icons/reading.svg" alt="" />
          </div>
          <div class="col-12 section-title my-3">
            <span>Experiencia</span>
          </div>
          <div class="col-12 section-text">
            <p>
              Contamos con más de 20 años de experiencia en el mundo de las
              inversiones y la bolsa; más de 10 escribiendo en Rankia sobre
              ETFs, indexación pasiva, mercados, estrategias, economía, finanzas
              y value investing
            </p>
          </div>
        </div>

        <div class="col-12 col-lg-4 why-section">
          <div class="col-12 section-image">
            <img
              src="../../../assets/icons/analytics.svg"
              alt=""
              id="last-image"
            />
          </div>
          <div class="col-12 section-title my-3">
            <span>Honestidad</span>
          </div>
          <div class="col-12 section-text">
            <p>
              No ofrecemos soluciones mágicas. Uno de nuestros principales
              principios, es fomentar la habilidad, el pensamiento
              independiente, el sentido crítico y el análisis.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 button-section">
      <button class="btn btn-success">Comienza Ahora</button>
    </div>
  </div>
</section> -->

<div class="section">
  <h2>Beneficios Únicos</h2>
  <div class="body__page">
    <div class="container__card container">
      <div class="card c1">
        <div class="icon">
          <img src="assets/icons/finance.svg" alt="" />
        </div>
        <div class="info__description">
          <h3>Calidad</h3>
          <p>
            Nuestro contenido y material, siempre tiene y tendrá un valor muy
            superior al precio. Las herramientas y cursos que enseñamos no
            suelen ser explicadas tan a detalle por ninguna academia ni
            universidad.
          </p>
        </div>
      </div>

      <div class="card c2">
        <div class="icon">
          <img src="assets/icons/reading.svg" alt="" />
        </div>
        <div class="info__description">
          <h3>Experiencia</h3>
          <p>
            Contamos con más de 20 años de experiencia en el mundo de las
            inversiones y la bolsa; más de 10 escribiendo en Rankia sobre ETFs,
            indexación pasiva, mercados, estrategias, economía, finanzas y value
            investing.
          </p>
        </div>
      </div>

      <div class="card c3">
        <div class="icon">
          <img src="assets/icons/analytics.svg" alt="" />
        </div>
        <div class="info__description">
          <h3>Honestidad</h3>
          <p>
            No ofrecemos soluciones mágicas. Uno de nuestros principales
            principios, es fomentar la habilidad, el pensamiento independiente,
            el sentido crítico y el análisis.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
