import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'console';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public hide = true;

    registerForm: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
    ) {
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });
    }


    ngOnInit(): void {
    }
    
    async submit() {
        var form = this.registerForm.value;
        form.email = form.email.toLowerCase()
        try {
            Swal.fire('Creando cuenta...', 'Espere por favor');
            Swal.showLoading();
            var user_response = await this.authService.register(form);
            await this.authService.createStripeClient(form, user_response);
            Swal.close();
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
            });
            Toast.fire({
                icon: 'success',
                title: 'Cuenta creada correctamente'
            })
            this.router.navigate(['/email-verified']);

        } catch (error) {
            console.log(error.error.message);

            if (error.error.message == "Email already exist") {
                Swal.fire('Oops...', 'El correo que ingresaste ya existe', 'error')
                this.router.navigate(['/registro']);
            }
            else {
                Swal.fire('Oops...', 'Ocurrió un error al realizar el registro, intente de nuevo', 'error');
            }
        }
    }

}
