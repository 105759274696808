<div mat-dialog-title>
  <h1>Seleccione una tarjeta</h1>
  <button *ngIf="!loader" mat-button mat-dialog-close>&times;</button>
</div>

<div mat-dialog-content>
  <ng-container *ngIf="loader">
    <app-spinner></app-spinner>
  </ng-container>
  <div class="form" *ngIf="!loader">
    <div
      class="card"
      *ngFor="let card of cards; let i = index"
      (click)="selectCard(i)"
    >
      <div class="card-type-img">
        <img
          src="assets/images/card-types/visa.svg"
          alt=""
          *ngIf="card.type == 'visa'"
        />
        <img
          src="assets/images/card-types/amex.svg"
          alt=""
          *ngIf="card.type == 'amex'"
        />
        <img
          src="assets/images/card-types/mc.png"
          alt=""
          *ngIf="card.type == 'mastercard'"
        />
      </div>
      <h1>
        <ng-container *ngIf="card.type != 'amex'; else visa_mc"
          >**** **** ****
        </ng-container>
        <ng-template #visa_mc>**** ****** *</ng-template>{{ card.lastDigits }}
      </h1>
      <h1>{{ card.exp_month }}/{{ card.exp_year }}</h1>
    </div>
  </div>
</div>
