import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Card } from '../../models/user';

@Component({
  selector: 'app-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.scss']
})
export class SelectCardComponent implements OnInit {
  public loader: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public cards: Array<Card>,
    public dialogRef: MatDialogRef<SelectCardComponent>
  ) { }

  ngOnInit(): void {
  }

  selectCard(i: number) {
    this.dialogRef.close({ card: this.cards[i] });
  }
}
