<section class="hero">
  <div class="container">
    <div class="hero__wrapper">
      <div class="hero__left">
        <div class="hero__left-wrapper">
          <h1 class="hero__heading">Proceso Resultado</h1>
          <p class="hero__info">
            Tomar mejores decisiones hace la diferencia
          </p>
          <div class="button__wrapper">
            <a href="" class="btn primary-btn" [routerLink]="['/cursos']"
              >Ver Cursos</a
            >
          </div>
        </div>
      </div>
      <div class="hero__right">
        <div class="hero__imgWrapper">
          <img src="assets/images/main-banner.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
