import { LoginGuardService } from './services/login-guard/login-guard.service';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ActivitiesCalendarComponent } from './components/activities-calendar/activities-calendar.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormTendenciasComponent } from './pages/form-tendencias/form-tendencias.component';
import { HomeComponent } from './pages/home/home.component';
import 'echarts-gl';
import { BarsChartComponent } from './components/bars-chart/bars-chart.component';
import { BlogComponent } from './pages/blog/blog.component';
import { AboutComponent } from './pages/about/about.component';
import { CourseComponent } from './pages/course/course.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { ToolsPreSelectionComponent } from './pages/tools-pre-selection/tools-pre-selection.component';
import { ScreenersComponent } from './pages/screeners/screeners.component';
import { CourseDatailsComponent } from './components/course-datails/course-datails.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RecommendedCoursesComponent } from './pages/recommended-courses/recommended-courses.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { AnalysisPreSelectionComponent } from './pages/analysis-pre-selection/analysis-pre-selection.component';
import { AnalysisPostComponent } from './pages/analysis-post/analysis-post.component';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { NormalBlogPostComponent } from './pages/normal-blog-post/normal-blog-post.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { EmailVerfiedComponent } from './components/email-verfied/email-verfied.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { NewToolComponent } from './components/new-tool/new-tool.component';

const routes: Routes = [
    //{ path: '', redirectTo: 'inicio', pathMatch: 'full', },
    { path: '', redirectTo: 'cursos', pathMatch: 'full', },
    { path: 'inicio', component: HomeComponent, },
    { path: 'selecccionar-herramienta', component: ToolsPreSelectionComponent },
    { path: 'tendencias/:status', component: FormTendenciasComponent, canActivate: [AuthGuardService] },
    { path: 'screeners/:status', component: ScreenersComponent, canActivate: [AuthGuardService] },
    { path: 'portafolio/:status', component: NewToolComponent, canActivate: [AuthGuardService] },
    { path: 'verificar-compra/:id', component: CheckoutComponent },
    { path: 'verificar-compra', component: CheckoutComponent },
    { path: 'confirmacion-compra/:orderId/:from', component: PaymentSuccessComponent, canActivate: [AuthGuardService] },
    { path: 'blog', component: BlogComponent, },
    { path: 'blog-entrada/:type/:id', component: BlogPostComponent, },
    { path: 'blog-entrada/:id', component: NormalBlogPostComponent, },
    { path: 'mi-perfil', component: MyProfileComponent, canActivate: [AuthGuardService] },
    { path: 'nosotros', component: AboutComponent, },
    { path: 'selecccionar-analisis', component: AnalysisPreSelectionComponent, canActivate: [AuthGuardService] },
    { path: 'analisis', component: AnalysisComponent, canActivate: [AuthGuardService] },
    { path: 'analisis-entrada/:post', component: AnalysisPostComponent, canActivate: [AuthGuardService] },
    { path: 'calendario', component: ActivitiesCalendarComponent, },
    { path: 'cursos', component: CourseComponent, },
    { path: 'curso-detalles/:id', component: CourseDatailsComponent, },
    { path: 'cursos-recomendados/:category', component: RecommendedCoursesComponent, },
    { path: 'preguntas-frecuentes', component: FaqsComponent },
    { path: 'politica-privacidad', component: PrivacyPolicyComponent },
    { path: 'politica-cookies', component: CookiesPolicyComponent },
    { path: 'registro', component: RegisterComponent },
    { path: 'email-verified', component: EmailVerfiedComponent },
    { path: 'inicio-sesion', component: LoginComponent, canActivate: [LoginGuardService] },
    { path: 'recuperar-contraseña', component: RecoverPasswordComponent, canActivate: [LoginGuardService] },

    { path: '**', redirectTo: 'cursos', },
    //{ path: '**', redirectTo: 'inicio', },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled',
            scrollPositionRestoration: 'top'
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
