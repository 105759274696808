import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { HowComponent } from './components/how/how.component';
import { BlogHomeComponent } from './components/blog-home/blog-home.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { PointsChartComponent } from './components/points-chart/points-chart.component';
import { HttpClientModule } from '@angular/common/http';
import { FormTendenciasComponent } from './pages/form-tendencias/form-tendencias.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { BarsChartComponent } from './components/bars-chart/bars-chart.component';
import { BlogComponent } from './pages/blog/blog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CourseComponent } from './pages/course/course.component';
import { CardCourseComponent } from './components/card-course/card-course.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxPrintModule } from 'ngx-print';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
//CALENDAR
import { ActivitiesCalendarComponent } from './components/activities-calendar/activities-calendar.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { ToolsPreSelectionComponent } from './pages/tools-pre-selection/tools-pre-selection.component';
import { MatButtonModule } from '@angular/material/button';
import { ScreenersComponent } from './pages/screeners/screeners.component';
import { MatChipsModule } from '@angular/material/chips';
import { ContactComponent } from './components/course/contact/contact.component';
import { registerLocaleData } from '@angular/common';
import localeMX from '@angular/common/locales/es-MX';
import { LOCALE_ID } from '@angular/core';
import { CourseDatailsComponent } from './components/course-datails/course-datails.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion'; 
import {MatStepperModule} from '@angular/material/stepper';
import { IConfig, NgxMaskModule } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { RecommendedCoursesComponent } from './pages/recommended-courses/recommended-courses.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { AnalysisPreSelectionComponent } from './pages/analysis-pre-selection/analysis-pre-selection.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { ModalSubscriptionComponent } from './components/modal-subscription/modal-subscription.component';
import { AnalysisPostComponent } from './pages/analysis-post/analysis-post.component';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { HeroHomeComponent } from './components/home-hero/hero-home.component';
import { NormalBlogPostComponent } from './pages/normal-blog-post/normal-blog-post.component';
import { ValuesSectionComponent } from './components/values-section/values-section.component';
import { AboutComponent } from './pages/about/about.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterAnalyticsPipe } from './pipes/filter-analytics.pipe';
import { BannerDiscountComponent } from './components/banner-discount/banner-discount.component';
import { AddCardDialogComponent } from './components/add-card-dialog/add-card-dialog.component';
import { SelectCardComponent } from './components/select-card/select-card.component';
import {MatBadgeModule} from '@angular/material/badge';
import { EmailVerfiedComponent } from './components/email-verfied/email-verfied.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SubscriptionDialogComponent } from './components/subscription-dialog/subscription-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { CoursesListComponent } from './components/courses-list/courses-list.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { BuyNoAccountDialogComponent } from './components/buy-no-account-dialog/buy-no-account-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NewToolComponent } from './components/new-tool/new-tool.component';
import { InstrumentPortfolioComponent } from './components/instrument-portfolio/instrument-portfolio.component';
import { ResultsContributionsTabsComponent } from './components/results-contributions-tabs/results-contributions-tabs.component';
import { InstrumentPortfolioPdfComponent } from './components/instrument-portfolio-pdf/instrument-portfolio-pdf.component';
import { SelectPlanTypeDialogComponent } from './components/select-plan-type-dialog/select-plan-type-dialog.component';
registerLocaleData(localeMX);

//CALENDAR PLUGINS
FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
]);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        HomeSliderComponent,
        HeroHomeComponent,
        WhyUsComponent,
        HowComponent,
        BlogHomeComponent,
        FooterComponent,
        PointsChartComponent,
        FormTendenciasComponent,
        BarsChartComponent,
        BlogComponent,
        ActivitiesCalendarComponent,
        CourseComponent,
        CardCourseComponent,
        RegisterComponent,
        EmailVerfiedComponent,
        LoginComponent,
        ToolsPreSelectionComponent,
        ScreenersComponent,
        ContactComponent,
        CourseDatailsComponent,
        CheckoutComponent,
        QuestionnaireComponent,
        RecommendedCoursesComponent,
        PaymentSuccessComponent,
        AnalysisPreSelectionComponent,
        AnalysisComponent,
        ModalSubscriptionComponent,
        AnalysisPostComponent,
        BlogPostComponent,
        NormalBlogPostComponent,
        AboutComponent,
        ValuesSectionComponent,
        FaqsComponent,
        PrivacyPolicyComponent,
        CookiesPolicyComponent,
        SearchBarComponent,
        FilterPipe,
        FilterAnalyticsPipe,
        BannerDiscountComponent,
        AddCardDialogComponent,
        SelectCardComponent,
        RecoverPasswordComponent,
        MyProfileComponent,
        SubscriptionDialogComponent,
        SanitizeUrlPipe,
        CoursesListComponent,
        SpinnerComponent,
        ChangepasswordComponent,
        BuyNoAccountDialogComponent,
        NewToolComponent,
        InstrumentPortfolioComponent,
        ResultsContributionsTabsComponent,
        InstrumentPortfolioPdfComponent,
        SelectPlanTypeDialogComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        CarouselModule,
        MatTabsModule,
        FullCalendarModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        NgxMaskModule.forRoot(),
        NgxPayPalModule,
        NgxPrintModule,
        MatBadgeModule,
        PdfViewerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        // NgxStripeModule.forRoot('pk_test_51JNLEKC7qnCJJDECZn4Y2XicyCvI737yfcWLUEBQKFRF3bLZrJqJQ9otEvbC3fzZctJLmZwZRuGe4jfQWcAqrfy3007qERNpoc'),
        NgxStripeModule.forRoot('pk_live_51JNLEKC7qnCJJDECPzOxWmNEWgFZdWCs4uCeh3mPpqyZAe8J3huax0nYSLSbHSkun1AXMJucVMD99bmK7zhThqSg005daaOLmp'),

    ],
    providers: [
        { provide: LOCALE_ID, useValue: "es-MX" },

    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
