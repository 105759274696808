<div *ngIf="loader" class="loading-page">
    <div class="spinner"></div>
    <p>Espere por favor, estamos obteniendo los registros. El proceso puede tardar unos minutos.</p>
</div>

<div *ngIf="userLoader" class="loading-data">
    <div class="spinner"></div>
    <p>Cargando datos iniciales. Puede tomar un par de segundos.</p>
</div>
<mat-tab-group animationDuration="0ms" *ngIf="!userLoader">
    <mat-tab label="Tendencia" style="color: white !important;;">
        <!-- TAB PARA TENDENCIAS -->
        <section class="section-form">
            <div class="container-fluid mobile-container">
                <div class="col-md-10 col-xl-6" style="margin: 0 auto;">
                    <h2 class="title text-center">Tendencias</h2>
                    <h3>Indicador de tendencias</h3>
                    <p class="info">
                        Este indicador nos sirve para analizar la tendencia y momentum del precio, y así saber con altas
                        probabilidades cuándo podría comenzar a rebotar el precio o cuándo podría comenzar a caer, o
                        incluso si no existe una tendencia definida.
                    </p>
                    <form [formGroup]="form">
                        <div class="form-group row">
                            <div class="col-md-10 col-xl-6">
                                <label class="label mt-1">Ticker</label>
                                <input type="text" class="form-control" formControlName="ticker">
                            </div>
                            <div class="col-md-10 col-xl-6">
                                <label class="label mt-1">Temporalidad</label>
                                <select class="form-control" formControlName="frequency">
                                    <option value="1d">Por día</option>
                                    <option value="1wk">Por semana</option>
                                    <option value="1mo">Por mes</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <ng-container *ngFor="let item of errors">
                                <p class="error-text"><i>{{item.error}}</i></p>
                            </ng-container>

                        </div>
                        <div class="form-group text-center">
                            <div class="col-md-12" style="margin: 0 auto;">
                                <button class="btn btn-submit" (click)="searchTendencies()">Buscar
                                    tendencias</button>
                            </div>
                            <div class="col-md-12 current-ticker" *ngIf="hasTendenciesResult && !loader">
                                <p>Ticker actual: <span>{{currentTendTicker}}</span> - Temporalidad:
                                    <span *ngIf="temporalityFrontTendencies === '1d'">Por día</span>
                                    <span *ngIf="temporalityFrontTendencies === '1wk'">Por semana</span>
                                    <span *ngIf="temporalityFrontTendencies === '1mo'">Por mes</span>
                                </p>
                            </div>
                            <div class="col-12 charts-scroll-buttons" *ngIf="hasTendenciesResult && !loader">
                                <div class="row">
                                    <div class="col-12 col-md-6 btn-goto">
                                        <button mat-raised-button (click)="goToChart(1)">Gráfica de Tendencia</button>
                                    </div>
                                    <div class="col-12 col-md-6 btn-goto">
                                        <button mat-raised-button (click)="goToChart(2)">Gráfica de Momentum</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <section class="results">
            <div class="container-fluid p-5" *ngIf="!hasTendenciesResult && !loader">
                <h1 class="title-result text-center">Ingrese los datos para ver las tendencias</h1>
            </div>

            <div class="container-fluid p-5" *ngIf="hasTendenciesResult && !loader">
                <div class="row">
                    <div class="col-lg-12 table-div">
                        <table class="table table-hover text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Open</th>
                                    <th scope="col">High</th>
                                    <th scope="col">Low</th>
                                    <th scope="col">Close</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableTreding">
                                    <td>{{item.date | date:'MMM-dd-yy'}}</td>
                                    <td>{{item.open | number: '2.0-2'}}</td>
                                    <td>{{item.high | number: '2.0-2'}}</td>
                                    <td>{{item.low | number: '2.0-2'}}</td>
                                    <td>{{item.close | number: '2.0-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="how-to-read">
                        <h2>¿Cómo leer el indicador?</h2>
                        <img src="assets/images/tendency.png" alt="">
                        <p>
                            Para el indicador de Tendencia se deben de cumplir 2 condiciones que nos ayudarán a evitar
                            muchos falsos positivos. La primera condición es que la línea verde cruce a la línea
                            amarilla. Si la línea verde está arriba de la amarilla y la cruza quedando
                            ahora abajo de la amarilla, entonces es una señal alcista para la tendencia, siempre y
                            cuando la pendiente de la línea amarilla sea positiva. Y viceversa. Un cruce alcista debe de
                            ir acompañado de un triángulo negro. Y un cruce
                            bajista debe de ir acompañado de un triángulo rojo.
                        </p>
                        <br>
                        <p>
                            Con las dos condiciones anteriores ya existe una señal con altas probailidades. Si aparte de
                            esto miramos los marcadores o círculos amarillos y estos pasan de estar juntos a tener una
                            mayor separación, entonces el movimiento será fuerte. De lo contrario,
                            es decir, si los marcadores no se separan, entonces el movimiento será débil.
                        </p>
                        <br>

                        <p>
                            El indicador de momentum es un complemento al de Tendencia y también se puede usar de forma
                            aislada para algunas estrategias. El cambio de barras azules a rojas nos indican un momentum
                            negativo. Y viceversa. El decrecimiento de las barras azules indican
                            que la fuerza del movimiento alcista se está acabando. Y viceversa. El decrecimiento de las
                            barras rojas indican quela fuerza del movimiento bajista ya se está acabando. Si este
                            indicador coincide con el de Tendencia, entonces
                            la probabilidad de éxito es mucho mayor.

                        </p>
                    </div>
                    <div class="g-info row">
                        <div class="col-xl-9 mt-5" id="tendencies-chart">
                            <h2 class="title text-center">Indicador de Tendencia</h2>
                            <div class="col-md-12 current-ticker" *ngIf="hasTendenciesResult && !loader">
                                <p>Ticker actual: <span>{{currentTendTicker}}</span> - Temporalidad:
                                    <span *ngIf="temporalityFrontTendencies === '1d'">Por día</span>
                                    <span *ngIf="temporalityFrontTendencies === '1wk'">Por semana</span>
                                    <span *ngIf="temporalityFrontTendencies === '1mo'">Por mes</span>
                                </p>
                            </div>
                            <div class="image-download-button">
                                <button mat-raised-button color="primary" (click)="createImage(1)"
                                    *ngIf="!tendencyCreated">Generar imagen de gráfica de Tendencia</button>
                                <ng-container *ngIf="tendencyCreated">
                                    <a href="{{tendencyChartImg}}" download="grafica-tendencias.png">Descargar imagen
                                    </a>
                                    <span (click)="tendencyCreated = !tendencyCreated" class="cancel-img"> -
                                        Cancelar</span>
                                </ng-container>
                            </div>
                            <div class="grafics">
                                <img src="assets/images/logo-proceso.png" alt="">
                                <div echarts [options]="pointChartOption" theme="dark" class="demo-chart">
                                </div>
                            </div>


                        </div>
                        <div class="col-xl-3 info">

                            <p>
                                En la primera gráfica tenemos: el precio representado por la línea azul. La línea
                                amarilla es la tendencia y nos ayuda a ver su dirección. Los marcadores o círculos
                                amarillos nos ayudan a ver qué tan bien definida está la tendencia. Mientras más
                                separados,
                                más definida estará. Y viceversa. La línea verde punteada es el promedio móvil de 6
                                meses de la tendencia y nos ayuda a suavizar sus movimientos. Los triángulos azules son
                                indicadores de compra o acumulación. Los triángulos
                                rojos son indicadores de venta o cobertura.
                            </p>

                        </div>
                    </div>

                    <div class="g-info row">
                        <div class="col-xl-9 mt-5" id="momentum-chart">
                            <h2 class="title text-center">Momentum</h2>
                            <div class="col-md-12 current-ticker" *ngIf="hasTendenciesResult && !loader">
                                <p>Ticker actual: <span>{{currentTendTicker}}</span> - Temporalidad:
                                    <span *ngIf="temporalityFrontTendencies === '1d'">Por día</span>
                                    <span *ngIf="temporalityFrontTendencies === '1wk'">Por semana</span>
                                    <span *ngIf="temporalityFrontTendencies === '1mo'">Por mes</span>
                                </p>
                            </div>
                            <div class="image-download-button">
                                <button mat-raised-button color="primary" (click)="createImage(2)"
                                    *ngIf="!momentumCreated">Generar imagen de gráfica de Momentum</button>
                                <ng-container *ngIf="momentumCreated">
                                    <a href="{{momentumChartImg}}" download="grafica-momentum.png">Descargar imagen </a>
                                    <span (click)="momentumCreated = !momentumCreated" class="cancel-img"> -
                                        Cancelar</span>
                                </ng-container>
                            </div>
                            <div class="grafics">
                                <img src="assets/images/logo-proceso.png" alt="">
                                <div echarts [options]="barChartOption" theme="dark" class="demo-chart"></div>
                            </div>


                        </div>
                        <div class="col-xl-3 info">
                            <p class="card-content">
                                En la segunda gráfica tenemos: El momentum que está representado por barras azules y
                                rojas. Las barras azules indican momentum positivo y las rojas momentum negativo.
                                <span style="color: transparent;">Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Deleniti voluptates nemo facilis vel itaque mollitia esse libero eum
                                    dignissimos facere reiciendis quaerat commodi sit, quas nihil iusto rem at
                                    illum?</span>
                            </p>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    </mat-tab>

    <mat-tab label="Bayes">
        <!-- TAB PARA BAYES -->
        <section class="section-form">
            <div class="container-fluid p-5">
                <div class="col-md-6" style="margin: 0 auto;">
                    <h2 class="title text-center">Bayes</h2>
                    <form [formGroup]="form">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="label mt-1">Ticker 1</label>
                                <input type="text" class="form-control" formControlName="ticker">
                            </div>
                            <div class="col-md-6">
                                <label class="label mt-1">Ticker 2</label>
                                <input type="text" class="form-control" formControlName="ticker2">
                            </div>
                            <div class="col-md-6">
                                <label class="label mt-1">Temporalidad</label>
                                <select class="form-control" formControlName="frequency">
                                    <option value="1d">Por día</option>
                                    <option value="1wk">Por semana</option>
                                    <option value="1mo">Por mes</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <ng-container *ngFor="let item of errors">
                                <p class="error-text"><i>{{item.error}}</i></p>
                            </ng-container>

                        </div>
                        <div class="form-group text-center">
                            <div class="col-md-12" style="margin: 0 auto;">
                                <button class="btn btn-submit" (click)="searchBayes()">Calcular
                                    probabilidad</button>
                            </div>
                            <div class="row">
                                <div class="col-md-4 current-ticker" *ngIf="hasBayesResult && !loader">
                                    <p>Ticker 1 actual: <span>{{currentBayesTicker1}}</span></p>
                                </div>
                                <div class="col-md-4 current-ticker" *ngIf="hasBayesResult && !loader">
                                    <p>Ticker 2 actual: <span>{{currentBayesTicker2}}</span></p>
                                </div>
                                <div class="col-md-4 current-ticker" *ngIf="hasBayesResult && !loader">
                                    <p>
                                        Temporalidad:
                                        <span *ngIf="temporalityFrontBayes === '1d'">Por día</span>
                                        <span *ngIf="temporalityFrontBayes === '1wk'">Por semana</span>
                                        <span *ngIf="temporalityFrontBayes === '1mo'">Por mes</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <section class="results">
            <div class="container-fluid p-5" *ngIf="!hasBayesResult && !loader">
                <h1 class="title-result text-center">Ingrese los datos para ver los resultados</h1>
            </div>

            <div class="container-fluid " *ngIf="hasBayesResult && !loader">
                <div class="row">
                    <!-- <div class="col-md-12 table-div">
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-hover text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Close</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of tableTredingCompare.firstTicker">
                                            <td>{{item.date | date:'MMM-dd-yy'}}</td>
                                            <td>{{item.close}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <table class="table table-hover text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Close</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of tableTredingCompare.secondTicker">
                                            <td>{{item.date | date:'MMM-dd-yy'}}</td>
                                            <td>{{item.close}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div> -->
                    <div class="col-md-12 mt-5" id="bayes-chart">
                        <h2 class="title text-center">Gráfica de probabilidades</h2>
                        <div class="image-download-button">
                            <button mat-raised-button color="primary" (click)="createImage(3)"
                                *ngIf="!bayesCreated">Generar imagen de gráfica de Bayes</button>
                            <ng-container *ngIf="bayesCreated">
                                <a href="{{bayesChartImg}}" download="grafica-bayes.png">Descargar imagen </a>
                                <span (click)="bayesCreated = !bayesCreated" class="cancel-img"> - Cancelar</span>
                            </ng-container>
                        </div>
                        <div class="porcentaje-content">
                            <h2 class="porcentaje" *ngFor="let item of percentBarr">{{item.value}} %</h2>
                        </div>

                        <div echarts [options]="barChartCompareOption" theme="dark" class="demo-chart"></div>
                    </div>
                </div>
            </div>
        </section>
    </mat-tab>

    <mat-tab label="Predictivo">
        <!-- TAB PARA PREDICTIVE -->
        <section class="section-form">
            <div class="container-fluid p-5">
                <div class="col-md-10 col-xl-6" style="margin: 0 auto;">
                    <h2 class="title text-center">Predictivo</h2>
                    <p class="predictivo-info" style="text-align: justify;">
                        Este indicador nos sirve para tratar de pronosticar el movimiento de un instrumento para los
                        siguientes 5 días usando probabilidad y estadística. El área azul indica la probabilidad de alza
                        y a qué precio podría llegar. El área roja indica la probabilidad
                        de caída y a qué precio podría llegar.
                        <br><br> El precio promedio es simplemente el pronóstico promedio para los siguientes 5 días.
                        Todo lo anterior suponiendo que no aparezca un cisne realmente negro. Lo ideal aquí es vigilar
                        cuando las probabilidades sean superiores
                        al 65% para tener un mejor grado de confianza, ya sea al alza o a la baja.
                    </p>
                </div>
                <div class="col-md-10 col-xl-6" style="margin: 0 auto;">
                    <form [formGroup]="form">
                        <div class="form-group row">
                            <div class="col-12">
                                <label class="label mt-1">Ticker</label>
                                <input type="text" class="form-control" formControlName="ticker">
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <ng-container *ngFor="let item of errors">
                                <p class="error-text"><i>{{item.error}}</i></p>
                            </ng-container>

                        </div>
                        <div class="form-group text-center">
                            <div class="col-md-12" style="margin: 0 auto;">
                                <button class="btn btn-submit" (click)="searchPredictive()">Buscar
                                    predictivo</button>
                            </div>
                            <div class="col-md-12 current-ticker" *ngIf="hasPredictiveResult && !loader">
                                <p>Ticker actual: <span>{{currentTicker}}</span></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <section class="results">
            <div class="container-fluid p-5" *ngIf="!hasPredictiveResult && !loader">
                <h1 class="title-result text-center">Ingrese los datos para ver el predictivo</h1>
            </div>
            <!-- 
            Tabla
            <div class="container-fluid p-5" *ngIf="hasPredictiveResult && !loader">
                <div class="row">
                    <div class="col-md-12 table-div">
                        <table class="table table-hover text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">60, 40, 20 Días</th>
                                    <th scope="col">120, 90 Días</th>
                                    <th scope="col">90, 60 Días</th>
                                    <th scope="col">120, 90, 60 Días</th>
                                    <th scope="col">120, 90, 60, 40, 20 Días</th>
                                    <th scope="col">60, 40, 20, 10 Días</th>
                                    <th scope="col">60 Días</th>
                                    <th scope="col">10 Días</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableTendencyPredictive">
                                    <td>{{item.cell_1 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_2 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_3 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_4 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_5 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_6 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_7 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_8 |percent: '1.0-2' }}</td>
                                    <td>{{item.cell_9}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            -->
            <div class="col-md-12 mt-12 chart-container" id="predictive-chart"
                *ngIf="tableTendencyPredictive.length > 0">
                <h2 class="title text-center">Gráfica de probabilidades</h2>
                <div class="image-download-button">
                    <button mat-raised-button color="primary" (click)="createImage(4)"
                        *ngIf="!predictiveCreated">Generar imagen de gráfica de Predictivo</button>
                    <ng-container *ngIf="predictiveCreated">
                        <a href="{{predictiveChartImg}}" download="grafica-predictivo.png">Descargar imagen </a>
                        <span (click)="predictiveCreated = !predictiveCreated" class="cancel-img"> - Cancelar</span>
                    </ng-container>
                </div>
                <h4 class="prob-label">Probabilidad en 5 días</h4>
                <div class="grafics">
                    <img src="assets/images/logo-proceso.png" alt="">
                    <div echarts [options]="predictiveChartConfig" theme="dark" class="demo-chart"></div>
                    <!-- <div echarts [options]="predictiveChartExtendConfig" class="demo-chart | col-md-6"></div> -->
                </div>
            </div>
        </section>
    </mat-tab>
</mat-tab-group>