<section id="values-section">
    <h2 class="value-item-title" style="color:white">Valores</h2>
    <div class="container content">
        <div class="value-item">
            <div class="img">
                <img src="assets/images/about-us/value1.png" alt="mision" />
            </div>
            <div class="info">
                <h3 class="value-item-title">Calidad</h3>
                <p class="value-item-text">
                    Nuestro contenido y material, siempre tiene y tendrá un valor muy superior al precio. 
                    <br><br>
                    Nuestra misión
                    es que la educación financiera y económica, de alta calidad, sea accesible para todos.
                </p>
            </div>
        </div>
        <!-- ITEM -->
        <div class="value-item">
            <div class="img">
                <img src="assets/images/about-us/value2.png" alt="mision" />
            </div>
            <div class="info">
                <h3 class="value-item-title">Experiencia</h3>
                <p class="value-item-text">
                    Contamos con más de 20 años de experiencia en el mundo de las inversiones y la bolsa; más de 10
                    escribiendo en Rankia sobre ETFs, indexación pasiva, mercados, estrategias, economía, finanzas y
                    value investing; y más de 12 gestionando el fondo familiar.
                </p>
            </div>
        </div>
        <!-- ITEM -->
        <div class="value-item">
            <div class="img">
                <img src="assets/images/about-us/value3.png" alt="mision" />
            </div>
            <div class="info">
                <h3 class="value-item-title">Honestidad</h3>
                <p class="value-item-text">
                    No ofrecemos soluciones mágicas. <br>
                    No vendemos boletos de lotería ganadores. <br>
                    Uno de nuestros principales principios, es fomentar la habilidad, el pensamiento independiente, el
                    sentido crítico y el análisis.
                </p>
            </div>
        </div>
    </div>
</section>