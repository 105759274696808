import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'src/app/models/order';
import { CartService } from 'src/app/services/cart/cart.service';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { OrdersService } from 'src/app/services/orders/orders.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  public from: string;
  public orderId: string;
  public orderInformation: any;
  public loaderpage: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private checkoutService: CheckoutService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.loaderpage = true;
    this.from = this.route.snapshot.params['from'];
    this.orderId = this.route.snapshot.params['orderId'];
    this.getOrderInfomation();
  }

  async getOrderInfomation() {
    try {
      var res = await this.checkoutService.getOrderByPaymentIntent(this.orderId)
      console.log(res);
      
      this.loaderpage = false;
      this.orderInformation = res[0];

      if(this.from == 'cart') {
        this.cartService.EmptyCart();
        this.cartService.CartData = {}
        localStorage.removeItem('Carrito')
      }

    } catch (error: any) {
      this.loaderpage = false;
      // this.router.navigate(['/cursos'])
      console.log(error);
    }
  }


  // http://localhost:4200/confirmacion-compra/pi_3MHathC7qnCJJDEC0ukczBcZ/cart

}
