<div class="body-register">
    <div id="banner-register">
    </div>
    <div id="register-container">
        <h3>Iniciar Sesión</h3>
        <div class="custom-card">            
            <form [formGroup]="loginForm" id="form-container">
                <div class="form-control-container">
                    <label for="">Correo electrónico</label>
                    <input class="form-control" formControlName="email" placeholder="Correo electrónico" type="email">
                </div>
                <div class="form-control-container">
                    <label for="">Contraseña</label>
                    <input class="form-control" formControlName="password" placeholder="Contraseña" type="password">
                </div>
                <div style="margin-top: 5px;">
                    <a href="" routerLink="/recuperar-contraseña" style="color: white; text-decoration: none; margin-left: 5px; font-family: 'Amaranth', sans-serif; font-size: 16px;">Recuperar contraseña</a>
                </div>
                <div class="actions-container">
                    <button class="btn send-btn" (click)="submit()" [disabled]="!loginForm.valid">Enviar</button>
                </div>
            </form>
        </div>

    </div>    
</div>
