import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog/blog.service';
import { BlogComment, BlogPost } from 'src/app/models/blog';
import { DateTime } from 'luxon';
import { interval } from 'rxjs';
import { dataTool } from 'echarts';
//declare var $: any;

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {

  public popular: Array<BlogPost> = []
  public post: BlogPost;
  public cats: Array<string> = ['Inicio']
  public comments: Array<BlogComment> = [];
  public type: string = "";
  public interval;
  public counter: any;
  public n: number;
  public loader: boolean
  public blogId: string

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private router: Router

  ) { }

  async ngOnInit() {
    this.loader = true;
    let id = this.route.snapshot.paramMap.get('id') || '';
    this.blogId = this.route.snapshot.paramMap.get('id') || '';
    this.type = this.route.snapshot.paramMap.get('type') || '';
    await this.getPostById(id);
    await this.updateViews(id);
    this.interval = setInterval(this.checkPageFocus, 10000);
    this.loader = false;
    this.timer()
  }

  async addVisitCount(id: string): Promise<void> {
    try {
      let response = await this.blogService.addCountVisit(id);
      console.log(response);
    } catch (error) {
      console.log(error);
      throw error
    }
  }

  async ngOnDestroy(){
    clearInterval(this.interval);
    this.counter.unsubscribe()
    await this.viewTime();
  }

  private async updateViews(id) {
    // this.blogService.updateViewsCount(id);
    let lastEntryDate: DateTime;
    let date = new Date().toISOString();
    let actualEntryDate = DateTime.fromISO(date);
    let newEntryDate = actualEntryDate.plus({ minutes: 1 });
    try {
      let viewTime = localStorage.getItem(`${id}`);
      if (viewTime != null) {
        lastEntryDate = DateTime.fromISO(viewTime);

        if (actualEntryDate > lastEntryDate) {

          // await this.blogService.updateViewsCount(id);
          // localStorage.setItem(`${id}`, `${newEntryDate}`);

          let dateMinutes = actualEntryDate.diff(lastEntryDate, ["minutes"]).toObject();
          if (dateMinutes.minutes >= 15) {
            await this.blogService.updateViewsCount(id);
            localStorage.setItem(`${id}`, `${newEntryDate}`);
          }

        } else {

          let dateMinutes = lastEntryDate.diff(actualEntryDate, ["minutes"]).toObject();
          if (dateMinutes.minutes >= 15) {
            await this.blogService.updateViewsCount(id);
            localStorage.setItem(`${id}`, `${newEntryDate}`);
          }
        }
      } else {
        await this.blogService.updateViewsCount(id);
        localStorage.setItem(`${id}`, `${newEntryDate}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async checkPageFocus() {
    if (document.hasFocus()) {
      // console.log("Estoy viendo la página");
    } else {
      // console.log("No estoy viendo la página");

    }

  }

  private async getPostById(id: string) {
    try {
      let res: any
      if (this.type == 'analytics-posts') {
        res =await this.blogService.getAnalyticById(id)
      } else {
        res= await this.blogService.getById(id);
      }
      this.addVisitCount(id)
      this.post = res.data.post;
    } catch (err) {
      console.error(err.error.code)
      this.router.navigateByUrl('**');
    }
  }

  openYoutubeVideo(url: string) {
    let code = url.split('https://www.youtube.com/embed/')[1];
    let youtube = `https://www.youtube.com/watch?v=${code}`;
    return youtube;
  }

  isObjEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  timer(){
      
      this.counter = interval(1000).subscribe(res=>{
        this.n = res + 1
        // console.log(this.n);
      })
    
  }

  public async viewTime() {
    try {
      let id = this.route.snapshot.paramMap.get('id') || '';
      var res = await this.blogService.updateViewTime(id, this.n);

    } catch (error) {
      console.log(error);
      
    }
  }


}