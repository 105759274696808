import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { Card } from '../../models/user';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-card-dialog',
  templateUrl: './add-card-dialog.component.html',
  styleUrls: ['./add-card-dialog.component.scss']
})
export class AddCardDialogComponent implements OnInit {

  public payForm: FormGroup;
  public loader: boolean = false;
  public title: string = 'Nueva tarjeta';
  public cardMask = '0000-0000-0000-0000';
  public cardCVVMask = '000';
  public cardType = '';
  public userInformation!: any;

  constructor(public fb: FormBuilder,
    private userService: UserService,
    private auth: AuthService,
    public dialogRef: MatDialogRef<AddCardDialogComponent>,
  ) {
    this.payForm = this.fb.group({
      name: ['', Validators.required],
      card: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      cvv: ['', Validators.required],
    });
  }

  async ngOnInit() {
    // Get Stripe client ID
    let response = await this.auth.getUser();
    let userInformation = await this.userService.getUserData(response);
    this.userInformation = userInformation;
  }

  getBrand(event: any) {
    let cardType = this.userService.getBrand(event);
    if (cardType != null) {
      switch (cardType) {
        case 'visa':
          this.cardMask = '0000-0000-0000-0000';
          this.cardType = cardType;
          this.cardCVVMask = '000';

          break;
        case 'amex':
          this.cardMask = '0000-000000-00000';
          this.cardType = cardType;
          this.cardCVVMask = '0000';

          break;
        case 'mastercard':
          this.cardMask = '0000-0000-0000-0000';
          this.cardType = cardType;
          this.cardCVVMask = '000';
          break;
        default:
          break;
      }
    } else {
      this.cardMask = '0000-0000-0000-0000';
      this.cardType = '';
      this.cardCVVMask = '000';
    }
  }

  public async saveNewCard() {
    this.loader = true;
    this.title = 'Guardando...';
    try {
      let userId = await this.auth.getUser();
      var form = this.payForm.value;
      let card: Card = {
        number: form.card,
        name: form.name,
        exp_year: '20' + form.year,
        type: this.cardType,
        exp_month: form.month,
        cvc: form.cvv,
        lastDigits: form.card.substr(form.card.length - 4),
        created: new Date(),
        userId: userId,
      };

      await this.userService.validCart(card);

      var cardInfo = await this.userService.addNewCard(card, this.userInformation.stripeclient_id);

      this.dialogRef.close({ card: cardInfo?.data! });

    } catch (error) {
      this.loader = false;
      this.title = 'Nueva tarjeta';
      if (error == 'CARD') {
        Swal.fire({
          title: 'Tarjeta no valida',
          text: 'Favor de verificar los datos de tu tarjeta',
          icon: 'error',
          confirmButtonColor: '#d82d2a',
          customClass: {
            container: 'swal-question',
          },
        });
        return;
      }
      Swal.fire('Error', 'Ha ocurrido un error, intentalo más tarde ').then(
        () => this.dialogRef.close()
      );
    }
  }



}
