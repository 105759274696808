<div mat-dialog-title>
    <h1>{{title}}</h1>
    <button *ngIf="!loader" mat-button mat-dialog-close>&times;</button>
</div>

<div mat-dialog-content>
    <ng-container *ngIf="loader">
        <app-spinner></app-spinner>
    </ng-container>
    <div class="form" *ngIf="!loader">
        <form [formGroup]="payForm">
            <div class="form-group">
                <label for="name" class="col-form-label">TITULAR DE LA TARJETA</label>
                <div>
                    <input formControlName="name" type="text" class="form-control" id="namecard">
                </div>
            </div>
            <div class="form-group row">
                <div class="input-card">
                    <label for="card" class="col-form-label">NÚMERO DE TARJETA</label>
                    <div>
                        <input formControlName="card" [mask]="cardMask" type="text" class="form-control" id="card" (paste)="$event.preventDefault()" (keyup)="getBrand($any($event.target).value)">
                    </div>
                </div>
                <div class="card-type-img">
                    <img src="assets/images/card-types/credit-card.svg" alt="" *ngIf="cardType == ''">
                    <img src="assets/images/card-types/visa.svg" alt="" *ngIf="cardType == 'visa'">
                    <img src="assets/images/card-types/amex.svg" alt="" *ngIf="cardType == 'amex'">
                    <img src="assets/images/card-types/mc.png" alt="" *ngIf="cardType == 'mastercard'">
                </div>
            </div>
            <div class="form-group">
                <label for="date" class="col-form-label">FECHA DE EXPIRACIÓN</label>
                <div class="">
                    <input formControlName="month" mask="00" placeholder="MM" type="text" class="form-control"
                        id="month">
                </div>
                <div class="">
                    <input formControlName="year" placeholder="YY" mask="00" type="text" class="form-control" id="year">
                </div>
            </div>
            <div class="form-group">
                <label for="cvc" class="col-form-label">CÓDIGO DE SEGURIDAD</label>
                <div>
                    <input formControlName="cvv" [mask]="cardCVVMask" type="text" class="form-control" id="cvc">
                </div>
            </div>
        </form>
        <div class="btn">
            <button [disabled]="!payForm.valid" (click)="saveNewCard()">Guardar</button>
        </div>
    </div>
</div>