import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from 'src/app/services/contact/contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public nameCourse: any,
    private dialogRef: MatDialog,
    private contactService: ContactService,
  ) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$")]],
    });
  }

  ngOnInit(): void {
  }

  async sendMail(){
    try {
      let data = {
        name: this.contactForm.value.name,
        email: this.contactForm.value.email,
        nameCourse: this.nameCourse.nameCourse
      }
      Swal.fire('¡Gracias por el interes!','Se esta enviando su informacion...', 'info');
      Swal.showLoading();
      const answer = await this.contactService.sendMail(data);
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: '¡Excelente!',
        text: 'Se han enviado su información satisfactoriamente, nos pondremos en contacto con usted.',
        confirmButtonText: "Aceptar",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: "#e5514e",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.contactForm.reset();
          this.dialogRef.closeAll();
        }
      });
    } catch (error) {
      
    }

  }

}
