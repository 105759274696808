import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalSubscriptionComponent } from 'src/app/components/modal-subscription/modal-subscription.component';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BlogService } from 'src/app/services/blog/blog.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {
  filterPost = '';
  public typePost: string;
  public pageLoader: boolean = false;
  public posts: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private post_service: BlogService,
    private auth_service: AuthService,
    public dialog: MatDialog,
    private user_service: UserService
  ) { }

  ngOnInit(): void {
    this.pageLoader = true;
    // this.typePost = this.route.snapshot.params['type'];
    this.getPosts();
  }

  async getPosts() {
    try {
      this.posts = await this.post_service.getAllAnalyticPosts();

      this.pageLoader = false;
    } catch (error) {
      console.log(error);
      this.pageLoader = false;
    }
  }

  async goToPost(item, views, pay) {
    Swal.fire('Cargando...', '');
    Swal.showLoading();

    if (pay) {
      try {
        const user_id = await this.auth_service.getUser();
        const user_info: User = await this.user_service.getUserData(user_id);
        if (
          user_info.stripeclient_id != undefined ||
          user_info.stripeclient_id != ''
        ) {
          const subs = await this.user_service.getUserSubscriptions(
            user_info.stripeclient_id
          );

          if (subs.data.length == 0) {

            Swal.close();
            return this.dialog.open(ModalSubscriptionComponent, {
              width: '600px',
              height: 'auto',
              autoFocus: false,
              disableClose: true,
              hasBackdrop: false,
              data: {
                data: user_info,
                user_id: user_id,
                prod_id: 'prod_LIRZvH5Z6M23mi',
                subs: subs.data
              },
            });
          } else {
            var subAnalysis: Array<any> = subs.data
            if (subAnalysis.length == 0) {
              Swal.close();
              return this.dialog.open(ModalSubscriptionComponent, {
                width: '600px',
                height: 'auto',
                autoFocus: false,
                disableClose: true,
                hasBackdrop: false,
                data: {
                  data: user_info,
                  user_id: user_id,
                  prod_id: 'prod_LIRZvH5Z6M23mi',
                  subs: subs.data
                },
              });
            }

            let someActive = subAnalysis.some((sub) => sub.status == 'active' || sub.status == 'trialing');
            
            let currentDate = new Date().setHours(0, 0, 0, 0);
            let someCurrentPeriodActive = subAnalysis.some((sub) => sub.status == 'canceled' && sub.current_period_end * 1000 > currentDate);            

            if (someActive || someCurrentPeriodActive) {
              Swal.close();
              this.router.navigate([`blog-entrada/analytics-posts/${item}`]);
              return;
            }
            Swal.close();
            return Swal.fire(
              'Lo sentimos',
              'Actualmente no cuentas con una suscripción activa para acceder a este contenido',
              'info'
            );
          }
        }
        Swal.close();
      } catch (error) {
        return Swal.fire(
          'Lo sentimos',
          'Hubo un problema al obtener datos, intente de nuevo mas tade',
          'info'
        );
      }
    } else {
      Swal.close();
      this.router.navigate([`blog-entrada/analytics-posts/${item}`]);
    }
  }
}
