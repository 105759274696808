export const listOfCategories = [
    // {
    //     name: 'Broker Call Rate',
    //     value: 'broker-call-rate',
    // },
    {
        name: 'CPI',
        value: 'cpi',
    },
    // {
    //     name: 'T-Bills',
    //     value: 't-bills',
    // },
    {
        name: 'S&P 500 Total return',
        value: 's&p-500-total-return',
    },
    {
        name: 'Small Cap Stocks',
        value: 'small-cap-stocks',
    },
    {
        name: 'MSCI EAFE',
        value: 'msci-eafe',
    },
    {
        name: 'EEM',
        value: 'eem',
    },
    {
        name: 'US 10 YR',
        value: 'us-10-yr',
    },
    {
        name: 'US Corp Bond Return Index',
        value: 'us-corp-bond-return-index',
    },
    {
        name: 'GSCI',
        value: 'gsci',
    },
    {
        name: 'NAREIT',
        value: 'nareit',
    },
    {
        name: 'US 30 YR',
        value: 'us-30-yr',
    },
    {
        name: 'GOLD',
        value: 'gold',
    },
    {
        name: 'Foreign 10 YR',
        value: 'foreign-10-yr',
    },
    {
        name: 'Non-Durables',
        value: 'non-durables',
    },
    {
        name: 'Durables',
        value: 'durables',
    },
    {
        name: 'Manufacturing',
        value: 'manufacturing',
    },
    {
        name: 'Energy',
        value: 'energy',
    },
    {
        name: 'Tech',
        value: 'tech',
    },
    {
        name: 'Telecom',
        value: 'telecom',
    },
    {
        name: 'Shops',
        value: 'shops',
    },
    {
        name: 'Health',
        value: 'health',
    },
    {
        name: 'Utilities',
        value: 'utilities',
    },
    {
        name: 'OtherSectors',
        value: 'othersectors',
    },
    {
        name: 'REITS',
        value: 'reits',
    },
    {
        name: 'Small Cap Momentum',
        value: 'small-cap-momentum',
    },
    {
        name: 'Large Cap Momentum',
        value: 'large-cap-momentum',
    },
    {
        name: 'Bottom 10% Momentum',
        value: 'bottom-10%-momentum',
    },
    {
        name: 'Top 10% Momentum',
        value: 'top-10%-momentum',
    },
    {
        name: 'Bottom 20% value (B/P)',
        value: 'bottom-20%-value-(b/p)',
    },
    {
        name: 'Top 20% value (B/P)',
        value: 'top-20%-value-(b/p)',
    },
    {
        name: 'Small Value',
        value: 'small-value',
    },
    {
        name: 'Big Value',
        value: 'big-value',
    },
    {
        name: 'Small Size',
        value: 'small-size',
    },
    {
        name: 'Barclay Us Managed Futures Industry BTOP 50',
        value: 'barclay-us-managed-futures-industry-btop-50',
    },
    {
        name: 'AQR',
        value: 'aqr',
    },
    {
        name: 'TIPS Series',
        value: 'tips-series',
    },
    {
        name: 'Muni Index',
        value: 'muni-index',
    },
    {
        name: 'Timber',
        value: 'timber',
    },
    {
        name: 'International Small Cap Value (Global B/M Small Low)',
        value: 'international-small-cap-value-(global-b/m-small-low)',
    },
    {
        name: 'International Large Cap Value (Global B/M Big Low)',
        value: 'international-large-cap-value-(global-b/m-big-low)',
    },
    {
        name: 'International Small High Mom (Global mom Small High)',
        value: 'international-small-high-mom-(global-mom-small-high)',
    },
    {
        name: 'International Large High Mom (Global mom Small High)',
        value: 'international-large-high-mom-(global-mom-small-high)',
    },
    {
        name: 'Merrill High Yield',
        value: 'merrill-high-yield',
    },
    {
        name: 'World Stocks',
        value: 'world-stocks',
    },
    {
        name: 'World ex USA',
        value: 'world-ex-usa',
    },
    {
        name: 'BuyWrite',
        value: 'buywrite',
    },
    {
        name: 'PutWrite',
        value: 'putwrite',
    },
]



// startDate: ['', Validators.required],
// endDate: ['', Validators.required],
// 'broker-call-rate': ['', Validators.min(1)],
// 'cpi': ['', Validators.min(1)],
// 't-bills': ['', Validators.min(1)],
// 's&p-500-total-return': ['', Validators.min(1)],
// 'small-cap-stocks': ['', Validators.min(1)],
// 'msci-eafe': ['', Validators.min(1)],
// 'eem': ['', Validators.min(1)],
// 'us-10-yr': ['', Validators.min(1)],
// 'us-corp-bond-return-index': ['', Validators.min(1)],
// 'gsci': ['', Validators.min(1)],
// 'nareit': ['', Validators.min(1)],
// 'us-30-yr': ['', Validators.min(1)],
// 'gold': ['', Validators.min(1)],
// 'foreign-10-yr': ['', Validators.min(1)],
// 'non-durables': ['', Validators.min(1)],
// 'durables': ['', Validators.min(1)],
// 'manufacturing': ['', Validators.min(1)],
// 'energy': ['', Validators.min(1)],
// 'tech': ['', Validators.min(1)],
// 'telecom': ['', Validators.min(1)],
// 'shops': ['', Validators.min(1)],
// 'health': ['', Validators.min(1)],
// 'utilities': ['', Validators.min(1)],
// 'othersectors': ['', Validators.min(1)],
// 'reits': ['', Validators.min(1)],
// 'small-cap-momentum': ['', Validators.min(1)],
// 'large-cap-momentum': ['', Validators.min(1)],
// 'bottom-10%-momentum': ['', Validators.min(1)],
// 'top-10%-momentum': ['', Validators.min(1)],
// 'bottom-20%-value-(b/p)': ['', Validators.min(1)],
// 'top-20%-value-(b/p)': ['', Validators.min(1)],
// 'small-value': ['', Validators.min(1)],
// 'big-value': ['', Validators.min(1)],
// 'small-size': ['', Validators.min(1)],
// 'barclay-us-managed-futures-industry-btop-50': ['', Validators.min(1)],
// 'aqr': ['', Validators.min(1)],
// 'tips-series': ['', Validators.min(1)],
// 'muni-index': ['', Validators.min(1)],
// 'timber': ['', Validators.min(1)],
// 'international-small-cap-value-(global-b/m-small-low)': ['', Validators.min(1)],
// 'international-large-cap-value-(global-b/m-big-low)': ['', Validators.min(1)],
// 'international-small-high-mom-(global-mom-small-high)': ['', Validators.min(1)],
// 'international-large-high-mom-(global-mom-small-high)': ['', Validators.min(1)],
// 'merrill-high-yield': ['', Validators.min(1)],
// 'world-stocks': ['', Validators.min(1)],
// 'world-ex-usa': ['', Validators.min(1)],
// 'buywrite': ['', Validators.min(1)],
// 'putwrite': ['', Validators.min(1)],
// })