import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  private base_url: string = `${environment.URL_API}/coupons`;
  
  constructor(
    private http: HttpClient,
  ) { }

  public async validateCoupon(coupon: string, totalCart:number) {
    try {
      const response = await this.http.post<any>(`${this.base_url}/validate-coupon`, {couponId: coupon, cartTotal: totalCart}).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }
}

