import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface CustomHttp {
  code: number;
  message: string;
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class AssetPricesService {
  private baseUrl: string = `${environment.URL_API}/asset-prices`

  constructor(
    private http: HttpClient
  ) { }

  public async sendData(data: any) {
    try {
      var response = await this.http.post<CustomHttp>(`${this.baseUrl}/calculate`, { data }).toPromise();
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getAllCategories() {
    try {
      const response = await this.http.get<CustomHttp>(`${this.baseUrl}/get-all-categories`).toPromise();
      return response
    } catch (error) {
      console.log(error);
      throw error
    }
  }
}
