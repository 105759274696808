<section>
    <h1 class="title">Politicas de Privacidad y Contencion de datos</h1>
    <hr />
      <div class="polices">
          <div class="content">
              <p>
                  De conformidad con lo establecido en la legislación mexicana, el Titular
                  Gloria Diego Peralta (en adelante, “TITULAR”) de
                  https://procesoresultado.com (en adelante, “SITIO WEB”), se compromete a
                  adoptar las medidas necesarias que estén a su alcance para asegurar la
                  privacidad de los datos personales recabados de forma que se garantice
                  su seguridad, se evite su alteración, pérdida o tratamiento no
                  autorizado.
              </p>
              <h3>
                  Informamos a usted que, EL TITULAR tiene su domicilio ubicado en: <br />
                  15 sur número 512, San Pedro Cholula Puebla, CP 72764. <br />Con correo
                  electrónico:
                  <a href="mailto:proceso.resultado@gmail.com seubject text">proceso.resultado@gmail.com</a>
              </h3>
  
              <p>
                  Además, a efecto de dar cumplimiento a lo establecido en términos de lo
                  previsto en la Ley Federal de Protección de Datos Personales en Posesión
                  de los Particulares de la ley mexicana. Todo dato personal que sea
                  recabado a través de
                  <a href="https://procesoresultado.com">https://procesoresultado.com</a>
                  será tratado de conformidad con los principios de licitud, calidad,
                  finalidad, lealtad, y responsabilidad. Todo tratamiento de datos
                  personales quedará sujeto al consentimiento de su titular. En todo caso,
                  la utilización de datos financieros o patrimoniales, requerirán de
                  autorización expresa de sus titulares, no obstante, esta podrá darse a
                  través del propio SITIO WEB utilizando los mecanismos habilitados para
                  tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este
                  tipo de datos. Lo mismo ocurrirá en el caso de datos personales
                  sensibles, considerando por estos aquellos que debido a una utilización
                  indebida puedan dar origen a discriminación o su divulgación conlleve un
                  riesgo para el titular.
              </p>
              <br />
              <p>
                  En todo momento se procurará que los datos personales contenidos en las
                  bases de datos o archivos que en su caso se utilicen, sean pertinentes,
                  correctos y actualizados para los fines para los cuales fueron
                  recabados.
              </p>
              <br />
              <p>
                  <a href="https://procesoresultado.com/"
                  >https://procesoresultado.com/</a
                  >
                  podrá incluir hipervínculos o enlaces que permitan acceder a páginas web
                  de terceros distintos de EL TITULAR. Los titulares de dichos sitios web
                  dispondrán de sus propias políticas de privacidad y protección de datos,
                  por lo cual EL TITULAR no asume ningún tipo de responsabilidad por los
                  datos que san facilitados por el USUARIO a través de cualquier sitio web
                  distinto a
                  <a href="https://procesoresultado.com">https://procesoresultado.com</a>
              </p>
          </div>
      </div>
  
    <h1>CONDICIONES DE USO</h1>
    <hr />
      <div class="polices">
          <div class="content">
              <p>
                  Debes saber, para tu tranquilidad, que siempre te solicitaremos tu
                  consentimiento expreso para recabar tus datos con la correspondiente
                  finalidad especificada en cada caso, lo que implica que, en caso de
                  otorgar ese consentimiento, has leído y aceptado esta Política de
                  Privacidad.
              </p>
              <br />
              <p>
                  En el momento en que accedas y utilices esta web, asumes tu condición de
                  usuario con tus correspondientes derechos y obligaciones.
              </p>
  
              <p>
                  La web permitirá a personas de 18 años en adelante registrarse en el
                  sitio llenando sus datos requeridos en
                  <a href="https://procesoresultado.com"> https://procesoresultado.com</a>
                  sin el previo consentimiento de padres o tutores por lo que es
                  responsabilidad directa de la person.
              </p>
              <br />
              <p>
                  Si eres menor de 18 años, necesitarás el consentimiento del padre o
                  tutor para el tratamiento de tus datos personales.
              </p>
          </div>
      </div>
      
      <h1>REGISTRO Y FINALIDAD DE TUS DATOS</h1>
      <hr />
      <div class="polices">
          <div class="content">
              <p>
                  En función del formulario o sección a la que accedas, te solicitaremos 
                  exclusivamente los datos necesarios con las finalidades descritas a continuación. 
                  En todo momento, deberás dar tu consentimiento expreso, cuando te solicitemos 
                  información personal con las siguientes finalidades:
              </p>
              <br />
              <div>
                  <ul>
                      <li>Las finalidades concretas que particularmente se indiquen en cada una de 
                          las páginas o secciones donde aparezca el formulario de registro o contacto
                          electrónico.
                      </li>
                          <li>Con carácter general, para atender tus solicitudes, comentarios, consultas o
                              cualquier tipo de petición que realices como usuario a través de cualquiera 
                              de las formas de contacto que ponemos a tu disposición.
                          </li>
                          <li>Para informarte sobre consultas, peticiones, actividades, productos, 
                              novedades y/o servicios; vía e-mail, fax, Whatsapp, Skype, teléfono 
                              proporcionado, sms y mms.
                          </li>
                          <li>Para enviarte comunicaciones comerciales o publicitarias a través de 
                              cualquier otro medio electrónico o físico, que posibilite realizar 
                              comunicaciones.
                          </li>
                  </ul>
              </div>
          
              <p>
                  Estas comunicaciones, siempre serán relacionadas con nuestros productos, 
                  servicios, novedades o promociones, así como aquellos productos o servicios que 
                  podamos considerar de tu interés y que puedan ofrecer colaboradores, empresas 
                  o “partners” con los que mantengamos acuerdos de promoción o colaboración 
                  comercial.
              </p>
              <br />
              <p>
                  De ser así, te garantizamos que estos terceros nunca tendrán acceso a tus datos 
                  personales, con las excepciones reflejadas a continuación, siendo en todo caso 
                  estas comunicaciones realizadas por parte de EL TITULAR.
              </p>
              <br />
              <p>
                  En este caso, debes saber que únicamente proporcionamos y facilitamos los 
                  enlaces a las páginas y/o plataformas de estos terceros donde pueden adquirirse 
                  los productos que mostramos, en aras a facilitar la búsqueda y fácil adquisición de 
                  los mismos.
              </p>
              <br />
              <p>
                  Estas páginas enlazadas y pertenecientes a terceros no han sido revisadas ni son 
                  objeto de controles, ni recomendación por nuestra parte, por lo que en ningún 
                  caso EL TITTULAR será considerada responsable de los contenidos de estos 
                  sitios web, de las responsabilidades derivadas de su uso en todos los ámbitos, ni 
                  por las medidas que se adopten relativas a la privacidad del usuario, tratamiento 
                  de sus datos de carácter personal u otras que pudieran establecerse.
              </p>
              <br />
              <p>
                  Por todo ello, te recomendamos la lectura detenida y anticipada de todas las 
                  condiciones de uso, condiciones de compra, políticas de privacidad, avisos legales
                  y/o similares de estos sitios enlazados antes de proceder a la adquisición de estos
                  productos o uso de las webs.
              </p>
              
          </div>
      </div>
      <h1>EXACTITUD Y VERACIDAD DE LOS DATOS</h1>
      <hr />
      <div class="polices">
          <div class="content">
              <p>Como usuario, eres el único responsable de la veracidad y modificación de los 
                  datos que remitas a EL TITULAR, exonerándonos de cualquier responsabilidad al 
                  respecto.
              </p>
              <br />
              <p>
                  Es decir, a ti te corresponde garantizar y responder en cualquier caso de la 
                  exactitud, vigencia y autenticidad de los datos personales facilitados, y te 
                  comprometes a mantenerlos debidamente actualizados.
              </p>
              <br />
              <p>
                  De acuerdo a lo expresado en esta Política de Privacidad, aceptas proporcionar 
                  información completa y correcta en el formulario de contacto o suscripción.
              </p>
          </div>
      </div>
      <h1>BAJAS DE SUSCRIPCIÓN Y DERECHO DE REVOCACIÓN</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  Como titular de los datos que nos hayas facilitado, podrás ejercer en cualquier 
                  momento tus derechos de acceso, rectificación, cancelación y oposición, 
                  enviándonos un correo electrónico a <a href="proceso.resultado@gmail.com">proceso.resultado@gmail.com</a>   y adjuntándonos 
                  una fotocopia de tu documento de identidad como prueba válida.
              </p>
              <br />
              <p>
                  Igualmente, podrás darte de baja en cualquier momento para dejar de recibir 
                  nuestra newsletter o cualquier otra comunicación comercial, directamente desde el
                  mismo correo electrónico que hayas recibido o enviándonos un correo a <a href="proceso.resultado@gmail.com">proceso.resultado@gmail.com</a> 
              </p>
          </div>
      </div>
      <h1>ACCESO A DATOS POR CUENTA DE TERCEROS</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  Para poder prestar servicios estrictamente necesarios para el funcionamiento y 
                  desarrollo de las actividades de este sitio web, te informamos de que compartimos
                  datos con los siguientes prestadores de servicio bajo sus correspondientes 
                  condiciones de privacidad.
              </p>
              <br />
              <p>
                  Puedes tener la total tranquilidad de que estos terceros no podrán utilizar dicha 
                  información para ningún otro fin que no esté regulado específicamente en nuestras
                  relaciones con ellos en virtud de la normativa aplicable en materia de protección 
                  de datos de carácter personal.
              </p>
              <br />
              <p>
                  Este sitio web está alojado en <a href="https://sered.net/">https://sered.net/</a>, con marca comercial Sered 
                  Hosting Profesional, que proporciona los servicios de hosting para que puedas 
                  acceder y navegar por nuestro sitio. Puedes consultar la política de privacidad y 
                  demás aspectos legales de esta empresa en el siguiente enlace: <a href=" https://sered.net/politica-de-privacidad."> https://sered.net/politica-de-privacidad</a> 
              </p>
          </div>
      </div>
  
      <!-- Avisos Legales -->
      <h1 class="title">AVISO LEGAL</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  Este contrato describe los términos y condiciones generales (en adelante 
                  únicamente “TÉRMINOS Y CONDICIONES”) aplicables al uso de los contenidos, 
                  productos y servicios ofrecidos a través de este sitio.
              </p>
              <br>
              <p>
                  <a href="https://procesoresultado.com ">https://procesoresultado.com </a> (en adelante, “SITIO WEB”), del cual es titular Gloria 
                  Diego Peralta (en adelante, “TITULAR”). Cualquier persona que desee acceder o 
                  hacer uso del sitio o los servicios que en él se ofrecen, podrá hacerlo sujetándose 
                  a los presentes TERMINOS Y CONDICIONES, así como a políticas y principios 
                  incorporados al presente documento. En todo caso, cualquier persona que no 
                  acepte los presentes términos y condiciones, deberá abstenerse de utilizar el 
                  SITIO WEB y/o adquirir los productos y servicios que en su caso sean ofrecidos.
                  I. DEL OBJETO
                  El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la 
                  utilización del SITIO WEB, entendiendo por este cualquier tipo de contenido, 
                  producto o servicio que se encuentre a disposición del público en general dentro 
                  del dominio: https://procesoresultado.com
                  El TITULAR se reserva la facultad de modificar en cualquier momento y sin previo 
                  aviso, la presentación, los contenidos, la funcionalidad, los productos, los 
                  servicios, y la configuración que pudiera estar contenida en el SITIO WEB; en este
                  sentido, el USUARIO reconoce y acepta que EL TITULAR en cualquier momento 
                  podrá interrumpir, desactivar o cancelar cualquiera de los elementos que 
                  conforman el SITIO WEB o el acceso a los mismos.
                  El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y, por regla 
                  general es gratuito sin que el USUARIO tenga que proporcionar una 
                  contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la 
              </p>
          </div>
      </div>
      <h1>I. DEL OBJETO</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la 
                  utilización del SITIO WEB, entendiendo por este cualquier tipo de contenido, 
                  producto o servicio que se encuentre a disposición del público en general dentro 
                  del dominio: <a href="https://procesoresultado.com">https://procesoresultado.com</a>
              </p>
              <br>
              <p>
                  El TITULAR se reserva la facultad de modificar en cualquier momento y sin previo 
                  aviso, la presentación, los contenidos, la funcionalidad, los productos, los 
                  servicios, y la configuración que pudiera estar contenida en el SITIO WEB; en este
                  sentido, el USUARIO reconoce y acepta que EL TITULAR en cualquier momento 
                  podrá interrumpir, desactivar o cancelar cualquiera de los elementos que 
                  conforman el SITIO WEB o el acceso a los mismos.
              </p>
              <br>
              <p>
                  El acceso al SITIO WEB por parte del USUARIO tiene carácter libre y, por regla 
                  general es gratuito sin que el USUARIO tenga que proporcionar una 
                  contraprestación para poder disfrutar de ello, salvo en lo referente al costo de la 
                  conexión a internet suministrada por el proveedor de este tipo de servicios que 
                  hubiere contratado el mismo USUARIO.
              </p>
              <br>
              <p>
                  El acceso a los contenidos y servicios facilitados a través del SITIO WEB no 
                  requerirá de suscripción o registro alguno.
              </p>
              <br>
              <p>
                  EI SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con 
                  la mayoría de edad (mayores de 18 años); en este sentido, EL TITULAR declina 
                  cualquier responsabilidad por el incumplimiento de este requisito.
              </p>
              <br>
              <p>
                  Se hace del conocimiento del USUARIO que el TITULAR podrá administrar o 
                  gestionar el SITIO WEB de manera directa o a través de un tercero, lo cual no 
                  modifica en ningún sentido lo establecido en los presentes TÉRMINOS Y 
                  CONDICIONES.
              </p>
          </div>
      </div>
      <h1>II. DEL USUARIO</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  El acceso o utilización del SITIO WEB, así como de los recursos habilitados para 
                  interactuar entre los USUARIOS, o entre el USUARIO y el TITULAR tales como 
                  medios para realizar publicaciones o comentarios, confiere la condición de 
                  USUARIO del SITIO WEB, por lo que quedará sujeto a los presentes TÉRMINOS 
                  Y CONDICIONES, así como a sus ulteriores modificaciones, sin perjuicio de la 
                  aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el 
                  momento en el que se accede al SITIO WEB. Dada la relevancia de lo anterior, se 
                  recomienda al USUARIO revisar las actualizaciones que se realicen a los 
                  presentes TÉRMINOS Y CONDICIONES.
              </p>
              <br>
              <p>
                  Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo a la forma en 
                  la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier 
                  tipo de software que automatice la interacción o descarga de los contenidos o 
                  servicios proporcionados a través del SITIO WEB. Además, el USUARIO se 
                  compromete a utilizar la información, contenidos o servicios ofrecidos a través del 
                  SITIO WEB de manera lícita, sin contravenir lo dispuesto en los presentes 
                  TÉRMINOS Y CONDICIONES, la moral o el orden público, y se abstendrá de 
                  realizar cualquier acto que pueda suponer una afectación a los derechos de 
                  terceros, o perjudique de algún modo el funcionamiento del SITIO WEB.
              </p>
              <br>
              <p>EL TITULAR se reserva el derecho de retirar todos aquellos comentarios y 
                  aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean 
                  discriminatorios, atenten contra los derechos de tercero o el orden público, o bien, 
                  que a su juicio no resulten adecuados para su publicación.
              </p>
              <br>
              <p>
                  En cualquier caso, EL TITULAR no será responsable de las opiniones vertidas por 
                  los USUARIOS a través de comentarios o publicaciones que estos realicen.
              </p>
              <br>
              <p>
                  El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de 
                  relación entre el TITULAR y el USUARIO.
              </p>
              <br>
              <p>
                  Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con 
                  la mayoría de edad, el USUARIO manifiesta ser mayor de edad y disponer de la 
                  capacidad jurídica necesaria para sujetarse a los presentes TÉRMINOS Y 
                  CONDICIONES.
              </p>
          </div>
      </div>
      <h1>III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  EI TITULAR no garantiza de ningún modo la continuidad y disponibilidad de los 
                  contenidos, productos o servicios ofrecidos a través del SITIO WEB, no obstante, 
                  el TITULAR llevará a cabo las acciones que de acuerdo a sus posibilidades le 
                  permitan mantener el buen funcionamiento del SITO WEB, sin que esto suponga 
                  alguna responsabilidad de parte de EL TITULAR.
              </p>
              <br>
              <p>
                  De igual forma EL TITULAR no será responsable ni garantiza que el contenido o 
                  software al que pueda accederse a través del SITIO WEB, se encuentre libre de 
                  errores, software malicioso, o que pueda causar algún daño a nivel de software o 
                  hardware en el equipo a través del cual el USUARIO accede al SITIO WEB.
              </p>
              <br>
              <p>
                   EI TITULAR tampoco se hace responsable de los daños que pudiesen 
                  ocasionarse por un uso inadecuado del SITIO WEB. En ningún caso EL TITULAR 
                  será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan 
                  por el sólo acceso o utilización del SITIO WEB.
              </p>
          </div>
      </div>
      <h1>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  De conformidad con lo establecido en la legislación mexicana, el TITULAR se 
                  compromete a adoptar las medidas necesarias que estén a su alcance para 
                  asegurar la privacidad de los datos personales recabados de forma que se 
                  garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.
              </p>
              <br>
              <p>
                  Puede consultar el apartado de Política de Privacidad existente en el presente 
                  SITIO WEB para ver nuestra política de privacidad completa.
              </p>
          </div>
      </div>
      <h1>V. POLÍTICA DE ENLACES.</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>
                  EI SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros
                  sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo 
                  imágenes, videos, comentarios, motores de búsqueda, etc
              </p>
              <br>
              <p>La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto 
                  mejorar la experiencia del USUARIO al hacer uso del SITIO WEB, sin que pueda 
                  considerarse una sugerencia, recomendación o invitación para hacer uso de sitios 
                  externos. EL TITULAR en ningún caso revisará o controlará el contenido de los 
                  sitios externos, de igual forma, no hace propios los productos, servicios, 
                  contenidos, y cualquier otro material existente en los referidos sitios enlazados, 
                  por lo cual, tampoco se garantizará la disponibilidad, exactitud, veracidad, validez 
                  o legalidad de los sitios externos a los que se pueda tener acceso a través del 
                  daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos,
                  productos o servicios disponibles en los sitios web no gestionados porEL 
                  TITULAR a los que se pueda acceder mediante el SITIO WEB.
              </p>
          </div>
      </div>
      <h1>VI. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>EL TITULAR por sí o como parte cesionaria, es titular de todos los derechos de 
                  propiedad intelectual e industrial del SITIO WEB, entendiendo por este el código 
                  fuente que hace posible su funcionamiento, así como las imágenes, archivos de 
                  audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y
                  demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la 
                  legislación mexicana en materia de propiedad intelectual e industrial, así como por
                  los tratados internacionales aplicables. Por consiguiente, queda expresamente 
                  prohibida la reproducción, distribución, o difusión de los contenidos del SITIO 
                  WEB, con fines comerciales, en cualquier soporte y por cualquier medio, sin la 
                  autorización de EL TITULAR.
              </p>
              <br>
              <p>El USUARIO se compromete a respetar los derechos de propiedad intelectual e 
                  industrial del TITULAR. No obstante, además de poder visualizar los elementos 
                  del SITIO WEB podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea
                  exclusivamente para su uso estrictamente personal.
              </p>
              <br>
              <p>Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o manipular 
                  cualquier elemento, archivo, o contenido, del SITIO WEB, y por ningún motivo 
                  realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos 
                  que se encuentren protegidos, ya sea a través de un acceso restringido mediante 
                  un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, 
                  editarlos o manipularlos.
              </p>
              <br>
              <p>En caso de que el USUARIO o algún tercero consideren que cualquiera de los 
                  contenidos del SITIO WEB suponga una violación de los derechos de protección 
                  de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a EL 
                  TITULAR a través de los datos de contacto disponibles en el propio SITIO WEB 
                  y/o a través del siguiente medio:
              </p>
              <p>
                  Correo electrónico: <a href="proceso.resultado@gmail.com">proceso.resultado@gmail.com</a> 
              </p>
          </div>
      </div>
      <h1>VII. LEGISLACIÓN Y JURISDICCIÓN</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>EL TITULAR se reserva la facultad de presentar las acciones civiles o penales que
                  considere necesarias por la utilización indebida del SITIO WEB, sus contenidos, 
                  productos o servicios, o por el incumplimiento de los presentes TÉRMINOS Y 
                  CONDICIONES.
              </p>
              <br>
              <p>La relación entre el USUARIO y EL TITULAR se regirá por la legislación vigente 
                  en México, específicamente en Puebla. De surgir cualquier controversia en 
                  relación a la interpretación y/o a la aplicación de los presentes TERMINOS Y 
                  CONDICIONES, las partes se someterán a la jurisdicción ordinaria de los 
                  tribunales que correspondan conforme a derecho en el estado al que se hace 
                  referencia.
              </p>
          </div>
      </div>
      <h1>VIII. POLÍTICA DE COOKIES</h1>
      <hr/>
      <div class="polices">
          <div class="content">
              <p>De conformidad con lo dispuesto en la legislación vigente sobre el uso de cookies, 
                  el uso estas será tratado de conformidad con lo dispuesto en el apartado 
                  denominado Política de Cookies existente en el presente SITIO WEB y, que todo 
                  usuario debe leer, aceptar y/o limitar durante la navegación, según informa con 
                  carácter expreso el banner sobre cookies de nuestra web y la información 
                  adicional que se muestran sobre el uso de cookies en la misma. Pudiendo limitar 
                  el usuario la descarga de COOKIES desde las opciones del navegador.
              </p>
          </div>
      </div>
  </section>
  