<app-spinner *ngIf="isLoading"></app-spinner>
<mat-dialog-content *ngIf="!isLoading">
    <ng-container *ngFor="let course of courseList.courseList">
                <div class="content-container-main">
            <div class="content">
                <div class="content-course">
                    <h1>Contenido del curso</h1>
                </div>
                <div class="content-details">
                    <!-- <div class="no-content" *ngIf="courseMaterials?.length == 0">
                        <h1>No hay contenido para mostrar por el momento</h1>
                    </div> -->
                    <ng-container>
                        <!-- <div class="content-list">
                            <ul>
                                <li *ngFor="let material of courseMaterials" matTooltip="{{material.description}}"><mat-icon>done</mat-icon> {{material.title}}</li>
                            </ul>
                        </div> -->
                        <div class="content-list" [innerHTML]="course?.syllabus"></div>
                    </ng-container>
                    
                </div>
                <div class="content-course">
                    <h1>Descripción</h1>
                </div>
                <div class="description">
                    <div [innerHTML]="course?.description"></div>
                </div>
            </div>
            <div class="payment-data">
                <div class="payment-column">
                    <div class="course-img">
                        <img src="{{course?.coverImage}}" alt="">
                    </div>
                    <div class="course-info">
                        <h1 class="course-price">Precio: ${{course?.price}}.00 MXN</h1>
                        <div class="course-date">
                            <span class="course-date">Fecha: {{course?.date | date}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </ng-container>
    
</mat-dialog-content>

