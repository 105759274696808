<app-banner-discount></app-banner-discount>
<h2 mat-dialog-title>Información de curso</h2>
<mat-dialog-content>
    <div class="text-container">
        <p>
            Por el momento los cursos no estan disponibles, si desea que se te envie información del cursos cuando este activo por favor llena el siguiente formulario
        </p>
    </div>
    <form [formGroup]="contactForm">
        <div class="form-row">
            <div class="form-container">
                <mat-form-field appearance="outline" style="width:100%">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="" autocomplete="off" formControlName="name">
                    <mat-icon matSuffix> text_fields</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width:100%">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput placeholder="" autocomplete="off" formControlName="email">
                    <mat-icon matSuffix>alternate_email</mat-icon>
                </mat-form-field>
            </div>
            <div class="more-Info">
                <a href="https://api.whatsapp.com/send?phone=522215739036&text=Hola,%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20del%20curso..." target="blank">
                    ¿Más informacion del curso?
                </a>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial color="primary" (click)="sendMail()" [disabled]="contactForm.invalid">Enviar</button>
    <button mat-button mat-dialog-close color="warn">Cancelar</button>
</mat-dialog-actions>