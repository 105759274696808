// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDrnzZDQQTtrJeSuVurVrgdGIXDeUth868',
    authDomain: 'proceso-resultado.firebaseapp.com',
    projectId: 'proceso-resultado',
    storageBucket: 'proceso-resultado.appspot.com',
    messagingSenderId: '882269992668',
    appId: '1:882269992668:web:43df01ff9e283a8b812619',
  },
  // URL_API: 'http://localhost:5002/proceso-resultado/us-central1/api',
  URL_API: 'https://us-central1-proceso-resultado.cloudfunctions.net/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
