import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogsService {

  constructor(
    public afs: AngularFirestore
  ) { }


  async sendErrorLog(error: any, functionName: string) {
    try {
      let docError = {
        date: new Date(),
        function: functionName,
        where: 'proceso-resultado-front',
        error: error.toString()
      }
      await this.afs.collection("error-logs").doc().set(docError)
    } catch (error) {
      console.log(error);
      throw error
    }
  }
}
