<div class="card-container">
    <div class="col-12 no-padding">
        <div class="card">
            <div class="card-img-top" [ngStyle]="{'background-image': 'url('+img+')'}">
                <div class="card-badge" [ngClass]="title == 'Curso GRATIS'? 'card-badge-free':'card-badge-premium'">
                    <p>
                        {{title == 'Curso GRATIS' ? 'Gratuito':'Membresía'}}
                    </p>
                </div>
            </div>
            <div class="card-body">
                <h5 class="card-title">{{abbreviation != "" ? abbreviation:""|uppercase}} {{title|titlecase}} </h5>
                <!-- <span class="tooltiptext">{{title}}</span> -->
                <button class="card-btn btn" [routerLink]="['/curso-detalles/'+ id]"  *ngIf="title != 'Curso GRATIS'">
                    Detalles
                </button>
                <a class="card-btn btn" href="https://www.youtube.com/playlist?list=PLSPxAvbM2FSs_PqNALVhOftk4MGJJnvqd" target="_blank" *ngIf="title == 'Curso GRATIS'">
                    Ver
                    <mat-icon matSuffix aria-hidden="false" aria-label="Example home icon">open_in_new</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>