import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-buy-no-account-dialog',
  templateUrl: './buy-no-account-dialog.component.html',
  styleUrls: ['./buy-no-account-dialog.component.scss']
})
export class BuyNoAccountDialogComponent implements OnInit {
  public hide = true;
  registerForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<BuyNoAccountDialogComponent>
  ) {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
  }


  async validateAccount() {

    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    try {
      Swal.fire('Creando cuenta...', 'Espere por favor');
      Swal.showLoading();

      this.dialogRef.afterClosed()
      let formValue = this.registerForm.value;
      formValue.email = formValue.email.toLowerCase();
  
      var user_response = await this.authService.createSimpleAccount(formValue);
      let customerId = await this.authService.createStripeClient(formValue, user_response);
  
      let dialogResponse = {
        userCreated: true,
        userUid: user_response,
        customerId: customerId
      }
      this.dialogRef.close(dialogResponse);
      Swal.close();
    } catch (error) {
      if (error.error.message == "Email already exist") {
        Swal.fire('Oops...', 'El correo que ingresaste ya existe', 'error')
        this.router.navigate(['/registro']);
      }
      else {
        Swal.fire('Oops...', 'Ocurrió un error al realizar el registro, intente de nuevo', 'error');
      }
    }
  }
}
