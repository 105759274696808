import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InvestmentAge, selectedTool } from '../new-tool/models/form.model';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-instrument-portfolio',
  templateUrl: './instrument-portfolio.component.html',
  styleUrls: ['./instrument-portfolio.component.scss']
})
export class InstrumentPortfolioComponent implements OnInit {

  @Input() portfolioData: selectedTool[];
  @Input() comparisonResults: selectedTool[];

  public investmentInfo: FormGroup;

  public averageResults: InvestmentAge[] = [];
  public bestContributionResults = [];
  public worstContributionResults = [];

  public ageLength: number = 0;
  public inflationPercent: number = 0;
  public finalRate: number = 0
  public sumRates: number = 0;
  public sumMaxDD: number = 0;

  // Gráficas
  public averageChartOption: EChartsOption;
  public bestContributionChartOption: EChartsOption;
  public worstContributionChartOption: EChartsOption;

  constructor(
  ) { 
  }

  ngOnChanges() {
    if (this.portfolioData.length > 0) {
      console.log(this.portfolioData);
      console.log(this.comparisonResults);
      
      this.ageLength = this.portfolioData[0].retirementAge - this.portfolioData[0].initialAge;
      this.inflationPercent = (this.portfolioData[0].inflationPercentage / 100) + 1
      this.sumRates = this.portfolioData.reduce((total, el) => total + el.result, 0);
      this.sumRates = parseFloat(this.sumRates.toFixed(2));
      this.sumRates = 10.50;
      this.sumMaxDD = this.portfolioData.reduce((total, el) => total + el.maxDropDown, 0);
      this.finalRate = (this.sumRates / 100) + 1

      this.averageData()
      this.bestContributionData()
      this.worstContributionData()
    }
  }

  ngOnInit(): void {
  }


  averageData() {
    const bestContribution = this.annualizeContributions(this.portfolioData[0].bestContribution, this.portfolioData[0].temporality);
    const worstContribution = this.annualizeContributions(this.portfolioData[0].worstContribution, this.portfolioData[0].temporality);
    const initialInvestment = this.portfolioData[0].startingAmount;
    const rate = this.finalRate;
    

    const averageContribution = ((bestContribution + worstContribution) / 2);
    const inflationResult = this.inflationPercent * averageContribution;

    this.averageResults = [{
      age: this.portfolioData[0].initialAge,
      investment: initialInvestment,
      contribution: inflationResult
    }];

    for (let index = 0; index < this.ageLength; index++) {
      let contribution = this.averageResults[index].contribution * this.inflationPercent;
      let investment = (this.averageResults[index].investment * rate) + this.averageResults[index].contribution;

      contribution = this.roundNumber(contribution);
      investment = this.roundNumber(investment);

      const obj = {
        age: this.averageResults[index].age + 1,
        contribution,
        investment
      }
      this.averageResults.push(obj)
    }

    setTimeout(() => {
      this.createAverageGraphic(this.averageResults);
    });
  }

  bestContributionData() {
    const bestContribution = this.annualizeContributions(this.portfolioData[0].bestContribution, this.portfolioData[0].temporality);
    const initialInvestment = this.portfolioData[0].startingAmount;

    const rate = this.finalRate
    const inflationResult = this.inflationPercent * bestContribution;

    this.bestContributionResults = [{
      age: this.portfolioData[0].initialAge,
      investment: initialInvestment,
      contribution: inflationResult
    }];

    for (let index = 0; index < this.ageLength; index++) {
      let contribution: number = this.bestContributionResults[index].contribution * this.inflationPercent;
      
      let investment = (this.bestContributionResults[index].investment * rate) + this.bestContributionResults[index].contribution;

      contribution = this.roundNumber(contribution);
      investment = this.roundNumber(investment);

      const obj = {
        age: this.bestContributionResults[index].age + 1,
        contribution,
        investment
      }

      console.log('obj.contribution', obj.investment);
      

      this.bestContributionResults.push(obj)
    }

    setTimeout(() => {
      this.createBestContributionGraphic(this.bestContributionResults);
    });
  }

  worstContributionData() {
    const worstContribution = this.annualizeContributions(this.portfolioData[0].worstContribution, this.portfolioData[0].temporality);
    const initialInvestment = this.portfolioData[0].startingAmount;

    const rate = this.finalRate
    const inflationResult = this.inflationPercent * worstContribution;

    this.worstContributionResults = [{
      age: this.portfolioData[0].initialAge,
      investment: initialInvestment,
      contribution: inflationResult
    }];

    for (let index = 0; index < this.ageLength; index++) {
      let contribution = this.worstContributionResults[index].contribution * this.inflationPercent;
      let investment = (this.worstContributionResults[index].investment * rate) + this.worstContributionResults[index].contribution;

      contribution = this.roundNumber(contribution);
      investment = this.roundNumber(investment);

      const obj = {
        age: this.worstContributionResults[index].age + 1,
        contribution,
        investment
      }
      this.worstContributionResults.push(obj)
    }

    setTimeout(() => {
      this.createWorstContributionGraphic(this.worstContributionResults);
    });
  }

  createAverageGraphic(results: InvestmentAge[]) {
    const ageNumbers = results.map((result: InvestmentAge) => {
      return result.age
    })

    const investments = results.map((result: InvestmentAge) => {
      return result.investment
    })

    const contribution = results.map((result: InvestmentAge) => {
      return result.contribution
    })

    this.averageChartOption = {
      xAxis: {
        type: 'category',
        data: ageNumbers
      },
      yAxis: [
        {
          type: 'value',
          name: 'Inversión',
          min: 0,
          max: investments[investments.length -1].toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: 'Aportación',
          min: 0,
          max: (contribution[contribution.length -1] * 1.5).toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
      ],
      series: [
        {
          data: investments,
          type: 'bar',
          showBackground: true,
        },
        {
          data: contribution,
          type: 'bar',
          showBackground: true,
          yAxisIndex: 1
        },
      ],
    };
  }

  createBestContributionGraphic(results: InvestmentAge[]) {
    const ageNumbers = results.map((result: InvestmentAge) => {
      return result.age
    })

    const investments = results.map((result: InvestmentAge) => {
      return result.investment
    })

    const contribution = results.map((result: InvestmentAge) => {
      return result.contribution
    })

    this.bestContributionChartOption = {
      xAxis: {
        type: 'category',
        data: ageNumbers
      },
      yAxis: [
        {
          type: 'value',
          name: 'Inversión',
          min: 0,
          max: investments[investments.length -1].toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: 'Aportación',
          min: 0,
          max: (contribution[contribution.length -1] * 1.5).toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
      ],
      series: [
        {
          data: investments,
          type: 'bar',
          showBackground: true,
        },
        {
          data: contribution,
          type: 'bar',
          showBackground: true,
          yAxisIndex: 1
        },
      ]
    };
  }

  createWorstContributionGraphic(results: InvestmentAge[]) {
    const ageNumbers = results.map((result: InvestmentAge) => {
      return result.age
    })

    const investments = results.map((result: InvestmentAge) => {
      return result.investment
    })

    const contribution = results.map((result: InvestmentAge) => {
      return result.contribution
    })

    this.worstContributionChartOption = {
      xAxis: {
        type: 'category',
        data: ageNumbers
      },
      yAxis: [
        {
          type: 'value',
          name: 'Inversión',
          min: 0,
          max: investments[investments.length -1].toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: 'Aportación',
          min: 0,
          max: (contribution[contribution.length -1] * 1.5).toFixed(2),
          axisLabel: {
            formatter: '{value}'
          }
        },
      ],
      series: [
        {
          data: investments,
          type: 'bar',
          showBackground: true,
        },
        {
          data: contribution,
          type: 'bar',
          showBackground: true,
          yAxisIndex: 1
        },
      ]
    };
  }

  roundNumber(number: number) {
    number = Math.round(number * 100) / 100;
    return number
  }

  annualizeContributions(amount: number, temporality: string): number {
    switch (temporality) {
      case 'biweekly':
        return amount * 24;

      case 'monthly':
        return amount * 12;

      case 'quarterly':
        return amount * 4;

      case 'biannual':
        return amount * 2;

      default:
        return 0;
    }
  }

  getTemporality(temporality: string) {
    switch (temporality) {
      case 'biweekly':
        return 'Quincenal'

      case 'monthly':
        return 'Mensual'

      case 'quarterly':
        return 'Trimestral'

      case 'biannual':
        return 'Semestral'
    }
  }
}
