<section>
  <div class="col-12 main" id="desktop">
    <div class="row">
      <div class="col-6 image-left">
        <img src="../../../assets/icons/how-to.svg" alt="">
      </div>
      <div class="col-6 right-side">
        <div class="col-12 title">
          <h1>Experiencia Asegurada</h1>
          <span>Todo es con el fin de mejorar la toma de decisión bajo incertidumbre</span>
        </div>
        <div class="col-12 right-side-text">
          <p>Proceso Resultado es una Asociación Civil sin fines de lucro. La Asociación nace por la necesidad
            de brindar información, herramientas, consejos y educación financiera y económica de calidad,
            basada en investigación robusta y probada que ha funcionado. Esto, de manera sumamente
            accesible para todos y con la finalidad de mejorar la toma de decisiones, reduciendo la
            incertidumbre.</p>
        </div>
        <div class="col-12 numbers-right">
          <div class="row">
            <div class="col-6">
              <h1>+20</h1>
              <span>Años en los mercados financieros.</span>
            </div>
            <div class="col-6">
              <h1>+12</h1>
              <span>Años gestionando el fondo familiar.</span>
            </div>
            <div class="col-6">
              <h1>+10</h1>
              <span>Años escribiendo en Rankia.</span>
            </div>
            <div class="col-6">
              <h1>+500</h1>
              <span>Alumnos preparados y satisfechos.</span>
            </div>
            <div class="col-6">
              <h1>+60</h1>
              <span>Estudios e investigaciones realizadas para que las decisiones financieras y de inversiones estén
                respaldadas por datos y evidencia.</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 numbers-right">
          <div class="row">

            <div class="col-6">
              <h1>+20</h1>
              <span>Años en los mercados financieros</span>
            </div>
            <div class="col-6">
              <h1>+10</h1>
              <span>Años escribiendo en Rankia</span>
            </div>

          </div>
        </div> -->
      </div>
    </div>
  </div>

  <div class="col-12 main" id="movil">
    <div class="row">
      <div class="col-12 right-side">
        <div class="col-12 title">
          <h1>Experiencia Asegurada</h1>
          <span>Lorem ipsum dolor sit amet, consetetur sadipscing</span>
        </div>
        <div class="col-12 right-side-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          </p>
          <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
            amet, consetetur sadipscing elitr, sed diam nonumy.</p>
        </div>
        <div class="col-12 image-left">
          <img src="../../../assets/icons/how-to.svg" alt="">
        </div>
        <div class="col-12 numbers-right">
          <div class="row">
            <div class="col-6 mobile-number">
              <h1>+20</h1>
              <span>Años en los mercados financieros.</span>
            </div>
            <div class="col-6 mobile-number">
              <h1>+12</h1>
              <span>Años gestionando el fondo familiar.</span>
            </div>
            <div class="col-6 mobile-number">
              <h1>+10</h1>
              <span>Años escribiendo en Rankia.</span>
            </div>
            <div class="col-6 mobile-number">
              <h1>+500</h1>
              <span>Alumnos preparados y satisfechos.</span>
            </div>
            <div class="col-6 mobile-number">
              <h1>+60</h1>
              <span>Estudios e investigaciones realizadas para que las decisiones financieras y de inversiones estén
                respaldadas por datos y evidencia.</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 numbers-right">
          <div class="row">

            <div class="col-6 mobile-number">
              <h1>+12</h1>
              <span>Lorem ipsum dolor</span>
            </div>
            <div class="col-6 mobile-number">
              <h1>+35</h1>
              <span>Lorem ipsum dolor</span>
            </div>

          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>