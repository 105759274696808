<div id="calendar-card">
  <div id="calendar">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>
  <div id="events">
    <h1 *ngIf="filterEvents.length > 0">Eventos de {{ titleDate | date: "EEEE, MMMM d" }}</h1>
    <h1 *ngIf="filterEvents.length == 0">No hay eventos disponibles para el día seleccionado <br> {{ titleDate | date: "EEEE, MMMM d" }}</h1>
    <ul>
      <ng-container *ngFor="let event of filterEvents">
        <li>
          <p>{{ event.title }}</p>
          <p>
            {{ event.start }} -
            {{ event.end }}
          </p>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
