import { Component, OnInit } from '@angular/core';
import { ScreenerTable } from 'src/app/models/screeners';
import { ScreenersService } from 'src/app/services/screeners/screeners.service';

@Component({
  selector: 'app-screeners',
  templateUrl: './screeners.component.html',
  styleUrls: ['./screeners.component.scss']
})
export class ScreenersComponent implements OnInit {

  public chips: ScreenerTable;

  public showTable: boolean = false;
  public selectedChip;
  public selectedChipIndex: number = 0;
  public loader: boolean = false;
  public emptyScreeners: boolean = false;

  constructor(private screenerService: ScreenersService) { }

  async ngOnInit() {
    this.loader = true;
    try {
      const screeners = await this.screenerService.getScreeners();
      this.chips = screeners;
      this.selectedChip = screeners[0];
      this.loader = false;
      this.emptyScreeners = false;
    } catch (error) {
      console.log(error);
      if (error.error.code == 404) {
        this.emptyScreeners = true;
      }
      this.loader = false;
    }
  }

  setActive(chip, index) {
    this.selectedChip = chip;
    this.selectedChipIndex = index;
  }

}
