<div class="search-bar">
  <div class="input">
    <mat-icon>search</mat-icon>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingrese la búsqueda"
    />
  </div>
</div>
