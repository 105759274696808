<!-- course section start -->
<section class="section-b-space">
    <div class="banner-section">
        <img src="assets/images/banner-course.jpg" alt="course" />
        <div class="content">
            <!-- <h1>
                Cursos <br> recomendados para ti
            </h1> -->
            <div class="custom-card">
                <div class="left">
                    <h2>
                        Cursos <br> recomendados para ti
                    </h2>
                </div>
                <div class="right">
                    <img src="assets/images/students.png" class="books" alt="course-books" />
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-section mt-5">
        <h1>Cursos de la categoría:
            <strong *ngIf="category == '1'">Principiantes</strong>
            <strong *ngIf="category == '2'">Intermedio</strong>
            <strong *ngIf="category == '3'">Especializados</strong>
        </h1>
        <p>Estos son los cursos que recomendamos especialmente para ti</p>
    </div>
    <div class="categories-section">
        <div class="content-tab">
            <div class="cards-container">
              <ng-container *ngIf="loading;else show">
                <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
              </ng-container>
              <ng-template #show>
                <ng-container *ngFor="let item of courses">
                    <app-card-course class="col-4" [title]="item.name"
                        [img]="item.coverImage" [id]="item.id" [abbreviation]="item.abbreviation">
                    </app-card-course>
                </ng-container>
              </ng-template>
            </div>
        </div>
    </div>
</section>
<!-- course section end -->