import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-verfied',
  templateUrl: './email-verfied.component.html',
  styleUrls: ['./email-verfied.component.scss'],
})
export class EmailVerfiedComponent implements OnInit {

  constructor(
    ) { }
    
    ngOnInit(): void {
      Swal.fire({
        icon: 'warning',
        title: 'Su cuenta esta inactiva',
        text: 'Complete el registro para poder continuar.',
        confirmButtonText: "Aceptar",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: "#e5514e",
      })
    }

}
