<section>
    <mat-dialog-content>
        <button class="btn-close" mat-dialog-close>
            <span>X</span>
        </button>
        
        <div class="heading">
            <h1>Ingresa tu correo electrónico para continuar</h1>
            <p>Necesitamos la creación de una cuenta para enviarte la confirmación sobre tu compra.</p>
        </div>
    
        <div class="body">
            <form [formGroup]="registerForm">
                <div class="input-wrapper">
                    <mat-form-field appearance="outline">
                        <mat-label for="">Correo electrónico</mat-label>
                        <input matInput type="text" formControlName="email">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="registerForm.controls['email'].invalid && registerForm.controls['email'].touched">Ingrese un correo electrónico válido</mat-error>
                <div class="input-wrapper">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label for="">Contraseña (mínimo 6 caracteres)</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" autocomplete="off" formControlName="password">
                        </mat-form-field>
                        <mat-error *ngIf="registerForm.controls['password'].invalid && registerForm.controls['password'].touched">Ingrese al menos 6 caracteres</mat-error>
                    </div>
                    <button mat-flat-button (click)="hide = !hide" [attr.aria-label]="'Ocultar contraseña'"
                        [attr.aria-pressed]="hide">
                        <span>{{hide ? 'Mostrar' : 'Ocultar'}}</span>
                    </button>
                </div>
            </form>
        </div>
    
        <div class="footer">
            <button class="btn-buy" (click)="validateAccount()">Continuar</button>
            <button class="btn-login" [routerLink]="['inicio-sesion']" mat-dialog-close>Ya tengo una cuenta</button>
        </div>
    </mat-dialog-content>
</section>