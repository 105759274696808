<section class="main-section">

    <div class="btn-pdf-wrapper" *ngIf="tableResults.length > 0">
        <button mat-raised-button (click)="downloadPDF()">Generar pdf</button>
    </div>

    <div class="heading container">
        <h1>Portafolio porcentaje de interés</h1>
        <p>Herramienta que cálcula una aproximación en los resultados de tu inversión basados en un escenario promedio
            de rendimiento, con gráficas de proyección de como tu inversión puede crecer a lo largo del tiempo, lo que
            permite visualizar el potencial de tu inversión a largo plazo.</p>
        <p>Ingresa la información básica sobre tu inversión y selecciona una lista de instrumentos para realizar un
            cálculo aproximado del potecial de tu inversión.</p>
    </div>

    <form class="form container" [formGroup]="dataForm">
        <div class="container">
            <div class="fields-wrapper">
                <div class="heading">
                    <h2>Información</h2>
                </div>
                <div class="content-wrapper">
                    <div class="row">
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Fecha inicial</mat-label>
                                <input matInput [matDatepicker]="startDatePicker" [matDatepickerFilter]="dateFilter"
                                    formControlName="startDate" [max]="minDate">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker startView="multi-year" #startDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Fecha final</mat-label>
                                <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                                    [max]="maxDate" [matDatepickerFilter]="dateFilter">
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Edad actual</mat-label>
                                <input matInput type="number" formControlName="initialAge"
                                    oninput="(value=value.replace(/\D+/g, ''))">
                            </mat-form-field>
                        </div>
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Edad de retiro</mat-label>
                                <input matInput type="number" formControlName="retirementAge"
                                    oninput="(value=value.replace(/\D+/g, ''))">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Temporalidad</mat-label>
                                <mat-select formControlName="temporality">
                                    <mat-option value="biweekly">Quincenal</mat-option>
                                    <mat-option value="monthly">Mensual</mat-option>
                                    <mat-option value="quarterly">Trimestral</mat-option>
                                    <mat-option value="biannual">Semestral</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Monto inicial</mat-label>
                                <input matInput formControlName="startingAmount" prefix="$" mask="separator.2"
                                    thousandSeparator="," decimalSeparator=".">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Aportación (mejor escenario)</mat-label>
                                <input matInput formControlName="bestContribution" prefix="$" mask="separator.2"
                                    thousandSeparator="," decimalSeparator=".">
                            </mat-form-field>
                        </div>
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Aportación (peor escenario)</mat-label>
                                <input matInput formControlName="worstContribution" prefix="$" mask="separator.2"
                                    thousandSeparator="," decimalSeparator=".">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Inflación</mat-label>
                                <mat-select formControlName="inflationPercentage"
                                    (selectionChange)="validateInflation($event)">
                                    <mat-option value="t-bills">T-bills</mat-option>
                                    <mat-option value="custom">Personalizada</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="input-container" *ngIf="dataForm.controls['inflationPercentage'].value == 'custom'">
                            <mat-form-field appearance="outline">
                                <mat-label>Porcentaje personalizado</mat-label>
                                <input matInput formControlName="customInflationPercentage" suffix="%"
                                    mask="separator.2">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <h3>Instrumentos</h3>
                        <div class="btn-wrapper">
                            <button (click)="addNewField()" color="primary" mat-raised-button>Añadir</button>
                        </div>
                    </div>

                    <div formArrayName="categories">
                        <div *ngFor="let category of categoriesForm.controls; let i=index" [formGroupName]="i">
                            <div class="row dynamic-block">
                                <div class="input-container">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Seleccionar</mat-label>
                                        <mat-select formControlName="category">
                                            <mat-option *ngFor="let item of categoriesList" [value]="item">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Comparativa</mat-label>
                                        <mat-select formControlName="categoryToCompare">
                                            <mat-option *ngFor="let item of refRatesList" [value]="item">
                                                {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Porcentaje</mat-label>
                                        <input matInput formControlName="percentage" suffix="%" mask="separator.2"
                                            thousandSeparator="." decimalSeparator=",">
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <button mat-raised-button color="warn" (click)="deleteField(i)">Eliminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <button mat-raised-button color="primary" (click)="sendData()" [disabled]="isLoading">
                                <span *ngIf="isLoading">Cargando...</span>
                                <span *ngIf="!isLoading">Enviar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="container table-container" *ngIf="tableResults.length > 0">
        <table class="table table-hover text-center">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Categoría</th>
                    <th scope="col">Porcentaje calculado</th>
                    <th scope="col">Porcentaje elegido</th>
                    <th scope="col">Porcentaje calculado X elegido</th>
                    <th scope="col">Desv. Estd.</th>
                    <th scope="col">Sharpe</th>
                    <th scope="col">Desv. Estd. negativa</th>
                    <!-- <th scope="col">Max. DD</th> -->
                    <th scope="col">Sortino</th>
                    <th scope="col">Meses Positivos</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of tableResults; let i = index;">
                    <tr>
                        <td>{{item.category ? item.category: "-"}}</td>
                        <td>{{item.historicalPer ? item.historicalPer + "%" : "-"}}</td>
                        <td>{{item.percentage ? item.percentage + "%" : "-"}}</td>
                        <td>{{item.result ? item.result + "%" : "-"}}</td>
                        <td>{{item.standarDeviation ? item.standarDeviation + "%" : "-"}}</td>
                        <td>{{item.sharpe ? item.sharpe + "%" : "-"}}</td>
                        <td>{{item.standarDeviationDown ? item.standarDeviationDown + "%" : "-"}}</td>
                        <!-- <td>{{item.maxDropDown.toFixed(2) || "-"}}</td> -->
                        <td>{{item.sortino ? item.sortino + "%" : "-"}}</td>
                        <td>{{item.positiveMonths ? item.positiveMonths + "%" : "-"}}</td>
                    </tr>
                    <tr>
                        <td>{{comparisonResults[i].category}}</td>
                        <td>{{comparisonResults[i].historicalPer}}%</td>
                        <td>{{comparisonResults[i].percentage}}%</td>
                        <td>{{comparisonResults[i].result}}%</td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <td> - </td>
                        <!-- <td> - </td> -->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div id="pdf-content">
        <div class="custom-container header-pdf" *ngIf="isGeneratingPdf == true">
            <div class="top">
                <div class="logo-wrapper">
                    <img src="assets/images/logo-proceso.png" alt="">
                </div>

                <div class="date">
                    <p><b>{{today | date}}</b></p>
                </div>
            </div>

            <div class="header-pdf-body">
                <h2><b>Porcentaje de interés</b></h2>
                <h3>Aproximación en los resultados de tu inversión basados en un escenario promedio de rendimiento,
                    con gráficas de proyección de como tu inversión puede crecer a lo largo del tiempo, lo que
                    permite
                    visualizar el potencial de tu inversión a largo plazo.</h3>
            </div>
            
            <div class="header-user-info">
                <h3><b>Nombre:</b> {{userInformation?.name + ' ' + userInformation?.lastname}}</h3>
                <h3><b>Pase:</b> {{ this.subscriptionName || '-' | titlecase}}</h3>
                <h3><b>Edad inicial:</b> {{tableResults[0]?.initialAge}} años</h3>
                <h3><b>Edad de retiro:</b> {{tableResults[0]?.retirementAge}} años</h3>
                <h3><b>Temporalidad:</b> {{getTemporality(tableResults[0]?.temporality)}}</h3>
                <h3><b>Aportación inicial:</b> {{tableResults[0]?.startingAmount | currency: 'MXN'}}</h3>
                <h3><b>Mejor aportación:</b> {{tableResults[0]?.bestContribution | currency: 'MXN'}}</h3>
                <h3><b>Menor aportación:</b> {{tableResults[0]?.worstContribution | currency: 'MXN'}}</h3>
            </div>
            
            <hr>
        </div>

        <div>
            <div *ngFor="let charOption of charOptions; let i = index" class="container graphic-container">
                <echarts echarts theme="dark" [initOpts]="initOpts" [options]="charOptions[i]"
                    *ngIf="charOptions.length > 0" class="echarts">
                </echarts>
            </div>
        </div>

        <section class="portfolio-section">
            <app-instrument-portfolio-pdf [portfolioData]="tableResults" *ngIf="isGeneratingPdf == true"
                [comparisonResults]="comparisonResults"></app-instrument-portfolio-pdf>
        </section>
    </div>

    <app-instrument-portfolio [portfolioData]="tableResults"
        [comparisonResults]="comparisonResults"></app-instrument-portfolio>

    <div class="btn-pdf-wrapper" *ngIf="tableResults.length > 0">
        <button mat-raised-button (click)="downloadPDF()">Generar pdf</button>
    </div>
</section>