import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  public passwordForm: FormGroup
  public loader: boolean = false;
  public currentPassword: string = '';
  public userId: string = '';
  public hide1 = true;
  public hide2 = true;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    private userService: UserService,
  ) { 
    this.passwordForm = new FormGroup({
      email:           new FormControl('',[]),
      password:     new FormControl('',[Validators.required, Validators.minLength(6)]),
    });
  }

  async ngOnInit() {
    let response = await this.authService.isLoggedIn();
    let userInformation = await this.userService.getUserData(response.uid);
    this.userId = response.uid
    this.passwordForm.patchValue({email: userInformation.email})
    
  }

  async updatePassword() {
    let userInformation = this.passwordForm.value

    if(this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    try {
      Swal.fire('Cargando...', 'Enviando información');
      Swal.showLoading();

      let response = await this.userService.updatePassword(userInformation, this.userId);

      if (response.code == 404) {
        Swal.close();
        Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
      } else {
        this.shootSimpleAlert('success', '¡Su contraseña fue cambiada correctamente! Por favor, inicie sesión nuevamente')
        this.dialogRef.close();
        this.authService.logoutUser();
      }


    } catch (error) {
      Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
    }
  }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
    });
  }
}
