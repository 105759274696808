<!-- course section start -->
<section>
    <div class="promotions">
        <p>
            En la compra de 3 cursos obtienes el &nbsp;<strong> 15%</strong>
        </p>
    </div>
    <div class="banner-section">
        <div class="content">
            <div>
                <h1>
                    Cursos <p class="title-proces"> Proceso Resultado </p>
                </h1>
            </div>
            <div class="custom-card">
                <div class="left">
                    <h2>
                        Sueña a lo grande
                    </h2>
                    <p>
                        Los cursos van dirigidos a todas las personas desde las que inician con muy poco o nulo
                        conocimiento hasta las que tienen grandes conocimientos al respecto.
                    </p>
                    <!-- <button class="custom-button btn" (click)="showQuestionnaire()">
                        ¡Empieza ya!
                    </button> -->
                </div>
                <div class="right">
                    <img src="assets/images/students.png" class="books" alt="course-books" />
                </div>
            </div>
        </div>
    </div>

    <div class="section">
        <h2>Beneficios de Usuarios</h2>

        <div class="body__page">
            <owl-carousel-o [options]="customOptions" class="course-slider">
                <ng-container *ngFor="let slide of slidesStore">
                    <ng-template carouselSlide [id]="slide.id">
                        <!-- <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title" class="home"> -->
                        <div class="container__card">

                            <div class="card">
                                <div class="info__description">
                                    
                                    <img class="card-img" [src]=slide.img alt="" class="card-image">
                                    <h3>{{slide.title}}</h3>                                    
                                    <p>
                                        <!-- ? this span allows the CSS float property work-->
                                        <!-- <span style="color: rgba(0, 0, 0, 0);">.</span> -->
                                        
                                        
                                        {{slide.text}}
                                        <!-- {{slide.text}} -->
                                    </p>
                                </div>
                            </div>
                            <!-- 
                            <div class="card c2">
                                <div class="info__description">
                                    <p>
                                        Los cursos van dirigidos a todas las personas desde las que inician con muy poco
                                        o nulo conocimiento hasta las que tienen grandes conocimientos al respecto,
                                        todos ellos son accesibles y flexibles, es decir procuramos que tanto los
                                        precios como los horarios de los mismos sean cómodos para las personas que lo
                                        toman.
                                    </p>
                                </div>
                            </div>

                            <div class="card c3">
                                <div class="info__description">
                                    <p>
                                        Es por ello que todos nuestros cursos son impartidos en un horario cómodo para
                                        aquellos que deseen visualizarlos de forma en línea y en vivo; de igual manera
                                        todos son grabados y posteriormente compartidos a través de sus correos
                                        electrónicos a todos nuestros alumnos para aquellos que no puedan tomarlo en
                                        vivo o en su caso los que deseen ir a su propio ritmo.
                                    </p>
                                </div>
                            </div>

                            <div class="card c4">
                                <div class="info__description">
                                    <p>
                                        Todos nuestros cursos cuentan con una clase de dudas con la finalidad de
                                        finalizar de manera correcta y con los temas lo más claro posible. Contamos de
                                        igual manera con Asesorías Personalizadas que permiten a las personas crear,
                                        revisar, corregir un portafolio más a fin a la persona en particular enfocada a
                                        sus necesidades y su visión propia, esta asesoría cuenta con una duración de una
                                        hora aproximadamente
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </ng-template>
                </ng-container>

            </owl-carousel-o>
        </div>
    </div>


    <!-- <div class="courses-text">
        Parte importante de los valores de esta asociación civil es crear consciencia e impulsar a que la educación financiera no sea un privilegio de unos cuantos, sino que pueda estar al alcance de la mano de toda la población a través de nuestro esquema de cursos enfocados a ellos.
        <br><br>
        Los cursos van dirigidos a todas las personas desde las que inician con muy poco o nulo conocimiento hasta las que tienen grandes conocimientos al respecto, todos ellos son accesibles y flexibles, es decir procuramos que tanto los precios como los horarios de los mismos sean cómodos para las personas que lo toman.
        <br><br>
        Es por ello que todos nuestros cursos son impartidos en un horario cómodo para aquellos que deseen visualizarlos de forma en línea y en vivo; de igual manera todos son grabados y posteriormente compartidos a través de sus correos electrónicos a todos nuestros alumnos para aquellos que no puedan tomarlo en vivo o en su caso los que deseen ir a su propio ritmo.
        <br><br>
        Todos nuestros cursos cuentan con una clase de dudas con la finalidad de finalizar de manera correcta y con los temas lo más claro posible. Contamos de igual manera con Asesorías Personalizadas que permiten a las personas crear, revisar, corregir un portafolio más a fin a la persona en particular enfocada a sus necesidades y su visión propia, esta asesoría cuenta con una duración de una hora aproximadamente
    </div> -->
    <div class="buttons-section">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-group" value="packs">
            <mat-button-toggle class="custom-mat-button" (click)="switchTabs(1)" value="packs">Paquetes
            </mat-button-toggle>
            <mat-button-toggle class="custom-mat-button" (click)="switchTabs(2)" value="courses">Cursos
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="categories-section">
        <mat-tab-group mat-stretch-tabs dynamicHeight class="example-stretched-tabs" *ngIf="coursesDisplay"
            (selectedTabChange)="onTabChangedCourse($event);" animationDuration="0ms">
            <mat-tab>
                <app-banner-discount></app-banner-discount>
                <ng-template mat-tab-label>Principiante</ng-template>
                <div class="content-tab">
                    <div class="cards-container">
                        <ng-container *ngFor="let item of courses">
                            <app-card-course *ngIf="item.category == 1" class="col-4" [title]="item.name"
                                [img]="item.coverImage" [id]="item.id" [abbreviation]="item.abbreviation">
                            </app-card-course>
                        </ng-container>
                    </div>

                    <div class="calendar-container">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <app-banner-discount></app-banner-discount>
                <ng-template mat-tab-label>Intermedio</ng-template>
                <div class="content-tab">
                    <div class="cards-container">
                        <ng-container *ngFor="let item of courses">
                            <app-card-course *ngIf="item.category == 2" class="col-4" [title]="item.name"
                                [img]="item.coverImage" [id]="item.id" [abbreviation]="item.abbreviation">
                            </app-card-course>
                        </ng-container>
                    </div>
                    <div class="calendar-container" *ngIf="showCalendar1">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <app-banner-discount></app-banner-discount>
                <ng-template mat-tab-label>Especializados</ng-template>
                <div class="content-tab">
                    <div class="cards-container">
                        <ng-container *ngFor="let item of courses">
                            <app-card-course *ngIf="item.category == 3" class="col-4" [title]="item.name"
                                [img]="item.coverImage" [id]="item.id" [abbreviation]="item.abbreviation">
                            </app-card-course>
                        </ng-container>
                    </div>
                    <div class="calendar-container" *ngIf="showCalendar2">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group mat-stretch-tabs dynamicHeight class="example-stretched-tabs" *ngIf="packsDisplay"
            (selectedTabChange)="onTabChangedPacks($event);" animationDuration="0ms" class="pack-tab-group">
            <mat-tab label="Principiante" class="pack-tab">
                <div class="content-tab">
                    <div class="cards-container">
                        <div class="packs-container">
                            <div class="pack-card" *ngFor="let pack of packs; let i=index">
                                <div class="head">

                                </div>
                                <div class="img-container">
                                    <img [src]="pack.image" alt="">
                                </div>
                                <div class="info-container">
                                    <h1 class="title">{{pack.title}}</h1>
                                    <p class="price">{{pack.price | currency: '':'$'}}</p>
                                    <p class="desc">{{pack.description}}</p>
                                    <button class="btn card-btn btn-buy" (click)="verifyAuth(paymentDialog,pack)" [disabled]="!pack.active">
                                        <span *ngIf="pack.active">Comprar</span>
                                    </button>
                                    <div class="more-info" [ngClass]="pack.showMore == true ? 'show' : 'hide'">
                                        <p>Información general:</p>
                                        <ul>
                                            <li *ngFor="let item of pack.moreInfo" style="margin:0px;padding:0px;" [ngClass]="{'dot-style': item == ''}" >{{item}}</li>
                                        </ul>
                                    </div>
                                    <p class="show-more" (click)="showMore(i)"> {{!pack.showMore ? 'Mostrar más':'Mostrar menos'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="calendar-container">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab>
            <!-- <mat-tab label="Intermedio">
                <div class="content-tab">
                    <div class="cards-container">
                        <ng-container *ngFor="let item of packs">
                            <app-card-course *ngIf="item.category == 2" class="col-4" [title]="item.title"
                                [img]="item.image" [id]="item.id" [abbreviation]="item.abbreviation"></app-card-course>
                        </ng-container>
                    </div>
                    <div class="calendar-container" *ngIf="showCalendar3">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Avanzado">
                <div class="content-tab">
                    <div class="cards-container">
                        <ng-container *ngFor="let item of packs">
                            <app-card-course *ngIf="item.category == 3" class="col-4" [title]="item.title"
                                [img]="item.image" [id]="item.id" [abbreviation]="item.abbreviation"></app-card-course>
                        </ng-container>
                    </div>

                    <div class="calendar-container" *ngIf="showCalendar4">
                        <app-activities-calendar></app-activities-calendar>
                    </div>
                </div>
            </mat-tab> -->
        </mat-tab-group>
    </div>

    <!-- <div class="calendar-container">
        <app-activities-calendar></app-activities-calendar>
    </div> -->

</section>
<!-- course section end -->

<ng-template #paymentDialog>
    <h1 mat-dialog-title>Formulario de pago</h1>
    <p *ngIf="loaderSub" style="color: #3d8a57;"><strong><i>Cargando pasarela...</i></strong></p>

    <form id="subscribe-form">
        <div class="form-control-container">
            <label for="">Nombre completo</label>
            <input class="form-control" type="text" [(ngModel)]="nameHolder" [ngModelOptions]="{standalone: true}">
        </div>
        <div id="card-element">
            <!-- the card element stripe will be mounted here -->
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Tipo de suscripción</mat-label>
            <mat-select [(ngModel)]="selectedPassTime" [ngModelOptions]="{standalone: true}">
                <mat-option value="yearly">
                  Suscripción Anual
                </mat-option>
              <mat-option value="biannual">
                Suscripción Semestral
              </mat-option>
            </mat-select>
          </mat-form-field>
        <button class="btn-subscribe" (click)="paySubscribe()" type="submit" style="margin-bottom: 5px;">Suscribirme</button>
        <div id="messages"></div>
    </form>

    <mat-dialog-actions style="justify-content: end;">
        <button mat-button mat-dialog-close style="background-color: red; color: white;">Cerrar</button>
    </mat-dialog-actions>
</ng-template>