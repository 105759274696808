<section>
    <h1 class="title">Política de cookies</h1>
    <hr />
        <div class="polices">
            <div class="content">
                <p>
                <a href="https://procesoresultado.com">https://procesoresultado.com</a>  te explica la política de cookies en nuestra página.
                </p>
                <br>
                <p>
                    Debes saber que, en el caso de continuar navegando, estarás prestando tu 
                    consentimiento para el empleo de estas cookies. Pero, en cualquier momento, 
                    podrás cambiar de opinión y bloquear su utilización a través de tu navegador.
                </p>
                <br>
                <p>
                    Para tu total tranquilidad, este sitio web cumple con lo estipulado en la normativa 
                    vigente en relación con el uso de las cookies y tus datos personales establecidos 
                    por la Ley Federal de Protección de Datos Personales en Posesión de los 
                    Particulares de la ley mexicana además de leyes como:
                </p>
                <br>
                <ul>
                    <li>
                        El reglamento LSSI-CE (Ley de la sociedad de la información y del 
                        comercio electrónico).
                    </li>
                </ul>
                <br>
                <p>
                    Esta Política de Cookies podría modificarse en cualquier momento para adaptarse 
                    a novedades normativas o cambios en nuestras actividades, siendo vigente la que 
                    en cada momento se encuentre publicada en la web. Debes conocer que teniendo 
                    en cuenta la forma en la que funciona Internet y los sitios web, no siempre es 
                    posible contar con información actualizada de las cookies que terceras partes 
                    puedan utilizar a través de este sitio web.
                </p>
                <br>
                <p>
                    Esto se aplica especialmente a casos en los que esta página web contiene 
                    elementos integrados: es decir, textos, documentos, imágenes o breves películas 
                    que se almacenan en otra parte, pero se muestran en nuestro sitio web.
                </p>
            </div>
      </div>
        <h1>QUÉ SON LAS COOKIES Y POR QUÉ LAS UTILIZAMOS EN ESTA WEB</h1>
        <hr/>
        <div class="polices">
            <div class="content">
                <p>
                    Una cookie es un fichero que se descarga en el dispositivo del usuario al acceder 
                a determinadas páginas web para almacenar y recuperar información sobre la 
                navegación que se efectúa desde dicho equipo.
                </p>
                <br>
                <p>
                    Las cookies permiten a dicha web, entre otras cosas, almacenar y recuperar 
                    información sobre las decisiones y hábitos del usuario. En 
                    <a href="https://procesoresultado.com">https://procesoresultado.com</a> las utilizamos para personalizar la experiencia dentro
                    de nuestras webs, identificar y recordar al usuario registrado, facilitar la 
                    navegación, así como personalizar las campañas publicitarias que se pudieran 
                    mostrar durante la navegación.
                </p>
                <br>
                <p>
                    Es importante destacar que el uso de cookies no proporciona datos personales del
                    usuario, que de cara a <a href=" https://procesoresultado.com"> https://procesoresultado.com</a> permanece anónimo.
                </p>
                <br>
                <p>
                    El usuario puede configurar su navegador para no aceptar el uso de cookies, en 
                    cuyo caso la personalización de la experiencia no se aplicaría, aunque sí podrá 
                    seguir accediendo a los contenidos de nuestras webs con normalidad.
                </p>
            </div>
        </div>
        <h1>QUÉ TIPO DE COOKIES UTILIZAMOS EN ESTA WEB</h1>
        <hr/>
        <div class="polices">
            <div class="content">
                <p>
                    Las Cookies, en función de su permanencia, pueden dividirse en Cookies de 
                    sesión o permanentes. Las primeras expiran cuando el usuario cierra el 
                    navegador. Las segundas expiran en función cuando se cumpla el objetivo para el 
                    que sirven o bien cuando se borran manualmente.
                </p>
                <br>
                <p>
                    Adicionalmente, en función de su objetivo, las Cookies pueden clasificarse de la 
                    siguiente forma:
                </p>
                <br>
                <p>
                    <strong> Cookies de rendimiento:</strong> este tipo de Cookie recuerda sus preferencias para las 
                    herramientas que se encuentran en los servicios, por lo que no tiene que volver a 
                    configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta 
                    tipología se incluyen:
                </p>
                <br>
                <p>
                    Ajustes de volumen de reproductores de vídeo o sonido.
                </p>
                <br>
                <p>
                    Las velocidades de transmisión de vídeo que sean compatibles con su navegador.
                </p>
                <br>
                <p>
                    <strong> Cookies de geolocalización:</strong> estas Cookies son utilizadas para averiguar en qué 
                    país se encuentra cuando se solicita una web o servicio. Esta Cookie es 
                    totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su 
                    ubicación, como por ejemplo a efectos de idioma. En ningún caso registramos la 
                    ubicación exacta desde donde nos estés visitando ni tampoco guardamos 
                    información de geolocalización de tus dispositivos.
                </p>
                <br>
                <p>
                    <strong> Cookies de registro:</strong> las Cookies de registro se generan una vez que el Usuario 
                    se ha registrado o posteriormente ha abierto su sesión, y se utilizan para 
                    identificarle en las web y servicios con los siguientes objetivos:
                </p>
                <br>
                <p>
                    Mantener al usuario identificado de forma que, si cierra un servicio o web, el 
                    navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho 
                    servicio o web, seguirá identificado, facilitando así su navegación sin tener que 
                    volver a identificarse. Esta funcionalidad se puede suprimir si el usuario pulsa la 
                    funcionalidad “Salir”, de forma que esta Cookie se elimina y la próxima vez que 
                    entre en la web el usuario tendrá que iniciar sesión para estar identificado.
                </p>
                <br>
                <p>
                    Comprobar si el usuario está autorizado para acceder a ciertos servicios, por 
                    ejemplo, para participar en un concurso o escribir comentarios.
                </p>
                <br>
                <p>
                    Adicionalmente, algunos servicios pueden utilizar conectores de terceros tales 
                    como Facebook, Twitter o Google. Cuando el usuario se registra en un servicio 
                    con credenciales de una red social o sistema de identificación de terceros, autoriza
                    al mismo a guardar una cookie persistente que recuerda su identidad y le 
                    garantiza acceso a la web hasta que expira. El usuario puede borrar esta cookie y 
                    revocar el acceso a la web mediante redes sociales o sistema de identificación de 
                    terceros actualizando sus preferencias en la red social que específica.
                </p>
                <br>
                <p>
                    <strong> Cookies de analítica:</strong> cada vez que un Usuario visita una Web o Servicio, una 
                    herramienta de un proveedor externo (Google Analytics, Parsely, comScore y 
                    similares) genera una cookie analítica en el dispositivo del usuario. Esta cookie 
                    que sólo se genera en la visita, servirá en próximas visitas a los servicios de 
                    https://climbingworld.net/ para identificar de forma anónima al visitante. Los 
                    objetivos principales que se persiguen son:
                </p>
                <br>
                <p>
                    Permitir la identificación anónima de los usuarios navegantes a través de la 
                    “Cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la 
                    contabilización aproximada del número de visitantes y su tendencia en el tiempo.
                </p>
                <br>
                <p>
                    Identificar de forma anónima los contenidos más visitados y por lo tanto más 
                    atractivos para los usuarios.
                </p>
                <br>
                <p>Saber si el usuario que está accediendo es nuevo o repite visita.</p>
                <br>
                <p>
                    <strong> Importante:</strong> Salvo que el Usuario decida registrarse en alguna web de 
                    <a href="https://procesoresultado.com">https://procesoresultado.com</a>, la cookie nunca irá asociada a ningún dato de 
                    carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con
                    propósitos estadísticos a los efectos de recoger métricas sobre el uso de nuestras 
                    webs.
                </p>
                <br>
                <p>
                    <strong> Cookies de publicidad:</strong> este tipo de cookies permiten ampliar la información de 
                    los anuncios mostrados a cada usuario anónimo en la web de 
                    <a href="https://procesoresultado.com">https://procesoresultado.com</a>. Entre otros, se almacena la duración o frecuencia de
                    visualización de posiciones publicitarias, la interacción con las mismas, o los 
                    patrones de navegación y/o comportamientos del usuario ya que ayudan a 
                    conformar un perfil de interés publicitario. De este modo, permiten ofrecer 
                    publicidad afín a los intereses del usuario.
                </p>
                <br>
                <p>
                    Para la gestión de la publicidad de este Sitio web se utilizan herramientas de 
                    servicio de publicidad de terceros. Estos terceros pueden almacenar cookies 
                    enviadas desde la web de <a href="https://procesoresultado.com">https://procesoresultado.com</a> procedentes de los 
                    navegadores de los usuarios, así como acceder a los datos que en ellas se 
                    guardan.
                </p>
                <br>
                <p>Las empresas que generan estas Cookies tienen sus propias políticas de privacidad.</p>
                <br>
                <p>
                    <strong> Cookies de terceros:</strong> La Web de <a href="https://procesoresultado.com">https://procesoresultado.com</a> puede utilizar 
                    servicios de terceros que, por cuenta de Google, recopilaran información con fines 
                    estadísticos, de uso del Site por parte del usuario y para la prestación de otros 
                    servicios relacionados con la actividad del Website y otros servicios de Internet.
                </p>
                <br>
                <p>
                    En particular, este sitio web utiliza Google Analytics, un servicio analítico de web 
                    prestado por Google, Inc. con domicilio en los Estados Unidos con sede central en
                    1600 Amphitheatre Parkway, Mountain View, California 94043.
                </p>
                <br>
                <p>
                    Para la prestación de estos servicios, estos utilizan cookies que recopilan la 
                    información, incluida la dirección IP del usuario, que será transmitida, tratada y 
                    almacenada por Google en los términos fijados en la Web Google.com. Incluyendo
                    la posible transmisión de dicha información a terceros por razones de exigencia 
                    legal o cuando dichos terceros procesen la información por cuenta de Google.
                </p>
                <br>
                <p>
                    <strong>Cookies de personalización:</strong> Son aquellas que permiten al usuario acceder al 
                    servicio con algunas características de carácter general predefinidas en función de
                    una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, 
                    el tipo de navegador a través del cual accede al servicio, la configuración regional 
                    desde donde accede al servicio, etc.
                </p>
                <br>
                <p>
                    Cookies de publicidad comportamental: Son aquellas que permiten la gestión, 
                    de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el 
                    editor haya incluido en una página web, aplicación o plataforma desde la que 
                    presta el servicio solicitado. Estas cookies almacenan información del 
                    comportamiento de los usuarios obtenida a través de la observación continuada de
                    sus hábitos de navegación, lo que permite desarrollar un perfil específico para 
                    mostrar publicidad en función del mismo.
                </p>
                <br>
                <p>
                    El Usuario acepta expresamente, por la utilización de este sitio web, el tratamiento
                    de la información recabada en la forma y con los fines anteriormente 
                    mencionados.
                </p>
                <br>
                <p>
                    Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales 
                    datos o información rechazando el uso de Cookies mediante la selección de la 
                    configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo 
                    de Cookies en su navegador puede no permitirle el uso pleno de todas las 
                    funcionalidades del Website.
                </p>
                <br>
                <p>Todas utilizadas para el sitio <a href="https://procesoresultado.com">https://procesoresultado.com</a></p>
            </div>
        </div>
      
</section>