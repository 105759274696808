<mat-dialog-content class="text-center">
    <!-- mensaje de no tiene subs -->
    <section *ngIf="sectionActive == 1">
        <h2 *ngIf="!silverPlan" mat-dialog-title>Parece que no cuentas con una suscripción activa para esta herramienta</h2>
        <h2 *ngIf="silverPlan" mat-dialog-title>Tu suscripción actual no contiene un pase para ver esta herramienta</h2>
        <img src="assets/images/login.png" alt="" style="width: 50%;">
        <h3 *ngIf="!silverPlan"> Suscríbete  para poder ver todo el contenido que tenemos para ti</h3>
        <h3 *ngIf="silverPlan"> Suscríbete con la membresía Gold y obten acceso a esta herramienta</h3>
        <button class="success-btn btn" (click)="showPlan()" *ngIf="!loader">
            Ver planes
        </button>
        <p *ngIf="loader" style="color: #3d8a57;"><strong><i>Cargando planes... Espere un momento</i></strong></p>
    </section>
    <!-- contenido de planes -->
    <section *ngIf="sectionActive == 2">
        <h2 mat-dialog-title>Seleccione un plan</h2>
        <div class="row" *ngIf="planList.length > 0 ">
            <ng-container *ngFor="let plan of planList">
                <div class="col-6">
                    <mat-card style="border: 1px solid grey;margin-top: 1rem;">
                        <p><strong>{{plan.nickname}}</strong></p>
                        <p>${{(plan.unit_amount / 100)}} MXN / al mes</p>
                        <mat-card-actions>
                            <button class="success-btn btn" (click)="openPayment(plan)">Seleccionar</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </ng-container>

        </div>
        <div class="row text-center" *ngIf="planList.length == 0 ">
            <h3>No hay planes disponibles en este momento, contactanos para darte más información</h3>
        </div>
    </section>


    <!-- formulario de pago -->
    <section *ngIf="sectionActive == 3">
        <h2 mat-dialog-title>Formulario de pago</h2>
        <p *ngIf="loaderSub" style="color: #3d8a57;"><strong><i>Cargando pasarela...</i></strong></p>

        <form id="subscribe-form">
            <div class="form-control-container">
                <label for="">Nombre completo</label>
                <input class="form-control" type="text" [(ngModel)]="nameHolder" [ngModelOptions]="{standalone: true}">
            </div>
            <div id="card-element">
                <!-- the card element stripe will be mounted here -->
            </div>
            <button class="success-btn btn" (click)="paySuscribe()" type="submit" style="margin-bottom: 5px;">Suscribirme</button>
            <div id="messages"></div>
        </form>


    </section>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end;">
    <button mat-button mat-dialog-close style="background-color: red; color: white;">Cerrar</button>
</mat-dialog-actions>