import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';


interface ResponsePredictive{
  result: {
    data: {
      average: number;
      max: number;
      min: number;
      pMin: number;
      pMax:number;
      pAverage: number;
      closeValues: Array<number>;
      headerValues : Array<string>;
    };
    message: string;
    status: number;
  };
}


@Injectable({
  providedIn: 'root',
}) 
    

export class FinancesService {
  constructor(
    private fbFunctions: AngularFireFunctions,
    private http: HttpClient
  ) {
    // this.fbFunctions.useEmulator("127.0.0.1", 5001);
  }

  // FUNCION PARA OBTENER DATOS DEL TICKER
  getHistoricalData(data) {

    // MANDA EL DÍA DE HOY A LAS 23:00 EN PERIOD2
    let today: any = new Date();
    today = today.setHours(23,0,0);
    data.period2 = today

    return this.http.get(
      'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-historical-data',
      {
        headers: {
          'x-rapidapi-key':
            'e006f5fe92msh9e8d5b0d1be5f9dp1c366ajsnc35b3c5c63e9',
          'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
        },
        params: data,
      }
    );
  }

  // FUNCION PARA ENVIAR DATOS A GOOGLE SHEET DE SOLO UN TICKER
  sendTickerInfoToSheet(ticker,sheetId) {
    let data = {
      data: {
        fdx: ticker.fdx,
        date: ticker.date,
        spreadsheetId:sheetId
      },
    };

    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/tendencyByTicker',
      // "http://localhost:5001/proceso-resultado/us-central1/tendencyByTicker",
      data
    );

    // return new Promise<any>((resolve, reject) => {
    //   var message = this.fbFunctions.httpsCallable('tendencyByTicker');
    //   let sub = message(data).subscribe(res => {
    //     console.log(res);
    //     resolve(res);
    //     sub.unsubscribe();
    //   })
    // })
  }

  // FUNCION PARA OBTENER RESULTADOS DE FUNCIONES EN GOOGLE SHEET PARA LLENAR GRAFICA DE PUNTOS DE SOLO UN TICKER
  getDataToPointChart(tickerName,spreadsheetId) {
    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/getDataByTendencyTicker',
      // "http://localhost:5001/proceso-resultado/us-central1/getDataByTendencyTicker",
      { data: { tickerName: tickerName, spreadsheetId } }
    );
  }

  // FUNCION PARA ENVIAR A GOOGLE SHEET COMPARATIVA DE 2 TICKERS
  sendTickersComparationToSheet(compare,spreadsheetId) {
    let data = {
      data: {
        date: compare.date,
        firstTickerClose: compare.firstTickerClose,
        secondTickerClose: compare.secondTickerClose,
        spreadsheetId
      },
    };
    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/setUpTickerData',
      // "http://localhost:5001/proceso-resultado/us-central1/setUpTickerData",
      data
    );
  }

  getDataComparationToBarChart(spreadsheetId) {
    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/getDataByPeaksTicker',
      // 'http://localhost:5001/proceso-resultado/us-central1/getDataByPeaksTicker',
      { data: {spreadsheetId} }
    );
  }

  // FUNCION PARA OBTENER DATOS HISTORICOS TOTALES PARA EL PREDICTIVO
  getAllHistoricalDataPerDay(data) {
    return this.http.get(
      'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-historical-data',
      {
        headers: {
          'x-rapidapi-key':
            'e006f5fe92msh9e8d5b0d1be5f9dp1c366ajsnc35b3c5c63e9',
          'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
        },
        params: data,
      }
    );
  }

  // FUNCION PARA ENVIAR DATOS A GOOGLE SHEET DEL PREDICTIVO
  sendPredictiveInfoToSheet(ticker,spreadsheetId) {
    let data = {
      data: {
        fdx: ticker.fdx,
        date: ticker.date,
        spreadsheetId
      },
    };

    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/predictiveByTicker',
      // 'http://localhost:5001/proceso-resultado/us-central1/predictiveByTicker',
      data
    );
  }

  //FUNCION PARA RECIBIR RESULTATDOS DEL PREDICTIVO DESDE SHEETS

  getTendencyPredictive(spreadsheetId) {
    return this.http.post(
      'https://us-central1-proceso-resultado.cloudfunctions.net/getDataByTendencyPredictive',
      // 'http://localhost:5001/proceso-resultado/us-central1/getDataByTendencyPredictive',
      { data: {spreadsheetId} }
    );
  }

 

  async getScreenerPredictive(spreadsheetId) {
    try {
      const response = await this.http
        .post<ResponsePredictive>(
          'https://us-central1-proceso-resultado.cloudfunctions.net/predictiveByFiveLastDays',
          // 'http://localhost:5001/proceso-resultado/us-central1/predictiveByFiveLastDays',
          { data: {spreadsheetId} }
        )
        .toPromise();
        
      return response.result.data;
    } catch (error) {
      console.log(error);
    }
  }
}
