<!-- about section start -->
<!-- <section class="about-page  section-b-space">
    <div class="banner-section">
        <img src="../../../assets/images/banner-test.jpg" alt="about-us" />
    </div>
    <div class="col-12 philosophy">
        <div class="col-12 col-lg-6 text-grid left">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vestibulum felis nibh, vel efficitur
                justo
                placerat nec. Mauris et convallis arcu. In vitae velit vel justo egestas congue. Aliquam erat volutpat.
                Phasellus tristique, nisi vel aliquam vulputate, nisl justo ornare est, sed semper neque ante eu felis.
                Cras
                a felis eros. Pellentesque molestie libero eget ipsum lobortis sagittis. Nam gravida nunc vel mauris
                luctus,
                eu lobortis mauris ullamcorper. Orci varius natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Donec non elit auctor, dignissim ipsum non, tincidunt mauris.
            </p>
        </div>
    </div>
    <div class="col-12 video-section">
        <video width="600" controls>
            <source
                src="https://firebasestorage.googleapis.com/v0/b/proceso-resultado.appspot.com/o/video-about.mp4?alt=media&token=7ad79e99-b09a-46f8-a7fe-9b76b158f889"
                type="video/mp4">
            Your browser does not support HTML video.
        </video>
    </div>
    <div class="col-sm-12">
        <div class="mision about-info">
            <div class="col-12 col-lg-6 text-grid left">
                <p class="custom-title">MISIÓN</p>
                <p>Brindar Educación, Análisis y Herramientas exclusivas y de altísima calidad a precios accesibles
                    para:</p>
                <ol>
                    <li>
                        Mejorar la toma de decisiones Financieras, Económicas y de Inversión.
                    </li>
                    <li>
                        Crear una comunidad donde cada integrante tenga pensamiento crítico e independiente.
                    </li>
                    <li>
                        Subir el nivel de todos los hispanohablantes a través de una formación sólida y robusta.
                    </li>
                </ol>
            </div>
            <div class="col-12 col-lg-6 image-grid">
                <img src="https://images.unsplash.com/photo-1588200908342-23b585c03e26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
                    alt="mision" />
            </div>
        </div>
        <div class="vision about-info">
            <div class="col-12 col-lg-6 image-grid">
                <img src="https://images.unsplash.com/photo-1588200908342-23b585c03e26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
                    alt="vision" />
            </div>
            <div class="col-12 col-lg-6 text-grid">
                <p class="custom-title">VISIÓN</p>
                <p>
                    Ser un referente y punto de encuentro para el Análisis Financiero, Económico y Valuación de
                    Empresas.
                </p>
            </div>
        </div>
        <div class="valores about-info">
            <div class="col-12 col-lg-6 text-grid left">
                <p class="custom-title">VALORES</p>
                <ul>
                    <li>Calidad</li>
                    <li>La importancia y prioridad de un buen Proceso ya que de él vienen los buenos resultados.</li>
                    <li>Honestidad</li>
                    <li>Atención oportuna y eficaz</li>
                    <li>Claridad</li>
                    <li>Humildad Intelectual</li>
                    <li>Actualización</li>
                </ul>
            </div>
            <div class="col-12 col-lg-6 image-grid" id="sixth">
                <img src="https://images.unsplash.com/photo-1588200908342-23b585c03e26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80"
                    alt="valores" />
            </div>
        </div>
    </div>
</section> -->
<!-- about section end -->

<section class="about">
  <div class="container">


    <div class="two-col">
      <div class="custom-col">
        <img src="assets/images/about-us/aboutUs.png" alt="" />
      </div>
      <div class="custom-col">
        <h2 class="valuesInfo__title">¿Quiénes somos?</h2>
        <p class="valuesInfo__text"><b>Proceso Resultado</b> es una Asociación Civil que nació con la finalidad de
          llegar a todos las personas
          que desean comenzar a introducirse en el ámbito de bolsas, inversiones y piensan en su futuro, así como
          también para todas aquellas personas experimentadas en el tema que busquen seguir incrementando sus
          conocimientos.</p>
      </div>
    </div>

    <div class="two-col">
      <div class="custom-col">
        <img src="assets/images/about-copy.png" alt="">
      </div>
      <div class="custom-col">
        <p class="valuesInfo__text"> Crear y crecer juntos como una comunidad capaz de lograr consciencia en un aspecto
          tan importante como lo es la financiera es un objetivo claro que tenemos en Proceso Resultado y con la
          confianza de las personas lograremos llevar este proyecto a un nivel.</p>
        <p class="valuesInfo__text">Nuestro trabajo está respaldado por más de 10 años de experiencia por parte del
          Maestro Joaquín Gaspar, quien se ha consagrado en uno de los mejores impartidores de clases financieras de
          todos los tiempos que con paciencia, dedicación y preparación se ha vuelto un excelente asesor financiero.</p>
        <p class="valuesInfo__text">
          <em>
            “El precio es lo que pagas, el valor es lo que recibes”
            <br>
            -Warren Buffet
          </em>
        </p>
      </div>
    </div>
  </div>

  <!-- <div class="valuesInfo">
    <div class="container">
      <div class="valuesInfo__wrapper">
        <div class="valuesInfo__about__wrapper">
          <h2 class="valuesInfo__title">¿Quiénes somos?</h2>
          <p class="valuesInfo__text">
            <img src="../../../assets/images/about-us/aboutUs.png" class="about-us-image" alt="">
            <b>Proceso Resultado</b> es una Asociación Civil que nació con la finalidad de llegar a todos las personas
            que desean comenzar a introducirse en el ámbito de bolsas, inversiones y piensan en su futuro, así como
            también para todas aquellas personas experimentadas en el tema que busquen seguir incrementando sus
            conocimientos.
            <br>
            La creación de Proceso Resultado fue gracias a los grandes beneficios que trajo a la vida del equipo de
            trabajo de la asociación civil y se decidió compartir esta información a todas aquellas personas interesadas
            para que todos sigamos creciendo como comunidad.
            <br>
            <br>
            <br>

            <em>
              “El precio es lo que pagas, el valor es lo que recibes”
              <br>
              -Warren Buffet
            </em>
            <br /><br />
            Crear y crecer juntos como una comunidad capaz de lograr consciencia en un aspecto tan importante como lo es
            la financiera es un objetivo claro que tenemos en Proceso Resultado y con la confianza de las personas
            lograremos llevar este proyecto a un nivel.
            <br>
            Nuestro trabajo está respaldado por más de 10 años de experiencia por parte del Maestro Joaquín Gaspar,
            quien se ha consagrado en uno de los mejores impartidores de clases financieras de todos los tiempos que con
            paciencia, dedicación y preparación se ha vuelto un excelente asesor financiero.
          </p>
        </div>
        <div class="valuesInfo__item">
          <div class="valuesInfo__item-img">
            <img src="assets/images/mision.png" alt="mision" />
          </div>
          <div class="valuesInfo__item-info">
            <h2 class="valuesInfo__item-title">Misión</h2>
            <p class="valuesInfo__item-text">
              Proceso Resultado tiene como misión llevar conocimiento, conciencia y educación financiera a toda la
              población con la finalidad de crear más personas preparadas y prosperas en sus finanzas.
            </p>
          </div>
        </div>

        <div class="valuesInfo__item">
          <div class="valuesInfo__item-img">
            <img src="assets/images/vision.png" alt="mision" />
          </div>
          <div class="valuesInfo__item-info">
            <h2 class="valuesInfo__item-title">Visión</h2>
            <p class="valuesInfo__item-text">
              Ser el aliado ideal para crear una mejor conciencia financiera en la población.
            </p>
          </div>
        </div>

         <div class="valuesInfo__item">
              <div class="valuesInfo__item-img">
                <img src="assets/images/values.svg" alt="mision" />
              </div>
              <div class="valuesInfo__item-info">
                <h2 class="valuesInfo__item-title">Valores</h2>
                <ul class="valuesInfo__item-text">
                  <li>Calidad</li>
                  <li>
                    La importancia y prioridad de un buen Proceso ya que de él
                    vienen los buenos resultados.
                  </li>
                  <li>Honestidad</li>
                  <li>Atención oportuna y eficaz</li>
                  <li>Claridad</li>
                  <li>Humildad Intelectual</li>
                  <li>Actualización</li>
                </ul>
              </div>
            </div>
      </div>
    </div>
  </div> -->
</section>

<app-values-section></app-values-section>