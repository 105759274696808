import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup;
    public deviceInfo = null;
    public userUid;
    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    ngOnInit(): void {
        this.deviceInfo = this.deviceService.getDeviceInfo();

    }

    async submit() {
        var mail = this.loginForm.value.email.trim();
        var pass = this.loginForm.value.password;

        try {
            Swal.fire('Cargando...', 'Iniciando sesión');
            Swal.showLoading();
            var response = await this.authService.userCanSignIn(mail);
            console.log(response);
            
            if (response.code == 404) {
                Swal.close();
                Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
            } else {
                var user = await this.authService.loginUser(mail, pass);
                console.log(user.user.emailVerified);

                if (user.user.emailVerified == true) {
                    try {
                        this.userUid = await this.authService.getUser();
                        let randomId = Math.floor(Math.random() * (2000 - 0)) + 0;
                        localStorage.setItem('loginId', randomId.toString());
                        await this.authService.setLoginId(randomId, this.userUid);
                        this.authService.getUserObservable(this.userUid);
                    } catch (error) {
                        console.log(error);
                    }

                    Swal.close();
                    this.router.navigate(['/inicio']);
                }
                else {
                    Swal.close();
                    this.userUid = await this.authService.getUser();
                    let randomId = Math.floor(Math.random() * (2000 - 0)) + 0;
                    localStorage.setItem('loginId', randomId.toString());
                    await this.authService.setLoginId(randomId, this.userUid);
                    this.authService.getUserObservable(this.userUid);
                    this.authService.logoutUser();
                    this.router.navigate(['/email-verified'])
                }
            }


        } catch (error) {
            console.log(error);

            Swal.fire('Oops...', 'Por favor verifique su usuario y contraseña e intente de nuevo', 'error');
        }
    }

}
