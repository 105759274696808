<section>

    <app-spinner *ngIf="isLoading"></app-spinner>

    <button class="btn-close" mat-dialog-close>
        <span>X</span>
    </button>

    <div *ngIf="isSubActive" class="sub-active">
        <div class="heading">
            <h1>Mi suscripción</h1>
            <h3>Pase "{{subscriptionData.subscriptionName}}"</h3>
        </div>

        <hr>

        <div class="body">
            <h3>Próximo pago</h3>

            <div class="date">
                <p>FECHA</p>
                <span>{{(subscriptionData.subscription?.current_period_end * 1000) | date}}</span>
            </div>

            <div class="amount">
                <p>CANTIDAD</p>
                <span>MXN ${{(subscriptionData.subscription.plan.amount / 100)}}.00</span>
            </div>
        </div>

        <hr>

        <div class="footer">
            <p>¿Deseas cancelar tu suscripción?</p>
            <button class="btn-cancel" (click)="cancelSubscription()">Cancelar mi suscripción</button>
        </div>
    </div>

    <div *ngIf="!isSubActive && !isLoading" class="sub-inactive">
        <h1>Actualmente no cuentas con una suscripción activa.</h1>
        
        <img src="assets/images/about-copy.png" alt="">

        <div class="btn-wrapper">
            <button class="btn-cancel" [routerLink]="['/cursos']" matDialogClose>Mira las diferentes membresías y sus beneficios</button>
        </div>
    </div>


</section>