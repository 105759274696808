<div class="body-register">
    <div id="banner-register">
        <!-- <img src="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80" alt=""> -->
    </div>
    <div id="register-container">
        <h3>Regístrate</h3>
        <div class="custom-card">
            <form [formGroup]="registerForm" id="form-container">
                <div class="form-control-container">
                    <label for="">Nombre</label>
                    <input class="form-control" formControlName="name" placeholder="Nombre" type="text">
                </div>
                <div class="form-control-container">
                    <label for="">Apellidos</label>
                    <input class="form-control" formControlName="lastname" placeholder="Apellidos" type="text">
                </div>
                <div class="form-control-container">
                    <label for="">Telefono</label>
                    <input class="form-control" formControlName="phone" placeholder="Telefono" type="text" minlength="10" maxlength="10" oninput="(value=value.replace(/\D+/g, ''))">
                </div>
                <div class="form-control-container">
                    <label for="">Correo electrónico</label>
                    <input class="form-control" formControlName="email" placeholder="Correo electrónico" type="email" autocomplete="off">
                </div>
                <div class="form-control-container">
                    <label for="">Contraseña (mínimo 6 caracteres)</label>
                    <input class="form-control" formControlName="password" placeholder="Contraseña" [type]="hide ? 'password' : 'text'" autocomplete="off">
                    <button mat-flat-button (click)="hide = !hide" [attr.aria-label]="'Ocultar contraseña'"
                        [attr.aria-pressed]="hide">
                        <span>{{hide ? 'Mostrar' : 'Ocultar'}}</span>
                    </button>
                </div>
                <div class="actions-container">
                    <button class="btn send-btn" (click)="submit()" [disabled]="!registerForm.valid">Enviar</button>
                </div>
            </form>
        </div>
    </div>
</div>