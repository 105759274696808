<section>
    <h1>Preguntas frecuentes</h1>
    <ul>
        <li>
            <h3>¿Si no tengo ningún conocimiento previo, puedo tomar sus cursos?</h3>
            <p>
                Si, hemos creado cursos que te ayudarán a iniciar desde cero, nuestro curso 
                “Bolsa desde Cero para Principiantes” además de un curso de Análisis Fundamental Básico que te ayudará a conocer un poco mejor estos temas.
            </p>
        </li>
        <li>
            <h3>¿Cuánto tiempo duran los cursos?</h3>
            <p>
                Los cursos constan de 6 sesiones con una duración aproximada de dos horas por cada clase.
            </p>
        </li>
        <li>
            <h3>¿Qué días se dan los cursos?</h3>
            <p>
                Todas las clases se imparten entre semana de lunes a viernes con excepción del curso de Indexación Pasiva en donde su clase de dudas se imparte el último sábado de cada mes.
            </p>
        </li>
        <li>
            <h3>¿Con qué frecuencia abren los cursos?</h3>
            <p>
                Los cursos se abren una vez cada tres meses todos con sus respectivas nuevas ediciones, con excepción del curso Indexación Pasiva.
            </p>
        </li>
        <li>
            <h3>¿Cuándo se abre el curso de Indexación Pasiva?</h3>
            <p>
                Este curso está abierto siempre, todas las sesiones están previamente grabadas y tienen una sesión por mes para resolver dudas, preguntas y comentarios.
            </p>
        </li>
    </ul>
</section>