<div class="my-profile-section">
    <div class="profile">
        <div></div>
        <div class="edit-wrapper" style="display: flex; align-items: center; justify-content: end;">
            <a href="javascript:void(0)" (click)="setEdit()" style="font-weight:700; color: #4068A6; margin-right: 10px;"><span *ngIf="!isEdit">Editar perfil</span> <span *ngIf="isEdit">Cancelar</span></a>
            <img src="/assets/icons/edit.png" alt="" style="width: 20px;">
        </div>

        <form [formGroup]="profileForm" id="form-container">
            <h1 *ngIf="!isEdit">{{userInformation?.name}} {{userInformation?.lastname}}</h1>
            <div *ngIf="isEdit" style="margin-top: 20px;">
                <div style="display: flex; flex-wrap: wrap; gap: 20px;">
                    <input class="form-control" formControlName="name" placeholder="Nombre">
                    <input class="form-control" formControlName="lastname" placeholder="Apellido">
                </div>    
            </div>

            <hr style="background-color:#A0DAB7; margin: 20px 0;">

            <div style="display: block;">

                <h4 style="font-size: 18px; font-weight: 700; color: #1B5B35;">Administrar mi cuenta</h4>

                <div class="grid-form">

                    <div *ngIf="isEdit"  class="form-container-left">
                        <button class="btn-password" mat-flat-button (click)="openChangePasswordDialog()">Cambiar contraseña</button>
                    </div>

                    <div class="form-container-right">
                        <label for="" style="font-size: 15px; font-weight: 500">Miembro desde</label>
                        <p>{{userInformation?.creationDate | date}}</p>
                        
                    </div>

                    <div class="form-container-left">
                        <label for="" style="font-size: 15px; font-weight: 500">Correo electrónico</label>
                        <!-- <input *ngIf="isEdit" class="form-control" formControlName="email" placeholder="Correo electrónico" type="email"> -->
                        <p>{{userInformation?.email}}</p>
                    </div>

                    <div class="form-container-right">
                        <label for="" style="font-size: 15px; font-weight: 500">Teléfono</label>
                        <input *ngIf="isEdit" class="form-control" formControlName="phone" placeholder="Teléfono" minlength="10"
                        maxlength="10" oninput="(value=value.replace(/\D+/g, ''))" required>
                        <p *ngIf="!isEdit">{{userInformation?.phone || '-'}}</p>
                    </div>
                </div>

                <div class="grid-form">
                    <div class="form-container-left">
                        <label for="" style="font-size: 15px; font-weight: 500">Suscripción</label>
                        <p *ngIf="!subscriptionName">Sin suscripción</p>
                        <p *ngIf="subscriptionName"><b>Pase:</b> {{ this.subscriptionName || '-' | titlecase}}</p>
                        <!-- <p *ngIf="subscriptionName">{{(subscription?.current_period_start * 1000) | date}} - {{(subscription?.current_period_end * 1000) | date}}</p> -->
                        <p *ngIf="subscriptionName"><b>Fin del periodo:</b> {{(subscription?.current_period_end * 1000) | date}}</p>
                    </div>

                    <div class="form-container-right">
                        <label for="" style="font-size: 15px; font-weight: 500">Mis cursos</label>
                        <a class="purchases" (click)="openCoursesListDialog()">{{userInformation?.purchasedCourses?.length || '0'}} cursos comprados</a>
                    </div>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap:25px">
                        <a href="javascript:void(0)" style="font-size: 15px; font-weight: 500; color: #4068A6;" (click)="openSubscriptionDialog()">ADMINISTRAR SUSCRIPCIÓN</a>
                    </div>
                </div>

                <div class="btn-wrapper">
                    <button *ngIf="isEdit" (click)="updateProfile()"  style="background-color: #4068A6; color: white; border: none; padding: 10px; border-radius: 5px; width: 120px; font-size: 16px;">
                        Guardar
                    </button>
                </div>

            </div>
        </form>
    </div>

</div>