import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ContactService } from 'src/app/services/contact/contact.service';
import Swal from 'sweetalert2';
import { ContactComponent } from '../course/contact/contact.component';

@Component({
  selector: 'app-card-course',
  templateUrl: './card-course.component.html',
  styleUrls: ['./card-course.component.scss']
})
export class CardCourseComponent implements OnInit {

  @Input() title: string;
  @Input() img: string;
  @Input() id: number;
  @Input() abbreviation: string;
  private subAuth: Subscription;
  constructor(
    public dialog: MatDialog,
    private auth: AngularFireAuth,
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
  }

  openDialog(nameCourse: string) {
    this.subAuth = this.auth.authState.subscribe((auth) => {
      // console.log(auth);
      if (!auth) {
        this.dialog.open(ContactComponent, {
          width: '500px',
          height: 'auto',
          // panelClass: 'modal-activity',
          autoFocus: false,
          data: {
            nameCourse: nameCourse
          }
        });
      } else {

        Swal.fire({
          icon: 'info',
          title: '¡Gracias por el interes!',
          text: 'Por el momento no estan disponibles los cursos, si desea recibir detalles del curso cuando este disponible presione el boton aceptar.',
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: "#3D8A57",
          cancelButtonColor:"#e5514e",
          showCancelButton: true
        }).then(async (result) => {
          if (result.isConfirmed) {
            let data = {
              name: auth.displayName || "default name",
              email: auth.email,
              nameCourse: nameCourse
            }
            Swal.fire('', 'Se esta enviando su informacion...', 'info');
            Swal.showLoading();
            
            const answer = await this.contactService.sendMail(data);
            Swal.close();
            Swal.fire({
              icon: 'success',
              title: '¡Excelente!',
              text: 'Se han enviado su información satisfactoriamente, nos pondremos en contacto con usted.',
              confirmButtonText: "Aceptar",
              allowEscapeKey: false,
              allowOutsideClick: false,
              confirmButtonColor: "#e5514e",
              footer:'<a href="https://api.whatsapp.com/send?phone=522215739036&text=Hola,%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20del%20curso..." target="blank">¿Más informacion del curso?</a>',
            })
          }
        });
      }
      this.subAuth.unsubscribe();
    });
  }
}
