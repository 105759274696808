import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog/blog.service';

@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.scss']
})
export class BlogHomeComponent implements OnInit {
  
  posts = [];
  constructor(public service: BlogService) {
    this.service.getPosts().subscribe(res => {
      this.posts = [];
      res.forEach(element => {
        // element.description = element.description.substring(0, 200) + '...';
        this.posts.push(element)
      });
    })
  }

  ngOnInit() {
  }

}
