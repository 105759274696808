import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Card } from '../../models/order';
declare var Conekta: any;

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private addCardUrl: string = `${environment.URL_API}/user/add-new-card`;
    private getUserCardUrl: string = `${environment.URL_API}/user/get-cards`;

    private getUserURL: string = `${environment.URL_API}/user/get-user`;
    private updatePasswordURL: string = `${environment.URL_API}/user/update-password`;
    private updateUserURL: string = `${environment.URL_API}/user/update`;
    private getUserSubsURL: string = `${environment.URL_API}/user/stripe/subscriptions`;
    private getUserPortalURL: string = `${environment.URL_API}/user/stripe/portal`;
    private getPlanListURL: string = `${environment.URL_API}/stripe/plan-list`;
    private getPassListURL: string = `${environment.URL_API}/stripe/product-list`;
    private createSubscriptionURL: string = `${environment.URL_API}/stripe/create-subscription`;
    private createSubscriptionWithCouponURL: string = `${environment.URL_API}/stripe/create-subscription-coupon`;
    private cancelSubscriptionURL: string = `${environment.URL_API}/stripe/cancel-subscription`;

    constructor(private http: HttpClient, private auth: AuthService) { }

    async getUserData(id) {
        try {
            const answer = await this.http.post<{ code: number, message: string, data: User, size: number }>(this.getUserURL, { userId: id }).toPromise();
            return answer.data;
        } catch (error) {
            console.log(error);

            throw error;
        }
    }

    async updateUser(user: User, userId ) {
        try {
          const answer = await this.http
            .post<{ code: number; message: string }>(`${this.updateUserURL}`, {
              user, userId
            })
            .toPromise();
          return answer;
        } catch (error) {
          console.log(error);
          throw error;
        }
      }

    async updatePassword(user: User, userId ) {
        try {
          const answer = await this.http
            .post<{ code: number; message: string }>(`${this.updatePasswordURL}`, {
              user, userId
            })
            .toPromise();
          return answer;
        } catch (error) {
          console.log(error);
          throw error;
        }
      }

    async getUserSubscriptions(stripeclient_id: string) {
        try {
            const answer = await this.http.get<{ code: number, message: string, data: any }>(`${this.getUserSubsURL}/${stripeclient_id}`).toPromise();
            return answer.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getBuyedCourses() {

    }

    async getUserPortal(stripeclient_id: string) {
        try {
            const answer = await this.http.get<{ code: number, message: string, data: any }>(`${this.getUserPortalURL}/${stripeclient_id}`).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async getPlanList() {
        try {
            const answer = await this.http.get<{ code: number, message: string, data: any }>(`${this.getPlanListURL}`).toPromise();
            return answer.data.data;
        } catch (error) {
            throw error;
        }
    }

    async getPassList() {
        try {
            const answer = await this.http.get<{ code: number, message: string, data: any }>(this.getPassListURL).toPromise();
            return answer
        } catch (error) {
            throw error;
        }
    }

    async getPass(pass: string) {
        try {
            const answer = await this.http.get<{ code: number, message: string, data: any }>(`${this.getPlanListURL}${pass}`).toPromise();
            return answer.data.data;
        } catch (error) {

        }
    }

    async createSuscription(customerId: string, planId: string, coupon?: string) {
        try {
            const answer = await this.http.post<{ code: number, message: string, data: any }>(`${this.createSubscriptionURL}`, { planId, customerId }).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async createSubscriptionWithCoupon(customerId: string, planId: string, coupon: string) {
        try {
            const answer = await this.http.post<{ code: number, message: string, data: any }>(`${this.createSubscriptionWithCouponURL}`, { planId, customerId, coupon }).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async deleteSuscription(subscriptionId){
        try {
            const answer = await this.http.post<{ code: number, message: string, data: any }>(`${this.cancelSubscriptionURL}`, { subscriptionId }).toPromise();
            return answer.data;
        } catch (error) {
            throw error;
        }
    }

    async getCards() {
        let userId = await this.auth.getUser();
        try {
            let response = await this.http
                .get<{ code: number; message: string; data: Card[] }>(
                    `${this.getUserCardUrl}/${userId}`
                )
                .toPromise();
            return response;
        } catch (error) {
            throw error;
        }
    }

    public getBrand(event: string) {
        return Conekta.card.getBrand(event);
    }

    public validCart(card: Card) {
        let number = Conekta.card.validateNumber(card.number);
        let date = Conekta.card.validateExpirationDate(
            card.exp_month,
            card.exp_year
        );
        let cvc = Conekta.card.validateCVC(card.cvc);
        if (number && date && cvc) {
            return true;
        }
        throw 'CARD';
    }

    async addNewCard(card: Card, customer: string) {
        try {
            const response = this.http
                .post<{ code: number; message: string; data: Card }>(this.addCardUrl, {
                    card: card,
                    customer: customer,
                })
                .toPromise();
            return response;
        } catch (error) {
            throw error;
        }
    }

}
