import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScreenerTable } from 'src/app/models/screeners';

@Injectable({
  providedIn: 'root',
})
export class ScreenersService {
  private base_url = 'https://us-central1-proceso-resultado.cloudfunctions.net';

  constructor(private http: HttpClient) { }

  async getScreeners() {
    try {
      const answer = await this.http
        .get<{
          code: number;
          message: string;
          data: ScreenerTable;
          size: number;
        }>(`${this.base_url}/api/screener/get`)
        .toPromise();
      // console.log(answer.data);
      return answer.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


}
